import OfferNewBookingModel from './OfferNewBookingModel'
export default class TourNewBookingModel {
    public static Create(obj: TourNewBookingModel) {
        return Object.assign(new TourNewBookingModel(), obj, {
            offers: obj.offers.map((o) => OfferNewBookingModel.Create(o)),
        })
    }
    id: number = 0
    offers: OfferNewBookingModel[] = []
    nameInternal: string = ''
    nameDisplay: string = ''
}
