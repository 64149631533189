import { Action, Reducer } from 'redux'
import { AppThunkAction } from '.'
import { LoadingStore, Localization } from 'traveldesk-ui'

export interface State {
    city?: number
    path: string
}
const SET_GLOBAL_PATH = 'A_SET_GLOBAL_PATH'
interface SetGlobalPath {
    type: 'A_SET_GLOBAL_PATH'
    path: string
}
const SET_GLOBAL_CITY = 'A_SET_GLOBAL_CITY'
interface SetGlobalCityAction {
    type: 'A_SET_GLOBAL_CITY'
    city: number
}

type KnownAction = SetGlobalCityAction | SetGlobalPath

export const actionCreators = {
    initialize: (): AppThunkAction<KnownAction | LoadingStore.KnownAction> => async (dispatch, getState) => {},

    setPath: (path: string) => ({ type: SET_GLOBAL_PATH, path }),
    setCity: (city: number) => ({ type: SET_GLOBAL_CITY, city } as SetGlobalCityAction),
}
const storageState = localStorage ? localStorage.getItem('settings') : undefined
const unloadedState: State = storageState
    ? Object.assign(JSON.parse(storageState), { locale: new Localization.Localization() })
    : { city: undefined, language: 'en', locale: new Localization.Localization() }
const updateStorage = (state: State) => {
    if (localStorage) {
        localStorage.setItem('settings', JSON.stringify(state))
    }
}
export const reducer: Reducer<State> = (state: State | undefined, incomingAction: Action): State => {
    if (state === undefined) {
        return unloadedState
    }

    const action = incomingAction as KnownAction
    switch (action.type) {
        case SET_GLOBAL_PATH:
            return {
                ...state,
                path: action.path,
            }

        case SET_GLOBAL_CITY:
            state = {
                ...state,
                city: action.city,
            }
            updateStorage(state)
            return state
        default:
            return state
    }
}
