export default class Hotel {
    public static Create(obj: Hotel) {
        return Object.assign(new Hotel(), obj)
    }
    id: number = 0
    name: string = ''
    regionId?: number
    regionName: string = ''
    cityId: number = 0
    cityName: string = ''
}
