import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import {
    ContainerWithPadding,
    Grid,
    RowTitle,
    SectionTitle,
    Col,
    Row,
    DateFormFieldController,
    nameof,
    SelectFormFieldController,
    Buttons,
    NumberFormField,
    TextFormField,
    TextAreaField,
    SelectOptionModel,
    round,
    FormFieldLabel,
    SelectFormField,
    LabelFormField,
    DateCarousel,
    DateCarouselDate,
    NumPeopleInput,
    generateArrayOfN,
    Button,
} from 'traveldesk-ui'
import { useForm, useFormState } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from '../../store'
import { Link } from 'react-router-dom'
import NewBookingAgent from './Models/NewBookingAgent'
import { toLen } from '../../utils/stringUtils'
import * as AgencyBookingsState from '../../store/AgencyBookings'
import { LoadingStore } from 'traveldesk-ui'
import { useEffect } from 'react'
import moment from 'moment'
import { useState } from 'react'
import AgencyAreaApiService from '../../services/AgencyAreaApiService'
import TimeSlotNewBookingOptionModel from './Models/TimeSlotNewBookingOptionModel'
import OfferNewBookingModel from './Models/OfferNewBookingModel'
import OfferNewBookingOptionModel from './Models/OfferNewBookingOptionModel'
import TimeSlotNewBookingModel from './Models/TimeSlotNewBookingModel'
import OfferForm from '../OfferForm/OfferForm'
import styled from 'styled-components'
// import { TimeSlotOption } from '../../models/TimeSlot'

const TdTimeSelect = styled.td`
    .field-wrap {
        margin-bottom: 0 !important;
    }
`

interface Props extends RouteComponentProps<{}> { }
const BookingForm = (props: Props) => {
    const dispatch = useDispatch()
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const {
        register,
        handleSubmit,
        control,
        setError,
        setValue,
        watch,
        // formState: { errors },
    } = useForm({
        defaultValues: new NewBookingAgent() as any,
    })
    const { errors } = useFormState({
        control,
    })
    const hotelId = watch('hotelId')
    const date = watch('date')
    const tourId = watch('tourId')
    const offerId = watch('offerId')
    const timeSlotIdWithTime = watch('timeSlotIdWithTime')
    const timeSlotId = watch('timeSlotId')
    const col = watch('collect')
    const colOrig = watch('collectOrig')
    const adults = watch('adults')
    const children = watch('children')
    const infants = watch('infants')
    const arrivalId = watch('arrivalId')
    const collect = watch('collect')
    const collectOrig = watch('collectOrig')
    const guestName = watch('guestName')
    const startTime = watch('startTime')
    const endTime = watch('endTime')

    //#region Initialize effect
    React.useEffect(() => {
        if (hotels.length == 0) {
            dispatch(AgencyBookingsState.actionCreators.requestNewBookingHotels())
        }
        if (tours.length == 0) {
            dispatch(AgencyBookingsState.actionCreators.requestNewBookingTours())
        }
    }, [])
    //#endregion
    //#region  Collection changed effects. Convert AED <--> USD
    React.useEffect(() => {
        if (isNaN(col)) {
            setValue('collect', 0)
        } else {
            setValue('collectOrig', round(col * 3.65))
        }
    }, [col])
    React.useEffect(() => {
        if (isNaN(colOrig)) {
            setValue('collectOrig', 0)
        } else {
            setValue('collect', round(colOrig / 3.65))
        }
    }, [colOrig])
    //#endregion
    const hotels = useSelector((state: ApplicationState) => state.agencyBookings.hotelsNewBooking)
    const tours = useSelector((state: ApplicationState) => state.agencyBookings.toursNewBooking)
    const hotelsOptions = React.useMemo(
        () => hotels.map((h) => ({ value: h.id, label: `${h.name} (${h.regionName})` })),
        [hotels],
    )
    const [toursOptions, setToursOptions] = React.useState([] as SelectOptionModel[])
    //#region Tours, Hotel or Date Changed Effect. Set Available Tours Optins
    React.useEffect(() => {
        if (!date) return
        const hotel = hotels.find((x) => x.id == hotelId)
        if (!hotel) return
        setToursOptions(
            [{ label: 'Select tour', value: 0 as any }].concat(
                tours
                    .filter((x) =>
                        x.offers.some(
                            (o) => o.citiesIds.indexOf(hotel?.cityId) >= 0,
                            // && (!o.timeSlots || o.timeSlots.some((ts) => !ts || ts.isOnDay(day))),
                        ),
                    )
                    .map((t) => ({
                        value: t.id,
                        label: `${t.id}. ${t.nameInternal} (${t.nameDisplay})`,
                    })),
            ),
        )
    }, [tours, hotelId, date])
    //#endregion
    //#region Available tOurs options changed effect. Check if tourid and it's still valie
    useEffect(() => {
        if (tourId && !toursOptions.some((t) => t.value == tourId)) {
            setValue('tourId', 0)
        }
    }, [toursOptions])
    //#endregion
    const [offersOptions, setOffersOptions] = useState([] as OfferNewBookingOptionModel[])
    const offerPrices = useSelector((state: ApplicationState) => state.agencyBookings.newBookingOfferPrices)
    //#region Changed tour => check if drop offer
    React.useEffect(() => {
        const tour = tours.find((x) => x.id == tourId)
        if (!tour) {
            setValue('offerId', 0)
            return
        }
        if (!tour.offers.some((o) => o.id == offerId)) {
            setValue('offerId', 0)
        }
    }, [tourId])
    //#endregion
    //#region Changed tours, tourId, date of offerprices => set available offers options
    React.useEffect(() => {
        if (!date) return
        const hotel = hotels.find((x) => x.id == hotelId)
        const tour = tours.find((x) => x.id == tourId)
        if (!hotel || !tour) {
            return
        }
        setOffersOptions(
            // [{ label: 'Select offer', value: 0 as number }].concat(
            tour.offers
                .filter(
                    (x) => x.citiesIds.indexOf(hotel.cityId) >= 0 && offerPrices?.some((op) => op.offerId == x.id),
                    // && (!x.timeSlots || x.timeSlots.some((ts) => !ts || ts.isOnDay(day))),
                )
                .map((o) => ({
                    value: o.id,
                    label: `${o.id}. ${o.nameInternal} (${o.nameDisplay})`,
                    numberOfHours: o.numberOfHours,
                    obj: o,
                })),
            // ),
        )
    }, [tours, tourId, date, offerPrices])
    //#endregion
    useEffect(() => {
        if (offersOptions.length == 1) {
            setValue('offerId', offersOptions[0].value)
            return
        }
        if (offerId && !offersOptions.some((o) => o.value == offerId)) {
            setValue('offerId', 0)
        }
    }, [offersOptions])
    //#region Selected Offer Memo
    const [offer, setOffer] = React.useState<OfferNewBookingModel | undefined>(undefined)
    React.useEffect(() => {
        if (date) {
            const hotel = hotels.find((x) => x.id == hotelId)
            const tour = tours.find((x) => x.id == tourId)
            if (hotel && tour) {
                const res = tour.offers.find((x) => x.id == offerId)
                setOffer(res)
                if (!offer?.childrenAllowed) {
                    setValue('children', 0)
                }
                if (!offer?.infantsAllowed) {
                    setValue('infants', 0)
                }
            }
        }
    }, [tours, tourId, date, offerId])
    //#endregion
    //#region Time slots options Memo
    const timeSlotsOptions = React.useMemo(() => {
        if (!date) return new Map<number, TimeSlotNewBookingOptionModel[]>()
        const day = date.isoWeekday()
        const hotel = hotels.find((x) => x.id == hotelId)
        const tour = tours.find((x) => x.id == tourId)
        if (!hotel || !tour) return new Map<number, TimeSlotNewBookingOptionModel[]>()
        return tour.offers.reduce((res: Map<number, TimeSlotNewBookingOptionModel[]>, offer: OfferNewBookingModel) => {
            const options = [
                {
                    label: 'Select time',
                    value: undefined,
                    id: 0,
                    startTime: undefined as number | undefined,
                    endTime: undefined as number | undefined,
                    obj: undefined as unknown as TimeSlotNewBookingModel,
                } as TimeSlotNewBookingOptionModel,
            ].concat(
                offer.timeSlots
                    // .filter((ts) => ts && ts.isOnDay(day))
                    .reduce((resTs, ts) => {
                        let option = undefined as unknown as TimeSlotNewBookingOptionModel
                        if (ts.isAnyTime) {
                            option = {
                                value: `${ts.id}-${ts.startTimeHour * 60 + ts.startTimeMinutes}-${ts.endTimeHour * 60 + ts.endTimeMinutes
                                    }`,
                                label: ts.toString(),
                                id: ts.id,
                                startTime: ts.startTimeHour * 60 + ts.startTimeMinutes,
                                endTime: ts.endTimeHour * 60 + ts.endTimeMinutes,
                                obj: ts,
                            }
                        } else {
                            option = {
                                value: ts.id.toString(),
                                id: ts.id,
                                label: ts.toString(),
                                startTime: ts.startTimeHour * 60 + ts.startTimeMinutes,
                                endTime: ts.endTimeHour * 60 + ts.endTimeMinutes,
                                obj: ts,
                            }
                        }
                        return resTs.some((x) => x.startTime == option.startTime && x.endTime == option.endTime)
                            ? resTs
                            : resTs.concat(option)
                    }, [] as TimeSlotNewBookingOptionModel[]),
            )
            res.set(offer.id, options)
            return res
        }, new Map<number, TimeSlotNewBookingOptionModel[]>())
    }, [tours, tourId])
    //#endregion

    //#region Calcualte more dates on date, timeslots or offers changed
    const moreDates = React.useMemo(() => {
        var selDate = moment(date).startOf('day')
        var daysBefore = selDate.diff(moment().startOf('d'), 'days') - 1
        const dates = generateArrayOfN(10).map((i) => {
            const r = moment(selDate).add(i - daysBefore, 'days')
            return r
        })
        const res = offersOptions.reduce((res, offer) => {
            const offerId = offer.value as number
            const timeSlots = timeSlotsOptions.get(offerId)
            const resDates = dates.map((x) => {
                const isOfferAvailable = offer.obj.isOnDay(x.isoWeekday())
                const isAvailableTimeSlots = timeSlots?.some((ts) => ts.obj && ts.obj.isOnDay(x.isoWeekday())) ?? false
                return {
                    isAvailable: isAvailableTimeSlots && isOfferAvailable,
                    date: x.toDate(),
                }
            })
            res.set(offerId, resDates)
            return res
        }, new Map<number, DateCarouselDate[]>())
        return res
    }, [date, timeSlotsOptions, offersOptions])
    //#endregion
    //#region Time Slot Changed Effect
    useEffect(() => {
        const tso = timeSlotsOptions.get(offerId)
        const ts = tso?.find((x) => x.value == timeSlotIdWithTime)
        setValue('timeSlotId', ts?.id)
        setValue('startTime', ts?.startTime)
        setValue('endTime', ts?.endTime)
    }, [timeSlotIdWithTime])
    //#endregion
    //#region Tour Id or Date changeed effect. Request offers prices
    React.useEffect(() => {
        dispatch(AgencyBookingsState.actionCreators.requestNewBookingOfferPrices(tourId, date))
    }, [tourId, date])
    //#endregion
    //#region Hoteld Id or Date changed effect. Request arrivals
    React.useEffect(() => {
        dispatch(AgencyBookingsState.actionCreators.requestNewBookingArrivals(hotelId, date))
    }, [hotelId, date])
    //#endregion
    const arrivals = useSelector((state: ApplicationState) => state.agencyBookings.newBookingArrivals)
    const [selectedTimeSlots, setSelectedTimeSlots] = React.useState<any>({})

    //#region Arrivals Options Memo
    const arrivalsOptions = React.useMemo(() => {
        return arrivals
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((x) => ({
                value: x.id,
                groupId: x.groupId,
                arrivalId: x.id,
                groupInfoId: x.groupInfoId,
                label: `${x.name} (${x.groupId})`,
                paxId: x.paxId,
                phones: x.phoneNumbers,
            }))
    }, [arrivals])
    //#endregion

    const showAll = offer && (!offer.timeSlots || offer.timeSlots.length == 0 || timeSlotId)
    const disabled = !showAll || !guestName
    const onSubmit = (data: any) => {
        // if(!data.guestName){
        //     setError('guestName', new "Guest name is required");
        //     return
        // }
        data.startTime = data.startTime || undefined
        data.endTime = data.endTime || undefined
        dispatch(AgencyBookingsState.actionCreators.addBooking(data))
    }
    const onClearGuest = () => {
        setValue('guestName', '')
        setValue('arrivalId', undefined)
        setValue('groupId', undefined)
        setValue('phones', '')
        setValue('roomNumber', '')
        setValue('groupInfoId', null)
    }
    const getGroupInfo = async (groupInfoId: number, hotelId: number) => {
        dispatch(LoadingStore.actionCreators.incrementLoading())
        var fetchTask = await AgencyAreaApiService.getNewBookingGroupInfo(groupInfoId, hotelId, dispatch)
        if (fetchTask.IsOk && fetchTask.Content) {
            setValue('roomNumber', fetchTask.Content.roomNumber)
            setValue('phones', fetchTask.Content.phoneNumbers)
        }
        dispatch(LoadingStore.actionCreators.decrementLoading())
    }
    const onChangeGuest = async (e: any) => {
        setValue('guestName', e.label)
        setValue('arrivalId', e.arrivalId)
        setValue('groupId', e.groupId)
        setValue('groupInfoId', e.groupInfoId)
        if (e.groupInfoId) {
            await getGroupInfo(e.groupInfoId, hotelId)
        }
    }
    const onChangeTimeSlot = (oid: number) => (option: SelectOptionModel) => {
        selectedTimeSlots[oid] = option.value
        setSelectedTimeSlots(Object.assign({}, selectedTimeSlots))
        if (offerId == oid) {
            setValue('timeSlotIdWithTime', option.value)
        }
    }
    const onSelectDateFromCarousel = (date: Date) => {
        setValue('date', moment(date))
    }
    //#region Offer Changed
    useEffect(() => {
        if (offerId) {
            const tso = timeSlotsOptions.get(offerId)
            if (tso && tso.length == 2) {
                setValue('timeSlotId', tso[1].id)
                setValue('timeSlotIdWithTime', tso[1].value)
                return
            }
            if (timeSlotIdWithTime && (!tso || !tso.some((ts) => ts.value == timeSlotIdWithTime))) {
                const selTs = selectedTimeSlots[offerId]
                if (selTs) {
                    const ts = tso?.find((x) => x.value == selTs)
                    if (ts) {
                        setValue('timeSlotIdWithTime', ts.value)
                    }
                } else {
                    setValue('timeSlotIdWithTime', '')
                }
            } else {
                const selTs = selectedTimeSlots[offerId]
                if (selTs) {
                    const ts = tso?.find((x) => x.value == selTs)
                    if (ts) {
                        setValue('timeSlotIdWithTime', ts.value)
                    }
                } else {
                    if (timeSlotIdWithTime) {
                        selectedTimeSlots[offerId] = timeSlotIdWithTime
                        setSelectedTimeSlots(Object.assign({}, selectedTimeSlots))
                    }
                }
            }
        }
    }, [timeSlotsOptions, offerId])
    //#endregion
    const onSelectOffer = (id: number) => {
        setValue('offerId', id)
    }
    const prices = React.useMemo(() => {
        const offerPrice = offerPrices?.find((x) => x.offerId == offerId)
        const totalNetPriceUsd = offerPrice?.totalNetPriceUsd(adults, children) ?? 0
        const totalNetPrice = offerPrice?.totalNetPrice(adults, children) ?? 0
        const publishRateUsd = offerPrice?.totalPublishRateUsd(adults, children) ?? 0
        const publishRate = offerPrice?.totalPublishRate(adults, children) ?? 0
        return {
            offerPrice,
            totalNetPriceUsd: round(totalNetPriceUsd) as number,
            totalNetPrice: round(totalNetPrice) as number,
            publishRateUsd: round(publishRateUsd),
            publishRate: round(publishRate),
        }
    }, [adults, children, offerId, offerPrices])
    const payable = React.useMemo(() => {
        return {
            payableUsd: round(prices.totalNetPriceUsd - collect),
            payable: round(prices.totalNetPrice - collectOrig),
        }
    }, [prices, collect])

    return (
        <ContainerWithPadding>
            <Grid>
                <SectionTitle title={Localization.getString('Add new booking')} />
                <Link className="a-back-to-list" to="/agent/bookings">
                    {Localization.getString('Back to bookings')}
                </Link>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* <div className="checkout-container">
                        <h2 className="checkout-title">{Localization.getString('Core Info')}</h2>
                        <div className="checkout-box-wrap checkout-box-details"></div>
                    </div> */}
                    <h2>{Localization.getString('Core Info')}</h2>
                    <div className="form-card">
                        <div className="form-row">
                            <div className="form-col">
                                <DateFormFieldController
                                    tabIndex={0}
                                    disabledDays={{ before: moment().add(1, 'd').toDate() }}
                                    name={nameof<NewBookingAgent>('date')}
                                    label={Localization.getString('Date')}
                                    isRequired
                                    control={control}
                                />
                                <SelectFormFieldController
                                    tabIndex={1}
                                    name={nameof<NewBookingAgent>('hotelId')}
                                    isRequired
                                    label={Localization.getString('Hotel')}
                                    options={hotelsOptions}
                                    control={control}
                                />
                                {date && hotelId ? (
                                    <SelectFormFieldController
                                        tabIndex={2}
                                        name={nameof<NewBookingAgent>('tourId')}
                                        isRequired
                                        label={Localization.getString('Tour')}
                                        options={toursOptions}
                                        control={control}
                                    />
                                ) : null}
                            </div>
                            <div className="form-col">
                                <NumPeopleInput
                                    label="Adult"
                                    tooltip={`${offer?.childAge ?? 12} + years old`}
                                    price={prices.offerPrice?.pricePerAdultRec}
                                    count={adults}
                                    minValue={1}
                                    hidePrice
                                    onChange={(value) => setValue('adults', value)}
                                />
                                {/* <NumberFormField
                                    tabIndex={8}
                                    register={register}
                                    name={nameof<NewBookingAgent>('adults')}
                                    label={`${Localization.getString('Adults')} (12+)`}
                                    min={1}
                                /> */}
                                <NumPeopleInput
                                    label="Children"
                                    tooltip={
                                        offer
                                            ? offer.childrenAllowed
                                                ? `Aged 3-${(offer?.childAge ?? 0 - 0.01).toFixed(2)}`
                                                : 'Children not allowed'
                                            : 'Select ticket'
                                    }
                                    disabled={!offer || !offer.childrenAllowed}
                                    price={prices.offerPrice?.pricePerChildRec}
                                    count={children}
                                    minValue={1}
                                    hidePrice
                                    onChange={(value) => setValue('children', value)}
                                />
                                {/* <NumberFormField
                                    tabIndex={9}
                                    register={register}
                                    name={nameof<NewBookingAgent>('children')}
                                    label={`${Localization.getString('Children')} (3-11.99)`}
                                    min={0}
                                /> */}
                                <NumPeopleInput
                                    label="Infants"
                                    disabled={!offer || !offer.infantsAllowed}
                                    tooltip={
                                        offer
                                            ? offer.infantsAllowed
                                                ? `Aged 0-${(offer?.infantAge ?? 0 - 0.01).toFixed(2)}`
                                                : 'Infants not allowed'
                                            : 'Select ticket'
                                    }
                                    price={0}
                                    count={infants}
                                    minValue={0}
                                    hidePrice
                                    onChange={(value) => setValue('infants', value)}
                                />
                                {/* <NumberFormField
                                    tabIndex={10}
                                    register={register}
                                    name={nameof<NewBookingAgent>('infants')}
                                    label={`${Localization.getString('Infants')} (0-2.99)`}
                                    min={0}
                                /> */}
                                {prices.offerPrice?.pricePerTripRec ? (
                                    <div className="number-input-container">
                                        <div className="number-input-label">
                                            <h6>
                                                Per Trip
                                                <span data-tip data-for={''}>
                                                    i{' '}
                                                </span>
                                            </h6>
                                        </div>
                                        <div className="counter-container"></div>
                                        <div className="number-input-price">
                                            {prices.offerPrice.pricePerTripRec} AED
                                        </div>
                                    </div>
                                ) : null}
                                {/* <div className="ticket-item-summary">
                                    <div className="ticket-item-summary__label">
                                        Total * <span>Includes all taxes.</span>
                                    </div>
                                    <div className="ticket-item-summary__summ">{prices.publishRate} AED</div>
                                </div> */}
                                {date && hotelId && tourId
                                    ? null
                                    : // (
                                    //     <SelectFormFieldController
                                    //         tabIndex={3}
                                    //         name={nameof<NewBookingAgent>('offerId')}
                                    //         disabled={offersOptions.length == 2}
                                    //         isRequired
                                    //         label={Localization.getString('Ticket')}
                                    //         options={offersOptions}
                                    //         control={control}
                                    //     />
                                    // )
                                    null}
                                <div style={{ display: 'none' }}>
                                    <NumberFormField
                                        label={'startTime'}
                                        register={register}
                                        name={nameof<NewBookingAgent>('startTime')}
                                    />
                                    <NumberFormField
                                        label={'endTime'}
                                        register={register}
                                        name={nameof<NewBookingAgent>('endTime')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {date && hotelId && tourId ? (
                        <React.Fragment>
                            <br />
                            <h2>{Localization.getString('Ticket Selection')}</h2>
                            <Row>
                                <Col type="100">
                                    <div className="table-wrapper">
                                        <table className="table--tickets">
                                            <thead>
                                                <tr>
                                                    <th>{Localization.getString('Ticket Selection')}</th>
                                                    <th>{Localization.getString('Date')}</th>
                                                    <th>{Localization.getString('Time Slot')}</th>
                                                    <th>{Localization.getString('Published Rate')}</th>
                                                    <th>{Localization.getString('NET Payable')}</th>
                                                    <th>{Localization.getString('Select')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {offersOptions.map((_offer) => {
                                                    const _offerId = _offer.value as number
                                                    const tso = timeSlotsOptions.get(_offerId)
                                                    const price = offerPrices?.find((x) => x.offerId == _offerId)
                                                    const isAvailable =
                                                        // _offer.obj.isOnDay(date.isoWeekday()) &&
                                                        tso?.some((ts) => ts.obj && ts.obj.isOnDay(date.isoWeekday()))
                                                    return (
                                                        <tr key={_offer.value as number}>
                                                            <td style={{ width: 250 }}>{_offer.label}</td>
                                                            <td colSpan={isAvailable ? 1 : 2}>
                                                                {isAvailable ? (
                                                                    moment(date).format('DD.MM.YYYY')
                                                                ) : (
                                                                    <DateCarousel
                                                                        dates={
                                                                            moreDates.get(_offer.value as number) || []
                                                                        }
                                                                        onSelectDate={onSelectDateFromCarousel}
                                                                    />
                                                                )}
                                                            </td>
                                                            {isAvailable ? (
                                                                <TdTimeSelect style={{ width: 200 }}>
                                                                    {tso && tso.length > 0 ? (
                                                                        tso.length > 2 ? (
                                                                            // _offerId == offerId ? (
                                                                            <SelectFormField
                                                                                tabIndex={4}
                                                                                options={tso}
                                                                                value={selectedTimeSlots[_offerId]}
                                                                                onChange={onChangeTimeSlot(_offerId)}
                                                                            />
                                                                        ) : (
                                                                            // <SelectFormFieldController
                                                                            //     style={{ margin: 0 }}
                                                                            //     tabIndex={4}
                                                                            //     // disabled={offerId != _offerId}
                                                                            //     name={nameof<NewBookingAgent>(
                                                                            //         'timeSlotIdWithTime',
                                                                            //     )}
                                                                            //     isRequired
                                                                            //     // label={Localization.getString('Select Time')}
                                                                            //     options={tso}
                                                                            //     control={control}
                                                                            //     // value={timeSlotIdWithTime}
                                                                            // />
                                                                            // ) : (
                                                                            //     <SelectFormField
                                                                            //         options={tso}
                                                                            //         disabled
                                                                            //         value=""
                                                                            //     />
                                                                            // )
                                                                            tso[1].label
                                                                        )
                                                                    ) : null}
                                                                </TdTimeSelect>
                                                            ) : null}
                                                            <td>
                                                                {price?.totalPublishRateUsd(adults, children)} $ /{' '}
                                                                {price?.totalPublishRate(adults, children)} AED
                                                            </td>
                                                            <td>
                                                                {price?.totalNetPriceUsd(adults, children)} $ /{' '}
                                                                {price?.totalNetPrice(adults, children)} AED
                                                            </td>
                                                            <td style={{ width: 30 }}>
                                                                {isAvailable ? (
                                                                    <Button
                                                                        className="ticket-table-button"
                                                                        active={offerId == _offerId}
                                                                        onClickHandler={() => onSelectOffer(_offerId)}
                                                                        iconLeft={() => (
                                                                            <i className="i__ticket__button" />
                                                                        )}
                                                                        label={
                                                                            offerId == _offerId
                                                                                ? 'Selected Ticket'
                                                                                : 'Select Ticket'
                                                                        }
                                                                    />
                                                                ) : null}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </React.Fragment>
                    ) : null}
                    {showAll ? (
                        <>
                            <h2>{Localization.getString('Guest Details')}</h2>
                            <div className="form-card">
                                <div className="form-row">
                                    <div className="form-col">
                                        <SelectFormField
                                            tabIndex={5}
                                            disabled={arrivalId > 0}
                                            isRequired
                                            hideIfNoOptions={true}
                                            isClearable={arrivalId > 0}
                                            onClear={onClearGuest}
                                            // options={{ required: 'Guest Name is required' }}
                                            options={arrivalsOptions as SelectOptionModel[]}
                                            error={errors && errors['guestName']?.message}
                                            label={Localization.getString('Guest Name')}
                                            onInputChanged={(value: string) => {
                                                setValue('guestName', value)
                                            }}
                                            isAutoComplete
                                            onChange={onChangeGuest}
                                            value={arrivalId}
                                        // autoCompleteToken={AuthUtils.getToken}
                                        // autoCompleteEndPoint={`/api/agent/customers/autocomplete?hotelid=${hotelId}&date=${moment(
                                        //     date,
                                        // ).format('YYYY-MM-DD')}`}
                                        // autoCompleteResponseHandler={(data) =>
                                        //     data.map((x: any) => {
                                        //         return {
                                        //             value: x,
                                        //             arrivalId: x.arrivalId,
                                        //             groupInfoId: x.groupInfoId,
                                        //             label: `${x.name} (${x.groupId})`,
                                        //             paxId: x.paxId,
                                        //             groupId: x.groupId,
                                        //             agencyId: x.agencyId,
                                        //             phones: x.phones,
                                        //         }
                                        //     })
                                        // }
                                        />
                                        <TextFormField
                                            tabIndex={6}
                                            register={register}
                                            name={nameof<NewBookingAgent>('phones')}
                                            label={Localization.getString('Phone number')}
                                        />
                                        <TextFormField
                                            tabIndex={7}
                                            register={register}
                                            name={nameof<NewBookingAgent>('roomNumber')}
                                            label={Localization.getString('Room number')}
                                        />
                                    </div>
                                    <div className="form-col">
                                        <TextAreaField
                                            tabIndex={8}
                                            register={register}
                                            name={nameof<NewBookingAgent>('notes')}
                                            label={Localization.getString('Notes')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null}
                    {showAll ? (
                        <>
                            <h2>{Localization.getString('Payment Details')}</h2>
                            <div className="form-card">
                                <div className="form-row">
                                    <div className="form-col">
                                        <TextFormField
                                            tabIndex={9}
                                            register={register}
                                            name={nameof<NewBookingAgent>('voucherNumber')}
                                            label={Localization.getString('Voucher number')}
                                        />
                                        <NumberFormField
                                            tabIndex={10}
                                            register={register}
                                            name={nameof<NewBookingAgent>('collect')}
                                            label={Localization.getString('Collect, $')}
                                            min={0}
                                            step={0.01}
                                        />
                                        <NumberFormField
                                            tabIndex={11}
                                            register={register}
                                            name={nameof<NewBookingAgent>('collectOrig')}
                                            label={Localization.getString('Collect, AED')}
                                            min={0}
                                            step={0.01}
                                        />
                                    </div>
                                    <div className="form-col">
                                        {offerPrices ? (
                                            <>
                                                <LabelFormField
                                                    label={Localization.getString('Publish rate')}
                                                    value={`${prices.publishRateUsd} $   /   ${prices.publishRate} AED`}
                                                />
                                                <LabelFormField
                                                    label={Localization.getString('Net Rate')}
                                                    value={`${prices.totalNetPriceUsd} $   /   ${prices.totalNetPrice} AED`}
                                                />
                                                <LabelFormField
                                                    label={Localization.getString('Payable')}
                                                    value={`${payable.payableUsd} $  /  ${payable.payable} AED`}
                                                />
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null}
                    <Buttons.StickySaveButton disabled={disabled}>
                        {Localization.getString('Save')}
                    </Buttons.StickySaveButton>
                </form>
            </Grid>
        </ContainerWithPadding>
    )
}
export default BookingForm
