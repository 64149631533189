import { SelectOptionModel } from 'traveldesk-ui'
import ArrivalGroupInfoHotelModel from './ArrivalGroupInfoHotelModel'
import ArrivalModel from './ArrivalModel'

export default class ArrivalGroupInfoModel {
    id: number = 0
    groupId: string = ''
    arrivals: ArrivalModel[] = []
    status: ArrivalGroupStatus = ArrivalGroupStatus.Pending
    hotels: ArrivalGroupInfoHotelModel[] = []
    notes: string = ''
    numberOfBookings: number = 0
    phoneNumbers: string = ''
    communicationStatus: ArrivalGroupInfoCommunicationStatus = ArrivalGroupInfoCommunicationStatus.None
    public static GetPhonesHidden(phones: string) {
        return phones
            ? phones
                  .split(',')
                  .map((phone, index) => `${phone.slice(0, 7)}...`)
                  .join(',')
            : undefined
    }
    public static Create(obj: ArrivalGroupInfoModel): ArrivalGroupInfoModel {
        return Object.assign(new ArrivalGroupInfoModel(obj.groupId), obj, {
            arrivals: obj.arrivals?.map((a) => ArrivalModel.Create(a)) ?? [],
            hotels: (obj.hotels || []).map((h) => ArrivalGroupInfoHotelModel.Create(h)),
        })
    }
    constructor(groupId: string) {
        this.groupId = groupId
        this.hotels = []
    }
    // RenderPhones(fontSize?:number) {
    //     return this.PhoneNumbers
    //         ? this.PhoneNumbers.split(',').map((phone,index)=><PInTableList fontSize={fontSize ? `${fontSize}px`: undefined}  key={index}><a href={`tel:${phone}`}>{phone}</a></PInTableList>)
    //         : null
    // }
    // RenderPhonesNoLink(fontSize?:number) {
    //     return this.PhoneNumbers
    //         ? this.PhoneNumbers.split(',').map((phone,index)=><PInTableList fontSize={fontSize ? `${fontSize}px`: undefined}  key={index}>{phone}</PInTableList>)
    //         : null
    // }

    public static get GetStatusOptionsFilter(): SelectOptionModel[] {
        return this.GetStatusOptions.concat([
            { value: ArrivalGroupStatus.Purchased.toString(), label: 'Purchased' },
            { value: ArrivalGroupStatus.Deleted.toString(), label: 'Deleted' },
        ])
    }
    public static get GetStatusOptions(): SelectOptionModel[] {
        return [
            {
                value: '0',
                label: 'Pending',
            },
            //  {
            //     value:"1",
            //     label:"Did No Attend"
            // }, {
            //     value:"2",
            //     label:"Did not Buy"
            // },
            {
                value: '3',
                label: 'Purchased Diff. Name',
            },
            {
                value: '4',
                label: 'Cancelled',
            },
            {
                value: '6',
                label: 'Non tourist',
            },
        ]
    }

    public static StatusDescription(
        s: ArrivalGroupStatus,
        cs: ArrivalGroupInfoCommunicationStatus,
        isDeleted: boolean,
        numberOfBookings: number,
    ) {
        const status = cs > 0 && s < 3 ? (cs >= 16 ? 8 : 7) : s
        return isDeleted
            ? ArrivalGroupStatusDescriptions[-1]
            : numberOfBookings > 0
            ? `${numberOfBookings} Booking${numberOfBookings > 1 ? 's' : ''}`
            : ArrivalGroupStatusDescriptions[status]
    }
    public static StatusStyle(
        s: ArrivalGroupStatus,
        cs: ArrivalGroupInfoCommunicationStatus,
        isDeleted: boolean,
        numberOfBookings: number,
    ) {
        const status = cs > 0 && s < 3 ? (cs >= 16 ? 8 : 7) : s
        return isDeleted
            ? ArrivalStatusStyles[-1]
            : numberOfBookings > 0
            ? ArrivalStatusStyles[5]
            : ArrivalStatusStyles[status]
    }
}
export enum ArrivalGroupInfoCommunicationStatus {
    None = 0,
    CallInitiated = 1,
    MeetInPersonInitiated = 1 << 1,
    MessengerInitiated = 1 << 2,
    LetterInLobbyInitiated = 1 << 3,
    CallResponded = 1 << 4,
    MeetInPersonResponded = 1 << 5,
    MessengerResponded = 1 << 6,
    LetterInLobbyResponded = 1 << 7,
}
export enum ArrivalGroupStatus {
    Deleted = -1,
    Pending,
    DidNotAttend,
    DidNotBuy,
    PurchasedDiffName,
    Cancelled,
    Purchased,
    NonTourist,
}
const ArrivalGroupStatusDescriptions = {
    [-1]: 'Deleted',
    [0]: 'No Comm.',
    [1]: 'Did Not Attend', //remove
    [2]: 'Did Not Buy', //remove
    [3]: 'Purch Diff. Name',
    [4]: 'Cancelled',
    [5]: 'Purchased',
    [6]: 'Non Tourist', //6
    [7]: 'Comm. Initiated',
    [8]: 'Comm. Resp.',
}
const ArrivalStatusStyles = {
    [-1]: { backgroundColor: 'gray', color: '#aaa' },
    [0]: { backgroundColor: 'red', color: 'white' },
    [1]: { backgroundColor: 'orange', color: 'white' },
    [2]: { backgroundColor: 'lightblue' },
    [3]: { backgroundColor: 'lightgreen' },
    [4]: { backgroundColor: 'yellow' },
    [5]: { backgroundColor: 'lightgreen' },
    [6]: { backgroundColor: 'wheat' },
    [7]: { backgroundColor: 'peachpuff' },
    [8]: { backgroundColor: 'lightblue' },
}
