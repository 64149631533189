import { SelectOptionModel } from 'traveldesk-ui'
import moment from 'moment'
export default class BookingOperations {
    public static Create(obj: BookingOperations) {
        return Object.assign(new BookingOperations(), obj, {
            date: moment.utc(obj.date),
            bookingDateTime: moment.utc(obj.date),
        })
    }
    id: number = 0
    date: moment.Moment = moment()
    bookingDateTime: moment.Moment = moment()
    tourId?: number
    offerId: number = 0
    hotelId?: number
    customHotelName: string = ''
    region: string = ''
    guestName: string = ''
    tel: string = ''
    ad: number = 1
    ch: number = 0
    inf: number = 0
    foc: number = 0
    paid: number = 0
    paidTo: string = ''
    collect: number = 0
    collectTo: string = ''
    voucher: string = ''
    status: BookingStatus = BookingStatus.New
}
export enum BookingStatus {
    New,
    PendingVerification,
    QuotationRequest,
    PaymentRequired,
    Cancelled,
    Completed,
}
export const BookingStatusOptions: SelectOptionModel[] = [
    { value: BookingStatus.New, label: 'Normal' },
    { value: BookingStatus.PendingVerification, label: 'Pending' },
    { value: BookingStatus.Cancelled, label: 'Cancelled' },
]
export const BookingStatusDescription = ['Normal', 'Pending Reconfirmation', '', '', 'Cancelled', '']
