import { round } from 'traveldesk-ui'
import moment from 'moment'

export default class AgentOfferPrice {
    public static Create(obj: AgentOfferPrice) {
        return Object.assign(new AgentOfferPrice(), obj, {
            periodStart: moment(obj.periodStart),
            periodEnd: moment(obj.periodEnd),
        })
    }
    offerId: number = 0
    periodStart: moment.Moment = moment()
    periodEnd: moment.Moment = moment()
    pricePerAdultRecUsd: number = 0
    pricePerChildRecUsd: number = 0
    pricePerTripRecUsd: number = 0
    pricePerAdultRec: number = 0
    pricePerChildRec: number = 0
    pricePerTripRec: number = 0
    commission: number = 0
    netPricePerAdultUsd: number = 0
    netPricePerChildUsd: number = 0
    netPricePerTripUsd: number = 0
    netPricePerAdult: number = 0
    netPricePerChild: number = 0
    netPricePerTrip: number = 0
    totalPublishRateUsd = (adults: number, children: number) => {
        return round(
            (adults || 0) * this.pricePerAdultRecUsd +
                (children || 0) * this.pricePerChildRecUsd +
                this.pricePerTripRecUsd,
        )
    }
    totalPublishRate = (adults: number, children: number) => {
        return round(
            (adults || 0) * this.pricePerAdultRec + (children || 0) * this.pricePerChildRec + this.pricePerTripRec,
        )
    }

    totalNetPriceUsd = (adults: number, children: number) => {
        return round(
            (adults || 0) * this.netPricePerAdultUsd +
                (children || 0) * this.netPricePerChildUsd +
                this.netPricePerTripUsd,
        )
    }
    totalNetPrice = (adults: number, children: number) => {
        return round(
            (adults || 0) * this.netPricePerAdult + (children || 0) * this.netPricePerChild + this.netPricePerTrip,
        )
    }
}
