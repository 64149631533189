import CancellationPolicy from './CancellationPolicy'

export class Supplier {
    static Create(obj: Supplier) {
        return Object.assign(new Supplier(), obj)
    }
    name: string = ''
    code: string = ''
    address: string = ''
    countryId?: number
    taxRegistrationNumber: string = ''
    tradeLicenseNumber: string = ''
    paymentDetails: SupplierPaymentDetails = new SupplierPaymentDetails()
    isAcknowledged: boolean = false
    customerSupport: SupportContacts = new SupportContacts()
    operationsSupport: SupportContacts = new SupportContacts()
    accountsDepartment: SupportContacts = new SupportContacts()
    defaultSettings: SupplierDefaultSettings = new SupplierDefaultSettings()
    cancellationPolicy: CancellationPolicy[] = [] as CancellationPolicy[]
    get isDefaultNotesToClientPostBooking() {
        return this.defaultSettings.notesToClientPostBooking && this.defaultSettings.notesToClientPostBooking.length > 0
    }
}
export class SupplierPaymentDetails {
    bankName: string = ''
    accountNumber: string = ''
    ibanNumber: string = ''
    swiftCode: string = ''
    bankBranchAddress: string = ''
}
export class SupportContacts {
    primaryName: string = ''
    secondaryName: string = ''
    emergencyName: string = ''
    primaryPhone: string = ''
    secondaryPhone: string = ''
    emergencyPhone: string = ''
    primaryEmail: string = ''
    secondaryEmail: string = ''
    emergencyEmail: string = ''
}
export class SupplierDefaultSettings {
    faq: string = ''
    termsAndConditions: string = ''
    notesToClientPostBooking: string = ''
}
