import { SelectOptionModel } from 'traveldesk-ui'
import { AppThunkAction } from '.'
import { LoadingStore } from 'traveldesk-ui'

import PickUpTimesApiService from './../services/PickUpTimesApiService'
import { Reducer, Action } from 'redux'
import PickUpTime from './../models/PickUpTime'
import { NotificationsStore } from 'traveldesk-ui'
import { NotificationModel, NotificationType } from 'traveldesk-ui'
import { push, RouterAction } from 'connected-react-router'
import Hotel from './../models/Hotel'
import HotelsApiService from './../services/HotelsApiService'
import CitiesApiService from './../services/CitiesApiService'

export interface State {
    options: SelectOptionModel[]
    pickUpTimesManage: PickUpTime[]
    pickUpTime?: PickUpTime
    hotelsOptions: Hotel[]
    citiesOptions: SelectOptionModel[]
}
const SET_HOTELS_OPTIONS = 'A_SET_HOTELS_OPTIONS'
interface SetHotelsOptions {
    type: 'A_SET_HOTELS_OPTIONS'
    payload: Hotel[]
}
const SET_CITIES_OPTIONS = 'A_SET_CITIES_OPTIONS'
interface SetCitiesOptions {
    type: 'A_SET_CITIES_OPTIONS'
    payload: SelectOptionModel[]
}
const PICKUPTIME_SAVED = 'A_PICKUPTIME_SAVED'
interface PickUpTimeSaved {
    type: 'A_PICKUPTIME_SAVED'
    payload: PickUpTime
}
const SET_PICKUPTIME_MANAGE = 'A_SET_PICKUPTIME_MANAGE'
interface SetPickUpTimeManage {
    type: 'A_SET_PICKUPTIME_MANAGE'
    payload: PickUpTime | undefined
}
const RECEIVE_PICKUPTIMES_MANAGE_LIST = 'A_RECEIVE_PICKUPTIMES_MANAGE_LIST'
interface ReceivePickUpTimesManageList {
    type: 'A_RECEIVE_PICKUPTIMES_MANAGE_LIST'
    payload: PickUpTime[]
}
const RECEIVE_PICKUPTIMES_OPTIONS = 'A_RECEIVE_PICKUPTIMES_OPTIONS'
interface ReceivePickUpTimesOptions {
    type: 'A_RECEIVE_PICKUPTIMES_OPTIONS'
    payload: SelectOptionModel[]
}
type KnownAction =
    | PickUpTimeSaved
    | ReceivePickUpTimesOptions
    | ReceivePickUpTimesManageList
    | SetPickUpTimeManage
    | RouterAction
    | SetHotelsOptions
    | SetCitiesOptions

export const actionCreators = {
    getCitiesOptions:
        (): AppThunkAction<KnownAction | LoadingStore.KnownAction | NotificationsStore.KnownAction> =>
        async (dispatch, getState) => {
            if (getState().pickUpTimes.hotelsOptions.length == 0) {
                let fetchTask = await CitiesApiService.getCitiesInSuppliersCountry(dispatch)
                const payload = fetchTask.Content
                if (fetchTask.IsOk && payload) {
                    dispatch({ type: SET_CITIES_OPTIONS, payload })
                }
            }
        },
    getHotelsOptions:
        (): AppThunkAction<KnownAction | LoadingStore.KnownAction | NotificationsStore.KnownAction> =>
        async (dispatch, getState) => {
            if (getState().pickUpTimes.hotelsOptions.length == 0) {
                let fetchTask = await HotelsApiService.getHotelsInSuppliersCountry(dispatch)
                const payload = fetchTask.Content
                if (fetchTask.IsOk && payload) {
                    dispatch({ type: SET_HOTELS_OPTIONS, payload })
                }
            }
        },
    managePickUpTime:
        (id: number): AppThunkAction<KnownAction | LoadingStore.KnownAction | NotificationsStore.KnownAction> =>
        async (dispatch, getState) => {
            if (id == 0) {
                dispatch({ type: SET_PICKUPTIME_MANAGE, payload: PickUpTime.Create(new PickUpTime()) })
            } else {
                const exTour = getState().tours.tour
                if (exTour && exTour.id != id) {
                }
                dispatch(LoadingStore.actionCreators.incrementLoading())
                let fetchTask = await PickUpTimesApiService.getForEdit(dispatch, id)
                const data = fetchTask.Content
                if (fetchTask.IsOk && data) {
                    dispatch({ type: SET_PICKUPTIME_MANAGE, payload: PickUpTime.Create(data) })
                } else {
                    const localization = getState().localization.locale
                    dispatch(
                        NotificationsStore.actionCreators.add(
                            new NotificationModel(
                                NotificationType.error,
                                localization.getString('Not found'),
                                localization.getString('Time slot group not found or you are not allowed to manage it'),
                            ),
                        ),
                    )
                    dispatch(push('/timeslots'))
                }
                dispatch(LoadingStore.actionCreators.decrementLoading())
            }
        },
    save:
        (pupt: PickUpTime): AppThunkAction<KnownAction | LoadingStore.KnownAction> =>
        async (dispatch) => {
            dispatch(LoadingStore.actionCreators.incrementLoading())
            let fetchTask = await PickUpTimesApiService.save(pupt, dispatch)
            if (fetchTask.IsOk && fetchTask.Content) {
                dispatch({ type: PICKUPTIME_SAVED, payload: fetchTask.Content })
            }
            dispatch(push('/pickuptimes'))
            dispatch(LoadingStore.actionCreators.decrementLoading())
        },
    requestPickUpTimesManageList:
        (): AppThunkAction<KnownAction | LoadingStore.KnownAction> => async (dispatch, getState) => {
            dispatch(LoadingStore.actionCreators.incrementLoading())
            let fetchTask = await PickUpTimesApiService.getManageList(dispatch)
            const data = fetchTask.Content
            if (fetchTask.IsOk && data) {
                const payload = data
                dispatch({ type: RECEIVE_PICKUPTIMES_MANAGE_LIST, payload })
            }
            dispatch(LoadingStore.actionCreators.decrementLoading())
        },
    requestPickUpTimesOptions:
        (): AppThunkAction<KnownAction | LoadingStore.KnownAction> => async (dispatch, getState) => {
            dispatch(LoadingStore.actionCreators.incrementLoading())
            let fetchTask = await PickUpTimesApiService.getOptions(dispatch)
            const data = fetchTask.Content
            if (fetchTask.IsOk && data) {
                const Localization = getState().localization.locale
                const payload = [
                    { value: undefined, label: Localization.getString('Not applicable') } as SelectOptionModel,
                ].concat(data)
                dispatch({ type: RECEIVE_PICKUPTIMES_OPTIONS, payload })
            }
            dispatch(LoadingStore.actionCreators.decrementLoading())
        },
}
const unloadedState: State = {
    options: [] as unknown as SelectOptionModel[],
    pickUpTimesManage: [] as PickUpTime[],
    hotelsOptions: [] as Hotel[],
    citiesOptions: [] as SelectOptionModel[],
}
export const reducer: Reducer<State> = (state: State = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction
    switch (action.type) {
        case PICKUPTIME_SAVED:
            const pickUpTimesManage = state.pickUpTimesManage
                ? state.pickUpTimesManage.some((x) => x.id == action.payload.id)
                    ? state.pickUpTimesManage.map((x) => (x.id == action.payload.id ? action.payload : x))
                    : state.pickUpTimesManage.concat([action.payload])
                : []
            return { ...state, pickUpTime: undefined, pickUpTimesManage }
        case SET_PICKUPTIME_MANAGE:
            return { ...state, pickUpTime: action.payload }
        case RECEIVE_PICKUPTIMES_OPTIONS:
            return { ...state, options: action.payload }
        case RECEIVE_PICKUPTIMES_MANAGE_LIST:
            return { ...state, pickUpTimesManage: action.payload }
        case SET_HOTELS_OPTIONS:
            return { ...state, hotelsOptions: action.payload }
        case SET_CITIES_OPTIONS:
            return { ...state, citiesOptions: action.payload }
    }
    return state || unloadedState
}
