import ArrivalModel from '../pages/Arrivals/ArrivalModel'
import { LoadingStore, EntitiesPaginatedModel, SelectOptionModel } from 'traveldesk-ui'
import { AppThunkAction } from './'
import ArrivalsApiService from '../services/ArrivalsApiService'
import ArrivalsDetailsFilter from '../pages/Arrivals/ArrivalsFilterModel'
import { Reducer, Action } from 'redux'
import Hotel from '../models/Hotel'
import HotelsApiService from '../services/HotelsApiService'
import StaffModel from '../models/StaffModel'
import ArrivalGroupInfoModel from '../pages/Arrivals/ArrivalGroupInfoModel'

export interface State {
    arrivals: EntitiesPaginatedModel<ArrivalModel>
    hotels: Hotel[]
    hotelsHash: Map<number, Hotel>
    sales: StaffModel[]
}
const A_SET_SALES = 'A_SET_SALES'
interface SetSalesOptions {
    type: 'A_SET_SALES'
    sales: StaffModel[]
}
const A_SET_HOTELS = 'A_SET_HOTELS'
interface SetHotelsAction {
    type: 'A_SET_HOTELS'
    hotels: Hotel[]
    hotelsHash: Map<number, Hotel>
}
const A_UPDATE_GROUP_INFO = 'A_UPDATE_GROUP_INFO'
interface UpdateGroupInfo {
    type: 'A_UPDATE_GROUP_INFO'
    payload: ArrivalGroupInfoModel
}
const A_SET_ARRIVALS = 'A_SET_ARRIVALS'
interface SetArrivalsAction {
    type: 'A_SET_ARRIVALS'
    payload: EntitiesPaginatedModel<ArrivalModel>
}

type KnownAction = SetArrivalsAction | SetHotelsAction | SetSalesOptions | UpdateGroupInfo

export const actionCreators = {
    requestSales: (): AppThunkAction<KnownAction | LoadingStore.KnownAction> => async (dispatch) => {
        // dispatch(LoadingStore.actionCreators.incrementLoading())
        let fetchTask = await ArrivalsApiService.getSales(dispatch)
        const data = fetchTask.Content
        if (fetchTask.IsOk && data) {
            dispatch({ type: A_SET_SALES, sales: data })
        }
    },
    requestHotels: (): AppThunkAction<KnownAction | LoadingStore.KnownAction> => async (dispatch) => {
        dispatch(LoadingStore.actionCreators.incrementLoading())
        let fetchTask = await HotelsApiService.getHotelsWithArrivalsByArrivals(dispatch) // apiCallWrapper<TourManageTableModel[]>(`/api/tours/managelist`, 'GET', dispatch, null)
        const data = fetchTask.Content

        if (fetchTask.IsOk && data) {
            const hotels = data.sort((a, b) => (a.name > b.name ? 1 : -1))
            const hotelsHash =
                data?.reduce((res, next) => {
                    res.set(next.id, next)
                    return res
                }, new Map<number, Hotel>()) ?? new Map<number, Hotel>()
            dispatch({ type: A_SET_HOTELS, hotels, hotelsHash })
        }
        dispatch(LoadingStore.actionCreators.decrementLoading())
    },
    saveGroupInfo: (groupInfo: ArrivalGroupInfoModel): AppThunkAction<KnownAction | LoadingStore.KnownAction> => async (
        dispatch,
    ) => {
        dispatch(LoadingStore.actionCreators.incrementLoading())
        console.log(groupInfo)
        let fetchTask = await ArrivalsApiService.saveGroupInfo(dispatch, groupInfo)
        const data = fetchTask.Content
        if (fetchTask.IsOk && data) {
            dispatch({ type: A_UPDATE_GROUP_INFO, payload: data })
        }
        dispatch(LoadingStore.actionCreators.decrementLoading())
    },
    requestArrivals: (filter: ArrivalsDetailsFilter): AppThunkAction<KnownAction | LoadingStore.KnownAction> => async (
        dispatch,
    ) => {
        dispatch(LoadingStore.actionCreators.incrementLoading())
        let fetchTask = await ArrivalsApiService.get(dispatch, filter) // apiCallWrapper<TourManageTableModel[]>(`/api/tours/managelist`, 'GET', dispatch, null)
        const data = fetchTask.Content
        if (fetchTask.IsOk && data) {
            data.entities = data.entities.map(x => ArrivalModel.Create(x))
            dispatch({ type: A_SET_ARRIVALS, payload: data })
        }
        dispatch(LoadingStore.actionCreators.decrementLoading())
    },
}
const unloadedState: State = {
    arrivals: new EntitiesPaginatedModel<ArrivalModel>(),
    hotels: [] = [] as Hotel[],
    hotelsHash: new Map<number, Hotel>(),
    sales: [] = [] as StaffModel[],
}
export const reducer: Reducer<State> = (state: State = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction
    switch (action.type) {
        case A_SET_SALES:
            return {
                ...state,
                sales: action.sales,
            }
        case A_SET_HOTELS:
            return {
                ...state,
                hotels: action.hotels,
                hotelsHash: action.hotelsHash,
            }
        case A_UPDATE_GROUP_INFO:
            const gi = action.payload
            return {
                ...state,
                arrivals: Object.assign(new EntitiesPaginatedModel(), {
                    entities: state.arrivals.entities.map((a) =>
                        a.groupId == gi.groupId
                            ? Object.assign(ArrivalModel.Create(a), {
                                groupInfo: ArrivalGroupInfoModel.Create(gi),
                            })
                            : a,
                    ),
                    pagingInfo: state.arrivals.pagingInfo,
                }),
            }
        case A_SET_ARRIVALS:
            return {
                ...state,
                arrivals: action.payload,
            }
        default:
    }
    return state || unloadedState
}
