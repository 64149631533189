import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from '../../store'
import { useForm } from 'react-hook-form'
import { Row, Col, TextFormField } from 'traveldesk-ui'
import { nameof } from 'traveldesk-ui'
import { Supplier } from '../../models/Supplier'
import { SupportContacts } from './../../models/Supplier'
import { Buttons } from 'traveldesk-ui'
import { actionCreators as supplierActions } from './../../store/Supplier'
import { useSupplier } from './../../effects/useSupplier'

interface Props extends RouteComponentProps<{ tab: string }> {}
const AdminContacts = (props: Props) => {
    const dispatch = useDispatch()
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const supplier = useSupplier()
    const { reset, register, handleSubmit } = useForm({ defaultValues: supplier })
    const onSubmit = (data: any) => {
        dispatch(supplierActions.updateSupplier(supplier, data))
    }
    React.useEffect(() => {
        reset(supplier)
    }, [supplier])
    return (
        <div className="tab-content-block is-active" data-tab="tab-v01">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col type="100">
                        <h2>{Localization.getString('Contacts')}</h2>
                        <p>{Localization.getString('Contacts Notes')}</p>
                        <hr />
                        <h3>{Localization.getString('Customer Support')}</h3>
                        <p>
                            {Localization.getString(
                                'These are the direct contacts which will be given to the customer once the booking is complete',
                            )}
                        </p>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>{Localization.getString('Description')}</th>
                                    <th>{Localization.getString('Man Name')}</th>
                                    <th>{Localization.getString('Phone number')}</th>
                                    <th>{Localization.getString('Email')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{Localization.getString('Primary Support Line')}</td>
                                    <td>
                                        <TextFormField
                                            defaultValue={supplier?.customerSupport.primaryName}
                                            label={''}
                                            // isRequired
                                            register={register}
                                            name={`${nameof<Supplier>('customerSupport')}.${nameof<SupportContacts>(
                                                'primaryName',
                                            )}`}
                                        />
                                    </td>
                                    <td>
                                        <TextFormField
                                            defaultValue={supplier?.customerSupport.primaryPhone}
                                            label={''}
                                            // isRequired
                                            register={register}
                                            name={`${nameof<Supplier>('customerSupport')}.${nameof<SupportContacts>(
                                                'primaryPhone',
                                            )}`}
                                        />
                                    </td>
                                    <td>
                                        <TextFormField
                                            defaultValue={supplier?.customerSupport.primaryEmail}
                                            label={''}
                                            // isRequired
                                            register={register}
                                            name={`${nameof<Supplier>('customerSupport')}.${nameof<SupportContacts>(
                                                'primaryEmail',
                                            )}`}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>{Localization.getString('Secondary Support Line')}</td>
                                    <td>
                                        <TextFormField
                                            defaultValue={supplier?.customerSupport.secondaryName}
                                            label={''}
                                            // isRequired
                                            register={register}
                                            name={`${nameof<Supplier>('customerSupport')}.${nameof<SupportContacts>(
                                                'secondaryName',
                                            )}`}
                                        />
                                    </td>
                                    <td>
                                        <TextFormField
                                            defaultValue={supplier?.customerSupport.secondaryPhone}
                                            label={''}
                                            // isRequired
                                            // innerRef={register()}
                                            register={register}
                                            name={`${nameof<Supplier>('customerSupport')}.${nameof<SupportContacts>(
                                                'secondaryPhone',
                                            )}`}
                                        />
                                    </td>
                                    <td>
                                        <TextFormField
                                            defaultValue={supplier?.customerSupport.secondaryEmail}
                                            label={''}
                                            // isRequired
                                            // innerRef={register()}
                                            register={register}
                                            name={`${nameof<Supplier>('customerSupport')}.${nameof<SupportContacts>(
                                                'secondaryEmail',
                                            )}`}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>{Localization.getString('After hours or emergency line')}</td>
                                    <td>
                                        <TextFormField
                                            defaultValue={supplier?.customerSupport.emergencyName}
                                            label={''}
                                            // isRequired
                                            // innerRef={register()}
                                            register={register}
                                            name={`${nameof<Supplier>('customerSupport')}.${nameof<SupportContacts>(
                                                'emergencyName',
                                            )}`}
                                        />
                                    </td>
                                    <td>
                                        <TextFormField
                                            defaultValue={supplier?.customerSupport.emergencyPhone}
                                            label={''}
                                            // isRequired
                                            // innerRef={register()}
                                            register={register}
                                            name={`${nameof<Supplier>('customerSupport')}.${nameof<SupportContacts>(
                                                'emergencyPhone',
                                            )}`}
                                        />
                                    </td>
                                    <td>
                                        <TextFormField
                                            defaultValue={supplier?.customerSupport.emergencyEmail}
                                            label={''}
                                            // isRequired
                                            // innerRef={register()}
                                            register={register}
                                            name={`${nameof<Supplier>('customerSupport')}.${nameof<SupportContacts>(
                                                'emergencyEmail',
                                            )}`}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr />
                        <h3>{Localization.getString('Reservations/Operations Support')}</h3>
                        <p>{Localization.getString('Reservations Support Info')}</p>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>{Localization.getString('Description')}</th>
                                    <th>{Localization.getString('Phone number')}</th>
                                    <th>{Localization.getString('Email')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{Localization.getString('Primary Support Line')}</td>
                                    <td>
                                        <TextFormField
                                            defaultValue={supplier?.operationsSupport.primaryPhone}
                                            label={''}
                                            // isRequired
                                            // innerRef={register()}
                                            register={register}
                                            name={`${nameof<Supplier>('operationsSupport')}.${nameof<SupportContacts>(
                                                'primaryPhone',
                                            )}`}
                                        />
                                    </td>
                                    <td>
                                        <TextFormField
                                            defaultValue={supplier?.operationsSupport.primaryEmail}
                                            label={''}
                                            // isRequired
                                            // innerRef={register()}
                                            register={register}
                                            name={`${nameof<Supplier>('operationsSupport')}.${nameof<SupportContacts>(
                                                'primaryEmail',
                                            )}`}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>{Localization.getString('Secondary Support Line')}</td>
                                    <td>
                                        <TextFormField
                                            defaultValue={supplier?.operationsSupport.secondaryPhone}
                                            label={''}
                                            // isRequired
                                            // innerRef={register()}
                                            register={register}
                                            name={`${nameof<Supplier>('operationsSupport')}.${nameof<SupportContacts>(
                                                'secondaryPhone',
                                            )}`}
                                        />
                                    </td>
                                    <td>
                                        <TextFormField
                                            defaultValue={supplier?.operationsSupport.secondaryEmail}
                                            label={''}
                                            // isRequired
                                            // innerRef={register()}
                                            register={register}
                                            name={`${nameof<Supplier>('operationsSupport')}.${nameof<SupportContacts>(
                                                'secondaryEmail',
                                            )}`}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>{Localization.getString('After hours or emergency line')}</td>
                                    <td>
                                        <TextFormField
                                            defaultValue={supplier?.operationsSupport.emergencyPhone}
                                            label={''}
                                            // isRequired
                                            // innerRef={register()}
                                            register={register}
                                            name={`${nameof<Supplier>('operationsSupport')}.${nameof<SupportContacts>(
                                                'emergencyPhone',
                                            )}`}
                                        />
                                    </td>
                                    <td>
                                        <TextFormField
                                            defaultValue={supplier?.operationsSupport.emergencyEmail}
                                            label={''}
                                            // isRequired
                                            // innerRef={register()}
                                            register={register}
                                            name={`${nameof<Supplier>('operationsSupport')}.${nameof<SupportContacts>(
                                                'emergencyEmail',
                                            )}`}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr />
                        <h3>{Localization.getString('Account Department')}</h3>
                        <p>
                            {Localization.getString(
                                'These contacts will be contacted for any enquiries regarding accounting related matters',
                            )}
                        </p>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>{Localization.getString('Description')}</th>
                                    <th>{Localization.getString('Phone number')}</th>
                                    <th>{Localization.getString('Email')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{Localization.getString('Primary Support Line')}</td>
                                    <td>
                                        <TextFormField
                                            defaultValue={supplier?.accountsDepartment.primaryPhone}
                                            label={''}
                                            // isRequired
                                            // innerRef={register()}
                                            register={register}
                                            name={`${nameof<Supplier>('accountsDepartment')}.${nameof<SupportContacts>(
                                                'primaryPhone',
                                            )}`}
                                        />
                                    </td>
                                    <td>
                                        <TextFormField
                                            defaultValue={supplier?.accountsDepartment.primaryEmail}
                                            label={''}
                                            // isRequired
                                            // innerRef={register()}
                                            register={register}
                                            name={`${nameof<Supplier>('accountsDepartment')}.${nameof<SupportContacts>(
                                                'primaryEmail',
                                            )}`}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>{Localization.getString('Secondary Support Line')}</td>
                                    <td>
                                        <TextFormField
                                            defaultValue={supplier?.accountsDepartment.secondaryPhone}
                                            label={''}
                                            // isRequired
                                            // innerRef={register()}
                                            register={register}
                                            name={`${nameof<Supplier>('accountsDepartment')}.${nameof<SupportContacts>(
                                                'secondaryPhone',
                                            )}`}
                                        />
                                    </td>
                                    <td>
                                        <TextFormField
                                            defaultValue={supplier?.accountsDepartment.secondaryEmail}
                                            label={''}
                                            // isRequired
                                            // innerRef={register()}
                                            register={register}
                                            name={`${nameof<Supplier>('accountsDepartment')}.${nameof<SupportContacts>(
                                                'secondaryEmail',
                                            )}`}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>{Localization.getString('After hours or emergency line')}</td>
                                    <td>
                                        <TextFormField
                                            defaultValue={supplier?.accountsDepartment.emergencyPhone}
                                            label={''}
                                            // isRequired
                                            // innerRef={register()}
                                            register={register}
                                            name={`${nameof<Supplier>('accountsDepartment')}.${nameof<SupportContacts>(
                                                'emergencyPhone',
                                            )}`}
                                        />
                                    </td>
                                    <td>
                                        <TextFormField
                                            defaultValue={supplier?.accountsDepartment.emergencyEmail}
                                            label={''}
                                            // isRequired
                                            // innerRef={register()}
                                            register={register}
                                            name={`${nameof<Supplier>('accountsDepartment')}.${nameof<SupportContacts>(
                                                'emergencyEmail',
                                            )}`}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <Buttons.StickySaveButtonTabs>{Localization.getString('Save')}</Buttons.StickySaveButtonTabs>
            </form>
        </div>
    )
}
export default AdminContacts
