import { BookingStatus } from '../../../models/BookingOperations'
import moment from 'moment'

export default class BookingAgent {
    public static Create(obj: BookingAgent) {
        return Object.assign(new BookingAgent(), obj, {
            date: moment(obj.date),
        })
    }
    id: number = 0
    date: moment.Moment = moment()
    offerId: number = 0
    hotelId: number = 0
    numAdults: number = 0
    numChildren: number = 0
    numInfants: number = 0
    numFoc: number = 0
    grossSales: number = 0
    agencyReceivable: number = 0
    agentId: number = 0
    staffAgentId: number = 0
    status: BookingStatus = BookingStatus.New
    pickUpTime: string = ''
    pickUpTimeStart: number = 0
    pickUpTimeEnd: number = 0
    voucher: string = ''
    notes: string = ''
    canDownloadTickets: boolean = false
    get customClass() {
        switch (this.status) {
            case BookingStatus.Cancelled:
                return 'cancelled'
            case BookingStatus.PendingVerification:
                return 'pending'
            default:
                return undefined
        }
    }
}
