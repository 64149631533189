import { apiCallWrapper, EntitiesPaginatedModel, PaginationModel } from 'traveldesk-ui'
import ArrivalsDetailsFilter from '../pages/Arrivals/ArrivalsFilterModel'
import ArrivalModel from '../pages/Arrivals/ArrivalModel'
import StaffModel from '../models/StaffModel'
import ArrivalGroupInfoModel from '../pages/Arrivals/ArrivalGroupInfoModel'

export default class ArrivalsApiService {
    static getSales = async (dispatch: any) => {
        const data = await apiCallWrapper<StaffModel[]>(`/api/arrivals/sales`, 'GET', dispatch)
        data.Content = data.Content?.map((s) => StaffModel.Create(s)) ?? []
        return data
    }
    static saveGroupInfo = async (dispatch: any, groupInfo: ArrivalGroupInfoModel) => {
        const data = await apiCallWrapper<ArrivalGroupInfoModel>(`/api/arrivals/groupinfo`, 'POST', dispatch, groupInfo)
        data.Content = Object.assign(new ArrivalGroupInfoModel(data.Content?.groupId ?? ''), data.Content)
        return data
    }
    static get = async (dispatch: any, filter: ArrivalsDetailsFilter) => {
        const data = await apiCallWrapper<EntitiesPaginatedModel<ArrivalModel>>(
            `/api/arrivals/filtered`,
            'POST',
            dispatch,
            filter,
        )
        data.Content = Object.assign(new EntitiesPaginatedModel<ArrivalModel>(), data.Content, {
            entities: data.Content?.entities.map((x) => ArrivalModel.Create(x)) ?? [],
            pagingInfo: PaginationModel.Create(data.Content?.pagingInfo || new PaginationModel()),
        })
        return data
    }
}
