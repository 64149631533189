import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import * as AgencyBookingsState from '../../store/AgencyBookings'
import {
    FilterValueType,
    DataTableColumnModel,
    FilterType,
    DataTableModel,
    DataTable,
    FilterValuesWrapper,
    DataTableModelCustomAction,
    CheckBox,
    Button,
    apiCallWrapper,
    apiCallWrapperNoDispatch,
    apiCallWrapperNoDispatchBlob,
} from 'traveldesk-ui'
import BookingsRequest from './Models/BookingsRequest'
import BookingAgent from './Models/BookingAgent'
import moment from 'moment'
import OfferAgent from './Models/OfferAgent'
import Hotel from '../../models/Hotel'
import { BookingStatusOptions, BookingStatusDescription } from '../../models/BookingOperations'
import { PaginationModel } from 'traveldesk-ui'
import { Link } from 'react-router-dom'
const download = require('downloadjs')

interface Props extends RouteComponentProps<{ groupId: string }> { }
const Bookings = (props: Props) => {
    const dispatch = useDispatch()
    const bookings = useSelector((state: ApplicationState) => state.agencyBookings.data)
    const offers = useSelector((state: ApplicationState) => state.agencyBookings.offers)
    const offersHash = useSelector((state: ApplicationState) => state.agencyBookings.offersHash)
    const agents = useSelector((state: ApplicationState) => state.agencyBookings.agents)
    const agentsHash = useSelector((state: ApplicationState) => state.agencyBookings.agentsHash)
    const hotels = useSelector((state: ApplicationState) => state.agencyBookings.hotels)
    const hotelsHash = useSelector((state: ApplicationState) => state.agencyBookings.hotelsHash)
    React.useEffect(() => {
        dispatch(AgencyBookingsState.actionCreators.requestAgencyBookingsAgents())
        dispatch(AgencyBookingsState.actionCreators.requestAgencyBookingsOffers())
        dispatch(AgencyBookingsState.actionCreators.requestAgencyBookingsHotels())
    }, [])
    const initFilter = () => {
        return new Map<string, FilterValueType>()
    }
    const [request, setRequest] = React.useState(new BookingsRequest())
    const getData = () => {
        dispatch(AgencyBookingsState.actionCreators.requestAgencyBookings(request))
    }
    React.useEffect(() => {
        getData()
    }, [request])
    const [filter, setFilter] = React.useState(initFilter())
    React.useEffect(() => {
        if (props.match.params.groupId) {
            onFilterValueChange('guestName', { type: FilterType.TEXT, value: props.match.params.groupId })
        }
    }, [props.match.params])
    const onShowMy = () => {
        let fv = filter.get('showMy')
        const v = !(fv && fv.value)
        onFilterValueChange('showMy', { type: FilterType.TEXT, value: v ? '1' : '0' })
    }
    const initDataTable = (
        offers: OfferAgent[],
        offersHash: Map<number, OfferAgent>,
        hotels: Hotel[],
        hotelsHash: Map<number, Hotel>,
    ) => {
        const dataTableColumns: DataTableColumnModel[] = [
            new DataTableColumnModel({
                displayName: 'Id',
                fieldName: 'id',
                filterType: FilterType.TEXTNUMBER,
            }),
            new DataTableColumnModel({
                displayName: 'Date',
                fieldName: 'date',
                width: 80,
                filterType: FilterType.DATERANGE,
                sortable: true,
            }),
            new DataTableColumnModel({
                displayName: 'Offer',
                fieldName: 'offer',
                width: 200,
                filterType: FilterType.MULTISELECT,
                customRenderer: (obj: BookingAgent) => {
                    const h = offersHash.get(obj.offerId)
                    if (h) {
                        return h.name
                    }
                    return null
                },
                customExcelRenderer: (obj: BookingAgent) => {
                    const h = offersHash.get(obj.offerId)
                    if (h) {
                        return h.name
                    }
                    return ""
                },
                filterOptions: offers.map((x) => ({ value: x.id, label: x.name })),
                sortable: true,
            }),
            new DataTableColumnModel({
                displayName: 'Hotel',
                fieldName: 'hotel',
                width: 140,
                filterType: FilterType.MULTISELECT,
                filterOptions: hotels.map((x) => ({
                    value: x.id.toString(),
                    label: `${x.name} (${x.cityId}, ${x.regionId || ''})`,
                })),
                customRenderer: (obj: BookingAgent) => {
                    const h = hotelsHash.get(obj.hotelId)
                    if (h) {
                        return (
                            h && (
                                <div>
                                    {h.name}
                                    <br />
                                    <span style={{ fontSize: 8, color: '#aaa' }}>
                                        {h.cityName}, {h.regionName}
                                    </span>
                                </div>
                            )
                        )
                    }
                    return null
                },
                customExcelRenderer: (obj: BookingAgent) => {
                    const h = hotelsHash.get(obj.hotelId)
                    if (h) {
                        return h.name
                    }
                    return ""
                },
                sortable: true,
            }),
            new DataTableColumnModel({
                displayName: 'Pick Up Time',
                fieldName: 'pickUpTime',
                width: 60,
                sortable: true,
            }),
            new DataTableColumnModel({
                displayName: 'Room #',
                fieldName: 'roomNumber',
                width: 60,
                filterType: FilterType.TEXT,
                sortable: true,
            }),
            new DataTableColumnModel({
                displayName: 'Guest Name',
                fieldName: 'guestName',
                width: 120,
                filterType: FilterType.TEXT,
                sortable: true,
            }),
            new DataTableColumnModel({
                displayName: 'Tel',
                fieldName: 'tel',
                width: 80,
                filterType: FilterType.TEXT,
                sortable: true,
            }),
            new DataTableColumnModel({
                displayName: 'Ad',
                fieldName: 'numAdults',
                width: 60,
                sortable: true,
            }),
            new DataTableColumnModel({
                displayName: 'Ch',
                fieldName: 'numChildren',
                width: 60,
                sortable: true,
            }),
            new DataTableColumnModel({
                displayName: 'Inf',
                fieldName: 'numInfants',
                width: 60,
                sortable: true,
            }),
            new DataTableColumnModel({
                displayName: 'FOC',
                fieldName: 'numFoc',
                width: 60,
                sortable: true,
            }),
            new DataTableColumnModel({
                displayName: 'Agent',
                fieldName: 'agent',
                sortable: true,
                filterType: FilterType.MULTISELECT,
                filterOptions: agents.map((x) => ({
                    value: x.id.toString(),
                    label: x.name,
                })),
                customRenderer: (obj: BookingAgent) => {
                    return agentsHash.get(obj.agentId)?.name ?? agentsHash.get(-obj.staffAgentId)?.name ?? ''
                },
                customExcelRenderer: (obj: BookingAgent) => {
                    return agentsHash.get(obj.agentId)?.name ?? agentsHash.get(-obj.staffAgentId)?.name ?? ''
                }
            }),
            new DataTableColumnModel({
                displayName: 'Voucher',
                fieldName: 'voucher',
                sortable: true,
                filterType: FilterType.TEXT,
            }),
            new DataTableColumnModel({
                displayName: 'Notes',
                fieldName: 'notes',
                sortable: true,
                filterType: FilterType.TEXT,
            }),
            new DataTableColumnModel({
                displayName: 'Tickets',
                fieldName: 'canDownloadTickets',
                width: 64,
                isExportExcel: false,
                customRenderer: (obj: BookingAgent) => {
                    return obj.canDownloadTickets ? (
                        <div onClick={downloadTickets(obj)} title="Download tickets" style={{ textAlign: 'center' }}>
                            <i className="clickable fa fa-download" />
                        </div>
                    ) : null
                },
            }),
            // new DataTableColumnModel({
            //     displayName: 'Agent',
            //     fieldName: 'agent',
            //     sortable: true,
            // }),
            new DataTableColumnModel({
                displayName: 'Gross Sales',
                fieldName: 'grossSales',
                customRenderer: (obj: BookingAgent) => obj.grossSales.toFixed(2),
                sortable: true,
            }),
            new DataTableColumnModel({
                displayName: 'Agency Receivable',
                fieldName: 'agencyReceivable',
                customRenderer: (obj: BookingAgent) => obj.agencyReceivable.toFixed(2),
            }),
            new DataTableColumnModel({
                displayName: 'Status',
                fieldName: 'Status',
                width: 80,
                filterType: FilterType.MULTISELECT,
                filterOptions: BookingStatusOptions,
                customRenderer: (obj: BookingAgent) => BookingStatusDescription[obj.status],
                customExcelRenderer: (obj: BookingAgent) => BookingStatusDescription[obj.status],

            }),
        ]
        return new DataTableModel({
            columns: dataTableColumns,
            name: 'Bookings',
            fileName: 'bookings.xlsx',
            customActions: [
                new DataTableModelCustomAction(
                    () => { },
                    () => (
                        <Link to="/agent/bookings/add">
                            <i className="fa fa-plus" />
                        </Link>
                    ),
                    'Add new booking',
                ),
                new DataTableModelCustomAction(
                    () => { },
                    () => (
                        <CheckBox
                            name="showMy"
                            label="Only my bookings"
                            onClick={onShowMy}
                            isChecked={(filter.get('showMy')?.value ?? '0') == '1' ? true : false}
                        />
                    ),
                ),
            ],
        })
    }
    const downloadTickets = (obj: BookingAgent) => async () => {
        const data = await apiCallWrapperNoDispatchBlob(`/api/agent/bookings/downloadtickets/${obj.id}`)
        if (data.IsOk && data.Content) {
            download(data.Content, data.FileName, 'pdf')
        } else {
            alert(data.ErrorTitle)
        }
    }
    const onClearFilter = () => {
        setRequest(Object.assign(new BookingsRequest()))
        setFilter(initFilter())
    }
    const onPageChanged = (page: number) => {
        setRequest(Object.assign(new BookingsRequest(), request, { page }))
    }
    const onRefresh = () => {
        getData()
        // dispatch(AgencyCommissionsState.actionCreators.requestAgencyCommissions(request))
    }
    const onSortChanged = (sortField: string, sortType: string) => {
        const r = Object.assign(new BookingsRequest(new FilterValuesWrapper(filter)), {
            sortField,
            sortType,
        })
        setRequest(r)
    }
    const onFilterValueChange = (fieldName: string, filterValue: FilterValueType) => {
        filter.set(fieldName, filterValue)
        const newFilter = new Map<string, FilterValueType>(filter)
        setFilter(newFilter)
        setRequest(new BookingsRequest(new FilterValuesWrapper(filter)))
    }
    const dataTableModel = React.useMemo(() => {
        const res = initDataTable(offers, offersHash, hotels, hotelsHash)
        res.setFilter(filter)
        return res
    }, [offers, hotels, filter])
    return (
        <DataTable
            isFullScreen
            sortField={request.sortField}
            sortType={request.sortType}
            data={bookings?.entities ?? []}
            pagingInfo={bookings?.pagingInfo ?? new PaginationModel()}
            dataTable={dataTableModel}
            onSortChanged={onSortChanged}
            onFilterValueChange={onFilterValueChange}
            onClearFilter={onClearFilter}
            onRefreshData={onRefresh}
            onPageChange={onPageChanged}
        />
    )
}
export default Bookings
