import { SelectOptionModel } from 'traveldesk-ui'
import CountriesApiService from './../services/CountriesApiService'
import { AppThunkAction } from '.'
import { Reducer, Action } from 'redux'

export interface State {
    countriesOptions: SelectOptionModel[]
}

const COUNTRIES_OPTIONS_RESPONSE = 'A_COUNTRIES_OPTIONS_RESPONSE'
interface CountriesOptionsResponse {
    type: 'A_COUNTRIES_OPTIONS_RESPONSE'
    payload: SelectOptionModel[]
}

type KnownAction = CountriesOptionsResponse

export const actionCreators = {
    requestCountriesOptions: (): AppThunkAction<KnownAction> => async (dispatch) => {
        let fetchTask = await CountriesApiService.getOptions(dispatch)
        if (fetchTask.IsOk && fetchTask.Content) {
            dispatch({
                type: COUNTRIES_OPTIONS_RESPONSE,
                payload: fetchTask.Content,
            })
        }
    },
}

const unloadedState: State = {
    countriesOptions: [] as SelectOptionModel[],
}

export const reducer: Reducer<State> = (state: State = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction
    switch (action.type) {
        case COUNTRIES_OPTIONS_RESPONSE:
            return { ...state, countriesOptions: action.payload }
    }
    return state || unloadedState
}
