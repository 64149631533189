import TimeSlotNewBookingModel from './TimeSlotNewBookingModel'
export default class OfferNewBookingModel {
    public static Create(obj: OfferNewBookingModel) {
        return Object.assign(new OfferNewBookingModel(), obj, {
            timeSlots: obj.timeSlots.map((ts) => TimeSlotNewBookingModel.Create(ts)),
        })
    }
    id: number = 0
    nameInternal: string = ''
    nameDisplay: string = ''
    numberOfHours: number = 0
    citiesIds: number[] = []
    daysOffered: number = 0
    isOnDay = (weekDay: number) => {
        const w = 1 << (weekDay - 1)
        const isAvailable = this.daysOffered == 0 ? true : (w & this.daysOffered) > 0
        // console.log(
        //     'If offer on ' +
        //         weekDay +
        //         ' ' +
        //         this.daysOffered +
        //         ' ' +
        //         w +
        //         ' ' +
        //         (w & this.daysOffered) +
        //         ' ' +
        //         isAvailable,
        // )
        return isAvailable
    }
    childAge: number = 12
    childrenAllowed: boolean = true
    infantAge: number = 3
    infantsAllowed: boolean = true
    maxNumOfPeople: number = 0
    minNumOfPeople: number = 0
    timeSlots: TimeSlotNewBookingModel[] = []
}
