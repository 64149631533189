import * as React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
// import { actionCreators as languagesActions } from '../store/Languages'
import { ApplicationState } from '../store'
import { Container, AuthStore } from 'traveldesk-ui'
const LoginPage = () => {
    const { register, handleSubmit, formState:{errors}, control, getValues, setValue, reset } = useForm()
    // const languages = useSelector((state: ApplicationState) => state.languages.languages)
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const auth = useSelector((state: ApplicationState) => state.auth)
    const dispatch = useDispatch()
    const onSubmit = (data: any) => {
        dispatch(AuthStore.actionCreators.signIn(data))
    }
    return auth.isAuthorized ? (
        <Redirect to={'/'} />
    ) : (
        <Container>
            <h2 className="checkout-title">{Localization.getString('Sign In')}</h2>
            <div className="checkout-box-wrap checkout-box-details">
                <form onSubmit={handleSubmit(onSubmit)} className="checkout-details-form">
                    {errors.email && <i title={errors.email.message} className="form-error-i" />}
                    {auth.signInError && <i title={auth.signInError} className="form-error-i" />}
                    <label>{Localization.getString('Email address')}</label>
                    <input
                        type="email"
                        className={errors.email ? 'error' : ''}
                        autoComplete="off"
                        {...register("email",{required: Localization.getString('Email address is required')})}                        
                    />
                    {errors.password && <i title={errors.password.message} className="form-error-i" />}
                    <label>{Localization.getString('Password')}</label>
                    <input
                        type="password"
                        className={errors.password ? 'error' : ''}                        
                        {...register("password",{ required: Localization.getString('Password is required') })}
                    />
                    <button type="submit" className="button button-100 float-right">
                        {Localization.getString('Sign In')}
                    </button>
                </form>
                <Link to="/account/resetpassword">{Localization.getString('Reset password')}</Link>
            </div>
        </Container>
    )
}
export default LoginPage
