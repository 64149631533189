import { apiCallWrapper, SelectOptionModel } from 'traveldesk-ui'
import { TourManageTableModel, TourManageModel } from '../models/TourManageModel'

export default class ToursApiService {
    static save = async (dispatch: any, model: TourManageModel) => {
        return await apiCallWrapper<TourManageModel>('/api/tours', model.id == 0 ? 'POST' : 'PUT', dispatch, model)
    }
    static getToursManageTable = async (dispatch: any) => {
        return await apiCallWrapper<TourManageTableModel[]>('/api/tours/manageList', 'GET', dispatch)
    }
    static getTourForEdit = async (dispatch: any, id: number) => {
        return await apiCallWrapper<TourManageModel>(`/api/tours/manage/${id}`, 'GET', dispatch)
    }
    static getCategoriesOptions = async (dispatch: any) => {
        return await apiCallWrapper<SelectOptionModel[]>('/api/toursCategories/options', 'GET', dispatch)
    }
    static getRecommendedForOptions = async (dispatch: any) => {
        return {
            IsOk: true,
            Code: 200,
            Content: [
                { value: 0, label: 'Everybody' },
                { value: 1, label: 'Families With Children' },
                { value: 2, label: 'Families With Infants' },
                { value: 3, label: 'Young Adults' },
                { value: 4, label: 'Elderly' },
            ],
        }
    }
}
