import * as React from 'react'
import { generateArrayOfN } from 'traveldesk-ui'
import { generateRandomInteger, generateRandomString } from '../utils/randomizer'
import moment from 'moment'
import { FilterType, DataTableColumnModel, DataTableModelCustomAction, DataTable, DataTableModel } from 'traveldesk-ui'
const n = generateArrayOfN(20)
const multiselected = generateArrayOfN(10)
const multiselectTest = multiselected.reduce((res: any[], next: number) => {
    res.push({ id: next, value: 'Value ' + (next + 1) })
    return res
}, [] as any[])
const data = [] as any
for (let i = 0; i < 6; i++) {
    data.push(
        n.reduce((res, next) => {
            switch (next) {
                case 0:
                    return Object.assign(res, { ['date']: moment().add(generateRandomInteger(-10, 10), 'day') })
                case 1:
                    return Object.assign(res, { ['dateRange']: moment().add(generateRandomInteger(-10, 10), 'day') })
                case 2:
                    return Object.assign(res, { ['multi']: multiselectTest[generateRandomInteger(0, 3)].id })
                case 3:
                    return Object.assign(res, { ['select']: multiselectTest[generateRandomInteger(0, 3)].id })
                case 4:
                    return Object.assign(res, { ['sum']: generateRandomInteger(10, 500) })
                default:
                    return Object.assign(res, { ['column' + next]: generateRandomString(20) })
            }
        }, {}),
    )
}

const TestTableFullScreenPage = () => {
    const [numColumns, setNumColumns] = React.useState(4)
    const initDataTable = () => {
        const columns = generateArrayOfN(numColumns).reduce((res, next) => {
            switch (next) {
                case 0:
                    res.push(
                        new DataTableColumnModel({
                            displayName: 'Date',
                            fieldName: 'date',
                            width: 120,
                            filterType: FilterType.DATEPICKER,
                        }),
                    )
                    break
                case 1:
                    res.push(
                        new DataTableColumnModel({
                            displayName: 'Date Range',
                            fieldName: 'dateRange',
                            width: 120,
                            sortable: true,
                            filterType: FilterType.DATERANGE,
                        }),
                    )
                    break
                case 2:
                    res.push(
                        new DataTableColumnModel({
                            displayName: 'Multi Select',
                            sortable: true,
                            fieldName: 'multi',
                            filterType: FilterType.MULTISELECT,
                            customRenderer: (obj: any) => multiselectTest[obj.multi].value,
                            filterOptions: multiselectTest.map((x) => ({ label: x.value, value: x.id })),
                        }),
                    )
                    break
                case 3:
                    res.push(
                        new DataTableColumnModel({
                            displayName: 'Select',
                            sortable: true,
                            fieldName: 'select',
                            filterType: FilterType.SELECT,
                            customRenderer: (obj: any) => multiselectTest[obj.select].value,
                            filterOptions: multiselectTest.map((x) => ({ label: x.value, value: x.id })),
                        }),
                    )
                    break
                case 4:
                    res.push(
                        new DataTableColumnModel({
                            displayName: 'Number with Sum',
                            sortable: true,
                            fieldName: 'sum',
                            showSum: true,
                            filterType: FilterType.TEXTNUMBER,
                        }),
                    )
                    break
                default:
                    res.push(
                        new DataTableColumnModel({
                            displayName: 'Column ' + (next + 1),
                            fieldName: 'column' + next,
                            sortable: true,
                            filterType: FilterType.TEXT,
                        }),
                    )
            }
            return res
        }, [] as DataTableColumnModel[])
        return new DataTableModel({
            columns,
            name: 'Test Table',
            fileName: 'test.xlsx',
            showSum: true,
            customActions: [
                new DataTableModelCustomAction(
                    () => {},
                    () => (
                        <span onClick={() => setNumColumns(4)} className={numColumns == 4 ? 'active' : ''}>
                            4
                        </span>
                    ),
                ),
                new DataTableModelCustomAction(
                    () => {},
                    () => (
                        <span onClick={() => setNumColumns(8)} className={numColumns == 8 ? 'active' : ''}>
                            8
                        </span>
                    ),
                ),
                new DataTableModelCustomAction(
                    () => {},
                    () => (
                        <span onClick={() => setNumColumns(12)} className={numColumns == 12 ? 'active' : ''}>
                            12
                        </span>
                    ),
                ),
                new DataTableModelCustomAction(
                    () => {},
                    () => (
                        <span onClick={() => setNumColumns(16)} className={numColumns == 16 ? 'active' : ''}>
                            16
                        </span>
                    ),
                ),
            ],
        })
    }
    const [isFullScreen, setIsFullScreen] = React.useState(false)
    return <DataTable isFullScreenable={true} isFullScreen={isFullScreen} dataTable={initDataTable()} data={data} />
}
export default TestTableFullScreenPage
