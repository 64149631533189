import * as React from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from '../store'
import { actionCreators as citiesActions } from '../store/Cities'
import { actionCreators as globalActions } from '../store/Global'
import { LanguagesStore, Roles } from 'traveldesk-ui'
import { Localization as LocalizationStore } from 'traveldesk-ui'

import { AuthStore } from 'traveldesk-ui'
import { HeaderMenuItemWithChildren, Select, SelectOptionModel, useOnCloseElement } from 'traveldesk-ui'
import styled from 'styled-components'
const Version = styled.span`
    position: absolute;
    top: 22px;
    left: 27px;
    font-size: 8px;
    color: #aaa;
`
export interface MenuItem {
    title: string
    url: string
}
const Header = () => {
    const [isOpened, setIsOpened] = React.useState(false)
    const [showMobileMenu, setShowMobileMenu] = React.useState(false)
    const auth = useSelector((state: ApplicationState) => state.auth)
    const path = useSelector((state: ApplicationState) => state.global.path)
    const languages = useSelector((state: ApplicationState) => state.languages)
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const dispatch = useDispatch()
    const close = () => {
        if (isOpened) {
            toggle()
        }
    }
    const ref = React.useRef()
    useOnCloseElement(ref, close)
    React.useEffect(() => {
        dispatch(citiesActions.requestCitiesOptions())
        dispatch(LanguagesStore.actionCreators.requestLanguages())
    }, [])
    React.useEffect(() => {
        close()
    }, [path])
    const toggle = () => setIsOpened(!isOpened)
    const toggleMobileMenu = () => setShowMobileMenu(!showMobileMenu)
    const onCityChanged = (option: SelectOptionModel) => {
        dispatch(globalActions.setCity(option.value as number))
    }
    const onLanguageChanged = (option: SelectOptionModel) => {
        const lang = languages.languagesHash.get(option.value as number)
        if (lang) dispatch(LocalizationStore.actionCreators.setLanguage(lang.code))
    }
    const signOut = () => {
        dispatch(AuthStore.actionCreators.signOut())
    }
    return (
        <header className={`traveldesk-main-header${showMobileMenu ? ' show-mobile-menu' : ''}`}>
            <div className="header-inner container">
                <div className="header-inner--left">
                    <div className="header-main-logo">
                        <Version>1.2.12</Version>
                        <Link to="/">
                            <img src="img/logo-travel.png" alt="Main Logo" width="216" height="36" title="Traveldesk" />
                        </Link>
                    </div>
                    <nav ref={ref as any} className="main-navigation">
                        <a onClick={toggle} className="mobile-navigation-toggler">
                            {Localization.getString('Menu')}
                        </a>
                        <form method="post" className="header-search-form">
                            <input type="search" placeholder="Search and hit enter" />
                        </form>
                        {auth.isAuthorized && (
                            <ul className={`main-menu${isOpened ? ' mobile-opened' : ''}`}>
                                {auth.authData?.isInRole(Roles.ArrivalsManager) && (
                                    <li>
                                        <Link to="/arrivals/">{Localization.getString('Arrivals')}</Link>
                                    </li>
                                )}
                                {auth.authData?.isInRole(Roles.Agent) && !auth.authData?.isInRole(Roles.Admin) ? (
                                    <>
                                        <li>
                                            <Link to="/agent/bookings">{Localization.getString('Bookings')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/agent/commissions">{Localization.getString('Commissions')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/agent/bookings/add">
                                                {Localization.getString('Add New Booking')}
                                            </Link>
                                        </li>
                                    </>
                                ) : (
                                    <HeaderMenuItemWithChildren title={Localization.getString('Agent')}>
                                        <li>
                                            <Link to="/agent/bookings">{Localization.getString('Bookings')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/agent/commissions">{Localization.getString('Commissions')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/agent/bookings/add">
                                                {Localization.getString('Add New Booking')}
                                            </Link>
                                        </li>
                                    </HeaderMenuItemWithChildren>
                                )}
                                {(auth.authData?.isInRole(Roles.Accounts) ||
                                    auth.authData?.isInRole(Roles.Admin) ||
                                    auth.authData?.isInRole(Roles.Operations)) && (
                                        <li className="is-active">
                                            <Link to="/bookings">{Localization.getString('Bookings')}</Link>
                                        </li>
                                    )}
                                {(auth.authData?.isInRole(Roles.Admin) || auth.authData?.isInRole(Roles.Editor)) && (
                                    <HeaderMenuItemWithChildren title={Localization.getString('Manage')}>
                                        <li>
                                            <Link to="/tours">{Localization.getString('Tours')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/timeslots">{Localization.getString('Time Slots')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/pickuptimes">{Localization.getString('Pick Up Times')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/availability">
                                                {Localization.getString('Availability Chart')}
                                            </Link>
                                        </li>
                                    </HeaderMenuItemWithChildren>
                                )}
                                {auth.authData?.isInRole('admin') && (
                                    <HeaderMenuItemWithChildren title={Localization.getString('Admin')}>
                                        <li>
                                            <Link to="/admin/core">{Localization.getString('Core Info')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/admin/contacts">{Localization.getString('Contacts')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/admin/contracting">{Localization.getString('Contracting')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/admin/staff">{Localization.getString('Staff')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/admin/defaultsettings">
                                                {Localization.getString('Default Settings')}
                                            </Link>
                                        </li>
                                    </HeaderMenuItemWithChildren>
                                )}
                                {/* <li>
                                <a href="#">About Us</a>
                            </li>
                            <li>
                                <a href="#">Contacts</a>
                            </li> */}
                            </ul>
                        )}
                    </nav>
                    <a onClick={toggleMobileMenu} className="header-mobile-toggler">
                        <span></span>
                        <span></span>
                        <span></span>
                    </a>
                </div>
                <div className="header-inner--right">
                    {/* <div className="header-select-lang-wrap">
                        <Select
                            wrapperClassName={'header-select-lang list-active'}
                            isNotSearchable
                            options={languages.languagesOptions}
                            onChange={onLanguageChanged}
                            value={language}
                        />
                    </div> */}
                    {/* <div className="header-select-currency-wrap">
                        <div className="header-select-currency">
                            <span className="header-currency-label">USD</span>
                            <input type="hidden" name="header-currency-value" />
                            <ul className="header-currency-list">
                                <li data-value="usd">USD</li>
                                <li data-value="gbp">GBP</li>
                                <li data-value="rub">RUB</li>
                            </ul>
                        </div>
                    </div> */}

                    {auth.isAuthorized && (
                        <div className="header-account-wrap">
                            <a href="#" className="header--account-link">
                                <i></i>
                                {Localization.getString('My Account')}
                            </a>
                            <div className="header-account-menu-wrap">
                                <ul className="header-account-menu">
                                    {auth.authData && <li>{auth.authData?.email}</li>}
                                    <li>
                                        <Link to="/account">
                                            <i className="header-account-item--profile"></i>
                                            {Localization.getString('My Profile')}
                                        </Link>
                                    </li>
                                    {/* <li>
                                        <a>
                                            <i className="header-account-item--bookings"></i>
                                            {Localization.getString('My Bookings')}
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <i className="header-account-item--help"></i>
                                            {Localization.getString('Help')}
                                        </a>
                                    </li> */}
                                    <li className="clickable" onClick={signOut}>
                                        <i className="header-account-item--help" />
                                        {Localization.getString('Sign Out')}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </header>
    )
}
export default Header
