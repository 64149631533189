import * as React from 'react'
import TourFaqModel from '../../models/TourFaqModel'
import { TourManageModel } from '../../models/TourManageModel'
import { Buttons, FormFieldLabel, FormFieldWrapper, TextAreaField, TextFormField, Tooltip, indexUp, indexDown } from 'traveldesk-ui'
import { ApplicationState } from '../../store'
import { useSelector } from 'react-redux'
interface Props {
    tour?: TourManageModel
    faq: TourFaqModel[]
    onChange: (faq: TourFaqModel[]) => void
}
const TourFormFaq = (props: Props) => {
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const onAddFaq = () => {
        props.onChange(props.faq.concat(Object.assign(new TourFaqModel(), { tourId: props.tour?.id, index: props.faq.length + 1 })))
    }
    const onDeleteFaq = (index: number) => {
        props.onChange(props.faq
            .filter(x => x.index != index)
            .map(x => x.index > index ? Object.assign(new TourFaqModel(), x, { index: x.index - 1 }) : x)
        )
    }
    const onFaqUp = (index: number) => {
        const newFaq = indexUp(props.faq, index)
        // if (index <= 1) return
        // let newFaq = props.faq.map(x => x.index == index
        //     ? Object.assign(new TourFaqModel(), x, { index: x.index - 1 })
        //     : x.index == index - 1
        //         ? Object.assign(new TourFaqModel(), x, { index: x.index + 1 })
        //         : x
        // );
        // newFaq = newFaq.sort((a, b) => a.index > b.index ? 1 : -1)
        props.onChange(newFaq)
    }
    const onFaqDown = (index: number) => {
        const newFaq = indexDown(props.faq, index)
        // indexUp
        // if (index > props.faq.length) return
        // let newFaq = props.faq.map(x => x.index == index
        //     ? Object.assign(new TourFaqModel(), x, { index: x.index + 1 })
        //     : x.index == index + 1
        //         ? Object.assign(new TourFaqModel(), x, { index: x.index - 1 })
        //         : x
        // )
        // newFaq = newFaq.sort((a, b) => a.index > b.index ? 1 : -1)
        props.onChange(newFaq)
    }
    const onChange = (index: number, fieldName: string) => (e: any) => {
        props.onChange(props.faq.map(x => x.index == index
            ? Object.assign(new TourFaqModel(), x, { [fieldName]: e.target.value })
            : x))
    }
    return <FormFieldWrapper>
        {props.faq.map((f, i) => <FormFieldWrapper key={f.index + f.question}>
            {i > 0 && <hr />}
            <label>Question #{f.index}
                <i className='fa fa-trash float-right clickable' onClick={() => onDeleteFaq(f.index)} />
                {i > 0 && <i className='fa fa-arrow-up float-right clickable' onClick={() => onFaqUp(f.index)} />}
                {i < props.faq.length - 1 && <i className='fa fa-arrow-down float-right clickable' onClick={() => onFaqDown(f.index)} />}

            </label>
            <input type="text" value={f.question} onChange={onChange(f.index, "question")} />
            {/* <TextFormField
                value={f.question}
                onChange={}
            />
            <FormFieldLabel
                value={`Answer #${f.index}`}
            /> */}
            <label>Answer #{f.index}</label>
            <textarea
                value={f.answer}
                onChange={onChange(f.index, "answer")}
            />
            {/* <TextAreaField
                value={f.answer}
                onChange={onChange(f.index, "answer")}

            /> */}
        </FormFieldWrapper>)}
        <Tooltip text={Localization.getString('Add quiestion')}>
            <Buttons.ButtonSmallGreen onClick={onAddFaq}>
                <i className="fa fa-plus" />
            </Buttons.ButtonSmallGreen>
        </Tooltip>

    </FormFieldWrapper>
}
export default TourFormFaq