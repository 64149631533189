import { CallHistoryMethodAction, push } from 'connected-react-router'
import { EntitiesPaginatedModel, PaginationModel } from 'traveldesk-ui'
import OfferAgent from '../pages/Agent/Models/OfferAgent'
import Hotel from '../models/Hotel'
import BookingAgent from '../pages/Agent/Models/BookingAgent'
import { LoadingStore } from 'traveldesk-ui'
import { AppThunkAction } from '.'
import AgencyAreaApiService from '../services/AgencyAreaApiService'
import BookingsRequest from '../pages/Agent/Models/BookingsRequest'
import { Reducer, Action } from 'redux'
// import TourNewBookingModel from '../pages/Agent/Models/TourNewBookingModel'
import AgentOfferPrice from '../pages/Agent/Models/AgentOfferPrice'
import NewBookingAgent from '../pages/Agent/Models/NewBookingAgent'
import NewBookingArrival from '../pages/Agent/Models/NewBookingArrival'
import { async } from 'q'
import TourNewBookingModel from '../pages/Agent/Models/TourNewBookingModel'
import IdNameModel from '../models/IdNameModel'
export interface State {
    data?: EntitiesPaginatedModel<BookingAgent>
    offers: OfferAgent[]
    offersHash: Map<number, OfferAgent>
    hotels: Hotel[]
    hotelsNewBooking: Hotel[]
    toursNewBooking: TourNewBookingModel[]
    newBookingOfferPrices?: AgentOfferPrice[]
    hotelsHash: Map<number, Hotel>
    newBookingArrivals: NewBookingArrival[]
    bookingDetails?: BookingAgent
    agents: IdNameModel[]
    agentsHash: Map<number, IdNameModel>
}

const BOOKING_DETAILS_CLEAR = 'A_BOOKING_DETAILS_CLEAR'
interface BookingDetailsClear {
    type: 'A_BOOKING_DETAILS_CLEAR'
}
const BOOKING_DETAILS_SET = 'A_BOOKING_DETAILS_SET'
interface BookingDetailsSet {
    type: 'A_BOOKING_DETAILS_SET'
    payload: BookingAgent
}
const RECEIVE_AGENT_BOOKINGS = 'A_RECEIVE_AGENT_BOOKINGS'
interface ReceiveAgentBookings {
    type: 'A_RECEIVE_AGENT_BOOKINGS'
    payload: EntitiesPaginatedModel<BookingAgent>
}
const RECEIVE_AGENCY_BOOKINGS_AGENTS = 'A_RECEIVE_AGENCY_BOOKINGS_AGENTS'
interface ReceiveAgencyBookingsAgents {
    type: 'A_RECEIVE_AGENCY_BOOKINGS_AGENTS'
    payload: IdNameModel[]
}
const RECEIVE_AGENCY_BOOKINGS_OFFERS = 'A_RECEIVE_AGENCY_BOOKINGS_OFFERS'
interface ReceiveAgencyBookingsOffers {
    type: 'A_RECEIVE_AGENCY_BOOKINGS_OFFERS'
    payload: OfferAgent[]
}
const RECEIVE_AGENCY_BOOKINGS_HOTELS = 'RECEIVE_AGENCY_BOOKINGS_HOTELS'
interface ReceiveAgencyBookingsHotels {
    type: 'RECEIVE_AGENCY_BOOKINGS_HOTELS'
    payload: Hotel[]
}
const RECEIVE_AGENT_NEWBOOKING_HOTELS = 'A_RECEIVE_AGENT_NEWBOOKING_HOTELS'
interface ReceiveAgentNewBookingHotels {
    type: 'A_RECEIVE_AGENT_NEWBOOKING_HOTELS'
    payload: Hotel[]
}
const RECEIVE_AGENT_NEWBOOKING_OFFER_PRICE = 'A_RECEIVE_AGENT_NEWBOOKING_OFFER_PRICE'
interface ReceiveAgentNewBookingOfferPrice {
    type: 'A_RECEIVE_AGENT_NEWBOOKING_OFFER_PRICE'
    payload?: AgentOfferPrice[]
}
const RECEIVE_AGENT_NEWBOOKING_TOURS = 'A_RECEIVE_AGENT_NEWBOOKING_TOURS'
interface ReceiveAgentNewBookingTours {
    type: 'A_RECEIVE_AGENT_NEWBOOKING_TOURS'
    payload: TourNewBookingModel[]
}
const RECEIVE_AGENT_NEWBOOKING_ARRIVALS = 'A_RECEIVE_AGENT_NEWBOOKING_ARRIVALS'
interface ReceiveAgentNewBookingArrivals {
    type: 'A_RECEIVE_AGENT_NEWBOOKING_ARRIVALS'
    payload: NewBookingArrival[]
}
type KnownAction =
    | ReceiveAgentBookings
    | ReceiveAgentNewBookingOfferPrice
    | ReceiveAgencyBookingsOffers
    | ReceiveAgencyBookingsHotels
    | ReceiveAgentNewBookingHotels
    | ReceiveAgentNewBookingTours
    | ReceiveAgentNewBookingArrivals
    | ReceiveAgencyBookingsAgents
    | LoadingStore.KnownAction
    | CallHistoryMethodAction
    | BookingDetailsClear
    | BookingDetailsSet

export const actionCreators = {
    addBooking:
        (model: NewBookingAgent): AppThunkAction<KnownAction | LoadingStore.KnownAction> =>
        async (dispatch) => {
            dispatch(LoadingStore.actionCreators.incrementLoading())
            const fetchTask = await AgencyAreaApiService.addBooking(model, dispatch)
            if (fetchTask.IsOk) {
                dispatch(push(`/agent/bookings/details/${fetchTask.Content?.id}`))
            }
            dispatch(LoadingStore.actionCreators.decrementLoading())
        },
    clearBookingDetails: () => {
        type: BOOKING_DETAILS_CLEAR
    },
    requestAgencyBookingsAgents: (): AppThunkAction<KnownAction | LoadingStore.KnownAction> => async (dispatch) => {
        dispatch(LoadingStore.actionCreators.incrementLoading())
        var fetchTask = await AgencyAreaApiService.getBookingsAgents(dispatch)
        if (fetchTask.IsOk && fetchTask.Content) {
            dispatch({
                type: RECEIVE_AGENCY_BOOKINGS_AGENTS,
                payload: fetchTask.Content.sort((a, b) => (a.name > b.name ? 1 : -1)),
            })
        }
        dispatch(LoadingStore.actionCreators.decrementLoading())
    },
    requestBookingDetails:
        (id: number): AppThunkAction<KnownAction | LoadingStore.KnownAction> =>
        async (dispatch) => {
            var fetchTask = await AgencyAreaApiService.getBookingDetails(id, dispatch)
            if (fetchTask.IsOk && fetchTask.Content) {
                dispatch({
                    type: BOOKING_DETAILS_SET,
                    payload: BookingAgent.Create(fetchTask.Content),
                })
            }
        },
    requestNewBookingTours: (): AppThunkAction<KnownAction | LoadingStore.KnownAction> => async (dispatch) => {
        dispatch(LoadingStore.actionCreators.incrementLoading())
        var fetchTask = await AgencyAreaApiService.getNewBookingTours(dispatch)
        if (fetchTask.IsOk && fetchTask.Content) {
            dispatch({
                type: RECEIVE_AGENT_NEWBOOKING_TOURS,
                payload: fetchTask.Content.sort((a, b) => (a.nameInternal > b.nameInternal ? 1 : -1)).map((x) =>
                    TourNewBookingModel.Create(x),
                ),
            })
        }
        dispatch(LoadingStore.actionCreators.decrementLoading())
    },
    requestNewBookingArrivals:
        (hotelId: number, date: moment.Moment): AppThunkAction<KnownAction> =>
        async (dispatch) => {
            if (hotelId && date) {
                dispatch(LoadingStore.actionCreators.incrementLoading())
                var fetchTask = await AgencyAreaApiService.getNewBookingArrivals(hotelId, date, dispatch)
                if (fetchTask.IsOk && fetchTask.Content) {
                    dispatch({
                        type: RECEIVE_AGENT_NEWBOOKING_ARRIVALS,
                        payload: fetchTask.Content.map((x) => NewBookingArrival.Create(x)),
                    })
                }
                dispatch(LoadingStore.actionCreators.decrementLoading())
            } else {
                dispatch({ type: RECEIVE_AGENT_NEWBOOKING_ARRIVALS, payload: [] })
            }
        },
    requestNewBookingOfferPrices:
        (tourId: number, date: moment.Moment): AppThunkAction<KnownAction> =>
        async (dispatch) => {
            if (tourId && date) {
                dispatch(LoadingStore.actionCreators.incrementLoading())
                var fetchTask = await AgencyAreaApiService.getNewBookingOfferPrice(tourId, date, dispatch)
                if (fetchTask.IsOk && fetchTask.Content) {
                    dispatch({
                        type: RECEIVE_AGENT_NEWBOOKING_OFFER_PRICE,
                        payload: fetchTask.Content.map((x) => AgentOfferPrice.Create(x)),
                    })
                }
                dispatch(LoadingStore.actionCreators.decrementLoading())
            } else {
                dispatch({ type: RECEIVE_AGENT_NEWBOOKING_OFFER_PRICE, payload: undefined })
            }
        },
    requestNewBookingHotels: (): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch(LoadingStore.actionCreators.incrementLoading())
        var fetchTask = await AgencyAreaApiService.getNewBookingHotels(dispatch)
        if (fetchTask.IsOk && fetchTask.Content) {
            dispatch({
                type: RECEIVE_AGENT_NEWBOOKING_HOTELS,
                payload: fetchTask.Content.sort((a, b) => (a.name > b.name ? 1 : -1)).map((x) => Hotel.Create(x)),
            })
        }
        dispatch(LoadingStore.actionCreators.decrementLoading())
    },
    requestAgencyBookingsHotels: (): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch(LoadingStore.actionCreators.incrementLoading())
        var fetchTask = await AgencyAreaApiService.getBookingsHotels(dispatch)
        if (fetchTask.IsOk && fetchTask.Content) {
            dispatch({
                type: RECEIVE_AGENCY_BOOKINGS_HOTELS,
                payload: fetchTask.Content.sort((a, b) => (a.name > b.name ? 1 : -1)).map((x) => Hotel.Create(x)),
            })
        }
        dispatch(LoadingStore.actionCreators.decrementLoading())
    },
    requestAgentCommissionHotels: (): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch(LoadingStore.actionCreators.incrementLoading())
        var fetchTask = await AgencyAreaApiService.getCommissionHotels(dispatch)
        if (fetchTask.IsOk && fetchTask.Content) {
            dispatch({
                type: RECEIVE_AGENCY_BOOKINGS_HOTELS,
                payload: fetchTask.Content.sort((a, b) => (a.name > b.name ? 1 : -1)).map((x) => Hotel.Create(x)),
            })
        }
        dispatch(LoadingStore.actionCreators.decrementLoading())
    },
    requestAgencyBookingsOffers: (): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch(LoadingStore.actionCreators.incrementLoading())
        var fetchTask = await AgencyAreaApiService.getBookingsOffers(dispatch)
        if (fetchTask.IsOk && fetchTask.Content) {
            dispatch({
                type: RECEIVE_AGENCY_BOOKINGS_OFFERS,
                payload: fetchTask.Content.sort((a, b) => (a.name > b.name ? 1 : -1)).map((x) => OfferAgent.Create(x)),
            })
        }
        dispatch(LoadingStore.actionCreators.decrementLoading())
    },
    requestAgentCommissionOffers: (): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch(LoadingStore.actionCreators.incrementLoading())
        var fetchTask = await AgencyAreaApiService.getCommissionOffers(dispatch)
        if (fetchTask.IsOk && fetchTask.Content) {
            dispatch({
                type: RECEIVE_AGENCY_BOOKINGS_OFFERS,
                payload: fetchTask.Content.sort((a, b) => (a.name > b.name ? 1 : -1)).map((x) => OfferAgent.Create(x)),
            })
        }
        dispatch(LoadingStore.actionCreators.decrementLoading())
    },
    requestAgencyBookings:
        (request: BookingsRequest): AppThunkAction<KnownAction> =>
        async (dispatch) => {
            dispatch(LoadingStore.actionCreators.incrementLoading())
            var fetchTask = await AgencyAreaApiService.getBookings(request, dispatch)
            const data = fetchTask.Content
                ? Object.assign(new EntitiesPaginatedModel<BookingAgent>(), {
                      entities: fetchTask.Content.entities.map((x) => BookingAgent.Create(x)),
                      pagingInfo: Object.assign(new PaginationModel(), fetchTask.Content.pagingInfo),
                  })
                : new EntitiesPaginatedModel<BookingAgent>()
            if (fetchTask.IsOk && data) {
                dispatch({ type: RECEIVE_AGENT_BOOKINGS, payload: data })
            }
            dispatch(LoadingStore.actionCreators.decrementLoading())
        },
}
const unloadedState: State = {
    data: undefined,
    agents: [],
    agentsHash: new Map<number, IdNameModel>(),
    offers: [],
    offersHash: new Map<number, OfferAgent>(),
    hotels: [],
    hotelsHash: new Map<number, Hotel>(),
    hotelsNewBooking: [],
    newBookingArrivals: [],
    toursNewBooking: [] as TourNewBookingModel[],
}

export const reducer: Reducer<State> = (state: State | undefined, incomingAction: Action): State => {
    if (state === undefined) {
        return unloadedState
    }

    const action = incomingAction as KnownAction
    switch (action.type) {
        case RECEIVE_AGENT_BOOKINGS:
            return {
                ...state,
                data: action.payload,
            }
        case RECEIVE_AGENT_NEWBOOKING_TOURS:
            return {
                ...state,
                toursNewBooking: action.payload,
            }
        case RECEIVE_AGENCY_BOOKINGS_HOTELS:
            return {
                ...state,
                hotels: action.payload,
                hotelsHash: action.payload.reduce((res, next) => {
                    res.set(next.id, next)
                    return res
                }, new Map<number, Hotel>()),
            }
        case RECEIVE_AGENCY_BOOKINGS_OFFERS:
            return {
                ...state,
                offers: action.payload,
                offersHash: action.payload.reduce((res, next) => {
                    res.set(next.id, next)
                    return res
                }, new Map<number, OfferAgent>()),
            }
        case RECEIVE_AGENT_NEWBOOKING_HOTELS:
            return {
                ...state,
                hotelsNewBooking: action.payload,
            }
        case RECEIVE_AGENT_NEWBOOKING_OFFER_PRICE:
            return {
                ...state,
                newBookingOfferPrices: action.payload,
            }
        case RECEIVE_AGENCY_BOOKINGS_AGENTS:
            const agentsHash = action.payload.reduce((res, next) => {
                res.set(next.id as number, next)
                return res
            }, new Map<number, IdNameModel>())
            return {
                ...state,
                agents: action.payload,
                agentsHash,
            }
        case RECEIVE_AGENT_NEWBOOKING_ARRIVALS:
            return {
                ...state,
                newBookingArrivals: action.payload,
            }
        case BOOKING_DETAILS_SET:
            return {
                ...state,
                bookingDetails: action.payload,
            }
        case BOOKING_DETAILS_CLEAR:
            return {
                ...state,
                bookingDetails: undefined,
            }
        default:
            return state
    }
}
