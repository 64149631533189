import { Photo } from 'traveldesk-ui'
import OfferValidPeriod from './OfferValidPeriod'
import OfferTicketType, { OffersTicketTypesType } from './OfferTicketType'
import { Localization } from 'traveldesk-ui'
import { AccountSettingsModel } from './AccountSettingsModel'
import { BookingType } from './OfferBookingType'

export const offerBookingTypeOptions = (lang: Localization.Localization) => [
    { value: 0, label: lang.getString('On request') },
    { value: 1, label: lang.getString('Live book') },
    { value: 2, label: lang.getString('Ticket only') },

]
export default class Offer {

    constructor(tourId: number) {
        this.tourId = tourId
    }
    public static Create(obj: Offer, tourId: number, accountSettings: AccountSettingsModel) {
        const as = accountSettings
        return Object.assign(new Offer(tourId), obj, {
            tourId,
            validPeriods: obj.validPeriods?.map((vp) => OfferValidPeriod.Create(
                vp,
                as.totalTaxAmount, as.totalTaxK,
                as.currencyConversionSettings.convRate, as.currencyConversionSettings.convType,
                as.defaultCommission)) ?? [],
        })
    }
    id: number = 0
    tourId: number = 0
    nameInternal: string = ''
    nameDisplay: string = ''
    minNumOfPeople: number = 1
    maxNumOfPeople?: number
    numberOfHours: number = 0
    isTicketsRequired: boolean = false
    minimumAdvancedPayment?: number
    origin: number[] = []
    validPeriods: OfferValidPeriod[] = []
    description: string = ''
    heroImageId?: number = undefined
    photos: Photo[] = []
    childrenAllowed: boolean = false
    childAge: number = 12
    infantsAllowed: boolean = false
    infantAge: number = 3
    isDirectReporting: boolean = false
    allowDirectCollection: boolean = false
    isAppendTransactionFeeB2b: boolean = false
    get ticketTypeAdult(): number | undefined {
        return this.ticketsTypes.find((x) => x.type == OffersTicketTypesType.Adult)?.ticketTypeId
    }
    set ticketTypeAdult(value: number | undefined) {
        this.ticketsTypes = this.ticketsTypes.filter((x) => x.type != OffersTicketTypesType.Adult)
        if (value) {
            this.ticketsTypes = this.ticketsTypes.concat(new OfferTicketType(value, OffersTicketTypesType.Adult))
        }
    }
    get ticketTypeChild(): number | undefined {
        return this.ticketsTypes.find((x) => x.type == OffersTicketTypesType.Child)?.ticketTypeId
    }
    set ticketTypeChild(value: number | undefined) {
        this.ticketsTypes = this.ticketsTypes.filter((x) => x.type != OffersTicketTypesType.Child)
        if (value) {
            this.ticketsTypes = this.ticketsTypes.concat(new OfferTicketType(value, OffersTicketTypesType.Child))
        }
    }
    get ticketTypeInfant(): number | undefined {
        return this.ticketsTypes.find((x) => x.type == OffersTicketTypesType.Infant)?.ticketTypeId
    }
    set ticketTypeInfant(value: number | undefined) {
        this.ticketsTypes = this.ticketsTypes.filter((x) => x.type != OffersTicketTypesType.Infant)
        if (value) {
            this.ticketsTypes = this.ticketsTypes.concat(new OfferTicketType(value, OffersTicketTypesType.Infant))
        }
    }
    ticketsTypes: OfferTicketType[] = []
    isDeleted: boolean = false
    timeSlots: number[] = []
    bookingType: BookingType = BookingType.LiveBook
    notesToClientsPostBooking: string = ''
    // isDeleted: boolean = false
    // cancellations: CancellationPolicy[] = []
    // isAvailableForChildren: boolean = false
    // childAge: number = 0
    // isAvailableForInfants: boolean = false
    // infantAge: number = 0
    // minNumberOfPeople: number = 0
    // maxNumberOfPeople: number = 0
    // numberOfHours: number = 0
    // description: OfferDescription[] = []
    // availableDays: number[] = []
    // photos: Photo[] = []
    // heroImage?: Photo
}

class OfferDescription {
    title: string = ''
    description: string = ''
}

export class OfferTourManageTableModel {
    id: number = 0
    nameDisplay: string = ''
    nameInternal: string = ''
    isDeleted: boolean = false
}
export class OfferManageTourModel extends OfferTourManageTableModel {
    public static Create(obj: OfferManageTourModel, accountSettings: AccountSettingsModel) {
        const as = accountSettings
        return Object.assign(new OfferManageTourModel(), obj, {
            validPeriod: obj.validPeriod ? OfferValidPeriod.Create(obj.validPeriod, as.totalTaxAmount, as.totalTaxK, as.currencyConversionSettings.convRate, as.currencyConversionSettings.convType, as.defaultCommission) : undefined,
        })
    }
    validPeriod?: OfferValidPeriod
}
