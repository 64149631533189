import * as React from 'react'
import { Autocomplete, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { FieldFooter, ToggleFormField } from 'traveldesk-ui';

const containerStyle = {
    width: '100%',
    height: '400px'
};
interface Props {
    label: string
    placeIdLabel: string
    value?: string
    validationError?: string
    onChange: (placeId: string | undefined) => void
}
function getLocationIdFromUrl(url: string) {
    var regex = /d(\d+)-/;
    var match = url.match(regex);

    if (match && match.length > 1) {
        return match[1];
    } else {
        return undefined;
    }
}

const TripAdvisorLocationIdPicker = (props: Props) => {

    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [isTripAdvisorId, setIsTripAdvisorId] = React.useState<boolean>(props.value ? true : false)

    const onChange = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
        setIsLoading(true)
        const value = getLocationIdFromUrl(e?.target.value ?? "")
        props.onChange(value)
        setIsLoading(false)

    }
    const onDropLocation = () => {
        onChange(undefined)
    }


    const onCheckIsTripAdvisorId = () => {
        if (isTripAdvisorId) onChange(undefined)
        setIsTripAdvisorId(!isTripAdvisorId)
    }
    return <>
        <br />
        <ToggleFormField
            label={props.label}
            name="IsGooglePlaceId"
            value={isTripAdvisorId}
            onChange={onCheckIsTripAdvisorId}

        />

        {isTripAdvisorId ? <React.Fragment>

            <span>{props.placeIdLabel}</span>
            <FieldFooter error={props.validationError} />

            <br />
            <br />
            <input
                type="text"
                placeholder="Copy/paste trip advisor url here"
                value={props.value || ''}
                onChange={onChange}
            />
            <br />
        </React.Fragment>
            : null}
        {isTripAdvisorId && <label className="clickable" onClick={onDropLocation}>Clear place Id</label>}

    </>
}
export default React.memo(TripAdvisorLocationIdPicker)