import * as React from 'react'
import { Route, Switch } from 'react-router'
import Layout from './components/Layout'
import PrivateRoute from './components/AuthorizedLayout'
import Home from './components/Home' //React.lazy(() => import(/* webpackChunkName: "home" */ './components/Home'))
import ToursPage from './pages/Tours' // React.lazy(() => import(/* webpackChunkName: "tours" */ './pages/Tours'))
import TourForm from './pages/TourForm/TourForm' // React.lazy(() => import(/* webpackChunkName: "tourForm" */ './pages/TourForm/TourForm'))
import LogMeInPage from './pages/LogMeInPage' // React.lazy(() => import('./pages/LogMeInPage'))
import Bookings from './pages/Bookings' // React.lazy(() => import(/* webpackChunkName: "bookings" */ './pages/Bookings'))
import AgentBookings from './pages/Agent/Bookings' //React.lazy(() => import(/* webpackChunkName: "agentBookings" */ './pages/Agent/Bookings'))
import AgentCommissions from './pages/Agent/Commissions' //React.lazy(() => import(/* webpackChunkName: "agentCommissions" */ './pages/Agent/Commissions'))
import TestTableFullScreenPage from './pages/TestTableFullScreen'
import Controls from './pages/Controls' //React.lazy(() => import('./pages/Controls'))
import OfferForm from './pages/OfferForm/OfferForm' // React.lazy(() => import(/* webpackChunkName: "offerForm" */ './pages/OfferForm/OfferForm'))
import TimeSlots from './pages/TimeSlots/TimeSlots' //React.lazy(() => import(/* webpackChunkName: "timeSlots" */ './pages/TimeSlots/TimeSlots'))
import TimeSlotsForm from './pages/TimeSlots/TimeSlotForm' //React.lazy(() => import(/* webpackChunkName: "timeSlotForm" */ './pages/TimeSlots/TimeSlotForm'))
import PickUpTimes from './pages/PickUpTimes/PickUpTimes' //React.lazy(() => import(/* webpackChunkName: "pickUpTimes" */ './pages/PickUpTimes/PickUpTimes'))
import PickUpTimeForm from './pages/PickUpTimes/PickUpTimeForm' //React.lazy(() => import(/* webpackChunkName: "pickUpTimeForm" */ './pages/PickUpTimes/PickUpTimeForm'))
import AdminLayout from './pages/Admin/AdminLayout'
import { LoadingSpinner } from 'traveldesk-ui'
import ArrivalsListPage from './pages/Arrivals/ArrivalsList' //React.lazy(() => import(/* webpackChunkName: "arrivalsList" */ './pages/Arrivals/ArrivalsList'))
import LoginPage from './pages/Login' //React.lazy(() => import(/* webpackChunkName: "loginPage" */ './pages/Login'))
import LogoutPage from './pages/Logout' //React.lazy(() => import(/* webpackChunkName: "loginPage" */ './pages/Logout'))
import AgentBookingForm from './pages/Agent/BookingForm'
import AgentBookingConfirm from './pages/Agent/BookingConfirm' //React.lazy(() => import(/* webpackChunkName: "bookingForm" */ './pages/Agent/BookingForm'))
import Modulbank from './ModulBank'

export default () => {
    return (
        <Layout>
            <React.Suspense fallback={<LoadingSpinner />}>
                <Route exact path="/account/login" component={LoginPage} />
                <Route exact path="/account/logout" component={LogoutPage} />
                <Route exact path="/testtablefull" component={TestTableFullScreenPage} />
                <Route exact path="/controls" component={Controls} />
                <Route exact path="/verify-modulbank" component={Modulbank} />
                <PrivateRoute exact path="/" component={Home} />
                <PrivateRoute exact path="/tours" component={ToursPage} />
                <PrivateRoute exact path="/tours/:id" component={TourForm} />
                <PrivateRoute exact path="/tours/:tourId/:ticketId" component={OfferForm} />
                <PrivateRoute exact path="/bookings" component={Bookings} />
                <PrivateRoute exact path="/timeslots" component={TimeSlots} />
                <PrivateRoute exact path="/timeslots/:id" component={TimeSlotsForm} />
                <PrivateRoute exact path="/pickuptimes" component={PickUpTimes} />
                <PrivateRoute exact path="/pickuptimes/:id" component={PickUpTimeForm} />
                <PrivateRoute exact path="/agent/bookings/add" component={AgentBookingForm} />
                <PrivateRoute exact path="/agent/bookings/details/:id" component={AgentBookingConfirm} />
                <PrivateRoute exact path="/agent/bookings/g-:groupId" component={AgentBookings} />
                <PrivateRoute exact path={Routes.agentBookingsTemplate} component={AgentBookings} />
                <PrivateRoute exact path="/agent/commissions" component={AgentCommissions} />
                <PrivateRoute exact path="/arrivals" component={ArrivalsListPage} />
                <PrivateRoute roles={['admin']} path="/admin/:tab" component={AdminLayout} />
                <PrivateRoute roles={['admin']} path="/auth/logmein/:email/" component={LogMeInPage} />
            </React.Suspense>
        </Layout>
    )
}
export const Routes = {
    downloadInvoice(bookingId: number) {
        return `/bookings/download/invoice/${bookingId}`
    },
    downloadVoucher(bookingId: number, lang: string) {
        return `/bookings/download/voucher/${bookingId}?lang=${lang}`
    },
    agentBookings: '/agent/bookings',
    agentBookingsTemplate: '/agent/bookings',
}
