export default class TicketType {
    static Create(obj: TicketType) {
        return Object.assign(new TicketType(), obj)
    }
    id: number = 0
    name: string = ''
    shortName: string = ''
    reorderLevel: number = 0
    reservationTime: number = 0
    available: number = 0
    expired: number = 0
    freezed: number = 0
    expiresIn5Days: number = 0
    againstReorderLevel() {
        return this.available - this.reorderLevel
    }
}
