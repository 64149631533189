import { Localization } from 'traveldesk-ui'

export default class DaysOfferedService {
    public static daysNames = ['Everyday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    public static getDays(days: number) {
        let res = [] as number[]
        if (days == 0) {
            res.push(0)
        } else {
            for (let i = 1; i <= 7; i++) {
                const w = 1 << (i - 1)
                if ((w & days) > 0) {
                    res.push(i)
                }
            }
            if (res.length == 7) {
                res = [0]
            }
        }
        return res
    }
    public static encode(days: number[]) {
        let res = 0
        if (days && days.length > 0) {
            days.forEach((day) => {
                if (day > 0) {
                    res = res | (1 << (day - 1))
                }
            })
        }
        return res
    }
    public static getDaysNamesOptions(localization: Localization.Localization) {
        return this.daysNames.map((day, index) => ({ label: localization.getString(day), value: index }))
    }
    public static getDaysNames(days: number, localization: Localization.Localization) {
        return this.getDays(days).map((x) => localization.getString(this.daysNames[x]))
    }
}
