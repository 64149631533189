export const generateRandomInteger = (from: number, to: number) => {
    const range = to - from
    return Math.floor(Math.random() * range) + from
}
export const generateRandomString = (length: number) => {
    var result = ''
    var characters = ' ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}
