import * as React from 'react'
import ItineraryModel from '../../models/ItineraryModel'
import { Buttons, FormFieldWrapper, Popup, Tooltip, indexDown, indexUp } from 'traveldesk-ui'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import ItineraryDesigner from '../../components/ItineraryDesigner/ItineraryDesigner'
interface Props {
    tourId: number
    itineraries: ItineraryModel[]
    onAddEditItinerary: (itinerary: ItineraryModel) => void
    onChange: (itineraries: ItineraryModel[]) => void
}
const TourItineraryForm = (props: Props) => {
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const onAddItenerary = () => {
        const newItenerary = new ItineraryModel(props.tourId, props.itineraries.length + 1)
        props.onAddEditItinerary(newItenerary)
    }
    const onEditItenerary = (index: number) => {
        const itinerary = props.itineraries.find(x => x.index == index)
        if (itinerary)
            props.onAddEditItinerary(itinerary)
    }
    const onDeleteItinerary = (index: number) => {
        props.onChange(props.itineraries
            .filter(x => x.index != index)
            .map(x => x.index > index ? { ...x, index: index - 1 } : x))
    }
    const onItineraryUp = (index: number) => {
        const newItinerary = indexUp(props.itineraries, index)
        props.onChange(newItinerary)
    }
    const onItineraryDown = (index: number) => {
        const newItinerary = indexDown(props.itineraries, index)
        props.onChange(newItinerary)
    }


    return <FormFieldWrapper>

        <Tooltip text={Localization.getString('Add itinerary')}>
            <Buttons.ButtonSmallGreen onClick={onAddItenerary}>
                <i className="fa fa-plus" />
            </Buttons.ButtonSmallGreen>
        </Tooltip>
        <br />
        <br />
        {props.itineraries.map((itinerary, i) => {
            return <FormFieldWrapper key={itinerary.index + itinerary.id}>
                <label>Itinerary #{itinerary.index}: {itinerary.name} ({itinerary.stops.length} stops)
                    <i className='fa fa-trash float-right clickable' onClick={() => onDeleteItinerary(itinerary.index)} />
                    <i className='fa fa-pencil float-right clickable' onClick={() => onEditItenerary(itinerary.index)} />
                    {i > 0 && <i className='fa fa-arrow-up float-right clickable' onClick={() => onItineraryUp(itinerary.index)} />}
                    {i < props.itineraries.length - 1 && <i className='fa fa-arrow-down float-right clickable' onClick={() => onItineraryDown(itinerary.index)} />}

                </label>
                <ul>
                    {itinerary.stops.map(stop => <li key={`stop-${stop.index}`}>{stop.name}</li>)}
                </ul>

            </FormFieldWrapper>
        })}
    </FormFieldWrapper>
}
export default TourItineraryForm