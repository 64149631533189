import TicketType from './../models/TicketType'
import { AppThunkAction } from '.'
import TicketsApiService from './../services/TicketsApiService'
import { Reducer, Action } from 'redux'

export interface State {
    types: TicketType[]
}
const TICKETS_TYPES_RESPONSE = 'A_TICKETS_TYPES_RESPONSE'
interface TicektsTypesResponse {
    type: 'A_TICKETS_TYPES_RESPONSE'
    payload: TicketType[]
}

type KnownAction = TicektsTypesResponse
export const actionCreators = {
    requestTypes: (): AppThunkAction<KnownAction> => async (dispatch) => {
        let fetchTask = await TicketsApiService.getTypes(dispatch)
        if (fetchTask.IsOk && fetchTask.Content) {
            dispatch({
                type: TICKETS_TYPES_RESPONSE,
                payload: fetchTask.Content.sort((a, b) => (a.name > b.name ? 1 : -1)).map((x) => TicketType.Create(x)),
            })
        }
    },
}
const unloadedState: State = {
    types: [] as TicketType[],
}
export const reducer: Reducer<State> = (state: State = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction
    switch (action.type) {
        case TICKETS_TYPES_RESPONSE:
            return { ...state, types: action.payload }
    }
    return state || unloadedState
}
