import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// import { actionCreators as languagesActions } from '../store/Languages'
import { ApplicationState } from '../store'
import {  AuthStore } from 'traveldesk-ui'
import Loading from '../components/Loading'
const LogoutPage = () => {
    const auth = useSelector((state: ApplicationState) => state.auth)
    const dispatch = useDispatch()
    React.useEffect(() => {
        dispatch(AuthStore.actionCreators.signOut())
    }, [])
    return auth.isAuthorized ? <Loading /> : <Redirect to="/" />
}
export default LogoutPage
