import { apiCallWrapper } from 'traveldesk-ui'
import { SelectOptionModel } from 'traveldesk-ui'
import PickUpTime from './../models/PickUpTime'
export default class PickUpTimesApiService {
    static getOptions = async (dispatch: any) => {
        const res = await apiCallWrapper<SelectOptionModel[]>('/api/pickuptimes/options', 'GET', dispatch)
        res.Content =
            res.Content?.sort((a: SelectOptionModel, b: SelectOptionModel) => (a.label > b.label ? 1 : -1)) ?? []
        return res
    }
    static getManageList = async (dispatch: any) => {
        const res = await apiCallWrapper<PickUpTime[]>('/api/pickuptimes', 'GET', dispatch)
        return res
    }
    static getForEdit = async (dispatch: any, id: number) => {
        const res = await apiCallWrapper<PickUpTime>(`/api/pickuptimes/${id}`, 'GET', dispatch)
        return res
    }
    static save = async (pickUpTime: PickUpTime, dispatch: any) => {
        const res = await apiCallWrapper<PickUpTime>(
            `/api/pickuptimes`,
            pickUpTime.id == 0 ? 'POST' : 'PUT',
            dispatch,
            pickUpTime,
        )
        res.Content = res.Content ? PickUpTime.Create(res.Content) : res.Content
        return res
    }
}
