import * as React from 'react'
import TimeSlot, { TimeSlotTimeType, getTimeSlotTimeTypeOptions } from './../../models/TimeSlot'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from './../../store/index'
import { actionCreators } from './../../store/TimeSlots'
import DaysOfferedService from './../../services/daysOfferedService'
import { Link } from 'react-router-dom'
import { PInTableList, SelectOptionModel, SortType, Tooltip, defaultLabelSort } from 'traveldesk-ui'
import { DataTableColumnModel, FilterType, DataTableModel, DataTableModelCustomAction, DataTable } from 'traveldesk-ui'
import TimeSlotGroup from '../../models/TimeSlotGroup'
import booleanOptions from '../../valueObjects/booleanOptions'

const TimeSlots = () => {
    const dispatch = useDispatch()
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const timeSlotsGroups = useSelector((state: ApplicationState) => state.timeSlots.timeSlotsGroups)
    const groupsOptions = React.useMemo(() => {
        return timeSlotsGroups.map((tsg) => ({ value: tsg.id, label: tsg.name }))
    }, [timeSlotsGroups])
    const timeSlotsOptions = React.useMemo(() => {
        return timeSlotsGroups.reduce((res: SelectOptionModel[], tsGroup: TimeSlotGroup) => {
            res = res.concat(tsGroup.timeSlots.map(ts => ({ value: ts.id, label: ts.name })))
            return res
        }, [] as SelectOptionModel[]).sort(defaultLabelSort)
    }, [timeSlotsGroups])
    const offersOptions = React.useMemo(() => {
        return timeSlotsGroups.reduce((res: SelectOptionModel[], tsGroup: TimeSlotGroup) => {
            tsGroup.timeSlots.forEach(ts => {
                ts.offers.forEach(offer => {
                    if (!res.some(o => offer.id == o.value)) {
                        res.push({ value: offer.id, label: offer.nameInternal })
                    }
                })
            })
            return res
        }, [])
    }, [timeSlotsGroups])
    const daysOptions = React.useMemo(() => {
        return DaysOfferedService.daysNames.slice(1).map((day, index) => ({ label: day, value: index }))
    }, [DaysOfferedService.daysNames])
    const initDataTable = () => {
        const columns = [
            new DataTableColumnModel({
                displayName: Localization.getString('Group name'),
                fieldName: 'groupId',
                filterFieldName: 'groupId',
                width: 100,
                customRenderer: (obj: TimeSlot) => obj.groupName,
                sortable: true,
                filterType: FilterType.MULTISELECT,
                filterOptions: groupsOptions,
                sortFunc: (sortType: SortType, sortField: string) => (obj1: TimeSlot, obj2: TimeSlot) => (obj1.name > obj2.name ? sortType == "ASC" ? 1 : -1 : sortType == "ASC" ? -1 : 1),
            }),
            new DataTableColumnModel({
                displayName: Localization.getString('Time slot'),
                fieldName: 'name',
                width: 100,
                filterFieldName: "id",
                filterType: FilterType.MULTISELECT,
                filterOptions: timeSlotsOptions,
                sortable: true,
            }),
            new DataTableColumnModel({
                displayName: Localization.getString('From'),
                fieldName: 'startTimeDescription',
                sortable: true,
                width: 60,
            }),
            new DataTableColumnModel({
                displayName: Localization.getString('To'),
                fieldName: 'endTimeDescription',
                sortable: true,
                width: 60
            }),
            new DataTableColumnModel({
                displayName: Localization.getString('Days offered'),
                fieldName: "DaysOffered",
                filterOptions: daysOptions,
                filterType: FilterType.MULTISELECT,
                customFilterFunc: (filterValue: number[], data: TimeSlot[]) => {
                    return filterValue.length > 0
                        ? data.filter((x) => x.daysOffered == 0 || filterValue.some((f) => (f & x.daysOffered) > 0))
                        : data
                },
                customRenderer: (obj: TimeSlot) =>
                    DaysOfferedService.getDaysNames(obj.daysOffered, Localization).join(','),
            }),
            new DataTableColumnModel({
                displayName: "Offers",
                fieldName: "offers",
                filterOptions: offersOptions,
                filterType: FilterType.MULTISELECT,
                customFilterFunc: (filterValue: number[], data: TimeSlot[]) => {
                    return filterValue.length > 0
                        ? data.filter((x) => x.offers.some(offer => filterValue.indexOf(offer.id) >= 0))
                        : data
                },
                width: 100,
                customRenderer: (obj: TimeSlot) => (
                    <React.Fragment>
                        {obj.offers.map(offer => <Link target='_blank' key={offer.id} to={`/tours/${offer.tourId}/${offer.id}`}><PInTableList>{offer.nameInternal}</PInTableList></Link>)}
                    </React.Fragment>
                )
            }),
            new DataTableColumnModel({
                displayName: "Type",
                fieldName: "timeType",
                customRenderer: (obj: TimeSlot) => TimeSlotTimeType[obj.timeType],
                width: 60,
                filterType: FilterType.MULTISELECT,
                filterOptions: getTimeSlotTimeTypeOptions(Localization)
            }),
            new DataTableColumnModel({
                displayName: "Is External",
                fieldName: "isExternalId",
                filterType: FilterType.SELECT,
                filterOptions: booleanOptions,
                customFilterFunc: (filterValue: any, data: TimeSlot[]) => filterValue === 2 ? data.filter(x => !x.isExternalId) : filterValue === 1 ? data.filter(x => x.isExternalId) : data,
                customRenderer: (obj: TimeSlot) => obj.isExternalId ? <>
                    <PInTableList>Ext. Product Id: {obj.externalProductId} {obj.externalId ? `(${obj.externalId})` : ""}</PInTableList>
                    {obj.externalProductPerChildId ? <PInTableList>Ext. Product Child Id: {obj.externalProductPerChildId} {obj.externalPerChildId ? `(${obj.externalPerChildId})` : ""}</PInTableList> : undefined}
                </> : undefined,
                width: 60,
                // filterType: FilterType.MULTISELECT,
                // filterOptions: getTimeSlotTimeTypeOptions(Localization)
            }),
            new DataTableColumnModel({
                displayName: '',
                width: 40,
                customRenderer: (obj: TimeSlot) => (
                    <Link to={{ pathname: `/timeslots/${obj.groupId}`, hash: `#ts-${obj.id}` }}>
                        <i className="fa fa-pencil clickable" />
                    </Link>
                ),
            }),
        ]
        return new DataTableModel({
            columns,
            name: Localization.getString('Time Slots'),
            customActions: [
                new DataTableModelCustomAction(
                    () => { },
                    () => (
                        <Tooltip style={{ width: 150 }} text={Localization.getString('Add new time slots group')}>
                            {' '}
                            <Link to={'/timeslots/add'}>
                                {' '}
                                <i className="fa fa-plus clickable" />
                            </Link>
                        </Tooltip>
                    ),
                ),
            ],
        })
    }
    const timeSlots = React.useMemo(() => {
        const res = timeSlotsGroups.reduce((res, next) => {
            return res.concat(next.timeSlots.map((ts) => TimeSlot.Create(ts, next)))
        }, [] as TimeSlot[])
        return res
    }, [timeSlotsGroups])
    const getTimeSlots = () => {
        dispatch(actionCreators.requestTimeSlotsGroups())
    }
    React.useEffect(() => {
        getTimeSlots()
    }, [])
    const onRefresh = () => {
        getTimeSlots()
    }
    return (
        <DataTable
            onRefreshData={onRefresh}
            sortField="groupId"
            sortType="ASC"
            data={timeSlots}
            dataTable={initDataTable()}
            isFullScreenable
            isFullScreen={true}
        />
    )
}
export default TimeSlots
