import BookingOperations from '../models/BookingOperations'
import { AppThunkAction } from '.'
import { PaginationModel } from 'traveldesk-ui'
import { LoadingStore } from 'traveldesk-ui'
import { apiCallWrapper } from 'traveldesk-ui'
import { Action, Reducer } from 'redux'
import BookingsOperationsFilter from '../serviceModels/BookingsOperationsFilter'
import { EntitiesPaginatedModel } from 'traveldesk-ui'
export interface State {
    bookings: EntitiesPaginatedModel<BookingOperations>
}

const RECEIVE_BOOKINGS = 'A_RECEIVE_BOOKINGS'
interface ReceiveBookingsAction {
    type: 'A_RECEIVE_BOOKINGS'
    payload: EntitiesPaginatedModel<BookingOperations>
}
const UPDATE_BOOKING = 'A_UPDATE_BOOKING'
interface UpdateBookingAction {
    type: 'A_UPDATE_BOOKING'
    payload: BookingOperations
}
type KnownAction = ReceiveBookingsAction | UpdateBookingAction

export const actionCreators = {
    requestBookingsOperations: (
        filter: BookingsOperationsFilter,
    ): AppThunkAction<KnownAction | LoadingStore.KnownAction> => async (dispatch) => {
        dispatch(LoadingStore.actionCreators.incrementLoading())
        let fetchTask = await apiCallWrapper<EntitiesPaginatedModel<BookingOperations>>(
            `/api/bookings/operations`,
            'POST',
            dispatch,
            filter,
        )
        const data = fetchTask.Content
            ? Object.assign(new EntitiesPaginatedModel<BookingOperations>(), {
                  entities: fetchTask.Content.entities.map((x) => BookingOperations.Create(x)),
                  pagingInfo: Object.assign(new PaginationModel(), fetchTask.Content.pagingInfo),
              })
            : new EntitiesPaginatedModel<BookingOperations>()
        if (fetchTask.IsOk && data) {
            dispatch({ type: RECEIVE_BOOKINGS, payload: data })
        }
        dispatch(LoadingStore.actionCreators.decrementLoading())
    },
}

const unloadedState: State = {
    bookings: new EntitiesPaginatedModel<BookingOperations>(),
}

export const reducer: Reducer<State> = (state: State = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction
    switch (action.type) {
        case RECEIVE_BOOKINGS:
            return {
                ...state,
                bookings: action.payload,
            }
        default:
    }
    return state || unloadedState
}
