import * as React from 'react'
import { DataTable, DataTableColumnModel, FilterType, DataTableModelCustomAction, DataTableModel } from 'traveldesk-ui'
import { Link } from 'react-router-dom'
import { TourManageTableModel } from '../models/TourManageModel'
import { PInTableList, SelectOptionModel } from 'traveldesk-ui'
import { useLanguagesManageTable } from '../effects/useLanguages'
import * as ToursState from '../store/Tours'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../store'
const ToursPage = () => {
    const languages = useLanguagesManageTable()
    const [languagesOptions, setLanguagesOptions] = React.useState([] as SelectOptionModel[])
    const [toursOptions, setToursOptions] = React.useState([] as SelectOptionModel[])
    const tours = useSelector((state: ApplicationState) => state.tours)
    const [showDeleted, setShowDeleted] = React.useState<boolean>(false)
    React.useEffect(() => {
        setFiltersOptions()
    }, [tours])
    const setFiltersOptions = () => {
        const options = languages.languagesOptions.filter((x) =>
            tours.toursManage.some((tour) => tour.languageId == x.value),
        )
        const toursOptions = tours.toursManage.reduce((res, next) => {
            res.push({ label: next.nameInternal, value: next.id })
            return res
        }, [] as SelectOptionModel[])
        setToursOptions(toursOptions)
        setLanguagesOptions(options)
    }
    const toggleShowDeleted = () => {
        setShowDeleted(!showDeleted)
    }
    const initDataTable = () => {
        const columns = [
            new DataTableColumnModel({
                displayName: 'Tour Name',
                fieldName: 'nameInternal',
                sortable: true,
                width: 200,
                filterType: FilterType.MULTISELECT,
                filterOptions: toursOptions,
                filterFieldName: 'id',
                customRenderer: (obj: TourManageTableModel) => (
                    <>
                        {obj.nameInternal} ({obj.nameDisplay})
                    </>
                ),
            }),
            new DataTableColumnModel({
                displayName: 'Language',
                width: 40,
                fieldName: 'languageId',
                customFilterFunc: (filterValue, data: TourManageTableModel[]) =>
                    data.filter((x) => filterValue.some((f: any) => x.languageId == f)),
                customRenderer: (obj: TourManageTableModel) => languages.languagesHash.get(obj.languageId)?.name ?? '',
                filterType: FilterType.MULTISELECT,
                filterOptions: languagesOptions,
            }),
            new DataTableColumnModel({
                displayName: 'Offers',
                width: 200,
                customRenderer: (obj: TourManageTableModel) => (
                    <>
                        {obj.offers.filter(x => showDeleted ? true : !x.isDeleted).map((x) => (
                            <Link key={x.id} to={`/tours/${obj.id}/${x.id}`}>
                                <PInTableList isDeleted={x.isDeleted}>
                                    {x.nameDisplay} ({x.nameInternal})
                                </PInTableList>
                            </Link>
                        ))}
                    </>
                ),
            }),
            // new DataTableColumnModel({
            //     displayName: 'Status',
            //     width: 40,
            // }),
            new DataTableColumnModel({
                displayName: "",
                width: 40,
                customRenderer: (obj: TourManageTableModel) => <>
                    {obj.isTopTour && <i className='fa fa-star warning' />}
                </>,
                filterType: FilterType.SELECT,
                filterOptions: [{ label: "Top Tours", value: 1 }, { label: "Not top tours", value: -1 }],
                customFilterFunc: (value, data) => {
                    return value ? value == 1 ? data.filter(x => x.isTopTour) : value == -1 ? data.filter(x => !x.isTopTour) : data : data
                }
            }),
            new DataTableColumnModel({
                displayName: '',
                width: 40,
                customRenderer: (obj: TourManageTableModel) => (
                    <Link to={`/tours/${obj.id}`}>
                        <i className="fa fa-pencil clickable" />
                    </Link>
                ),
            })
        ]
        return new DataTableModel({
            columns,
            name: 'Tours',
            fileName: 'tours.xlsx',
            customActions: [
                new DataTableModelCustomAction(
                    () => { },
                    () => (
                        <Link to="/tours/add">
                            <i className="fa fa-plus" />
                        </Link>
                    ),
                ),
                new DataTableModelCustomAction(
                    () => { },
                    () => (
                        <i title={showDeleted ? "Shows deleted tours and offers" : "Hides deleted tours and offers"} onClick={toggleShowDeleted} className={`fa fa-trash clickable${showDeleted ? " active" : ""}`} />
                    )
                )
            ],
        })
    }
    const onRefresh = () => {
        getTours()
    }
    const dispatch = useDispatch()
    const getTours = async () => {
        dispatch(ToursState.actionCreators.requestToursManage())
    }
    React.useEffect(() => {
        getTours()
    }, [])
    return (
        <DataTable
            onRefreshData={onRefresh}
            sortField="nameInternal"
            sortType="ASC"
            data={tours.toursManage}
            dataTable={initDataTable()}
            isFullScreenable
            isFullScreen={true}
        />
    )
}
export default ToursPage
