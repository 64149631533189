import * as React from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
interface Props {
    url: string
}
const VideoContainer = styled.div`
    position: relative;
    margin-top: -20px;
    margin-bottom: 25px;
    padding-top: 56.25%;
    .react-player {
        position: absolute;
        top: 0;
        left: 0;
    }
`

const VideoDisplay = (props: Props) => {
    return props.url ? (
        <VideoContainer>
            <ReactPlayer className="react-player" url={props.url} width={'100%'} height={'100%'} />
        </VideoContainer>
    ) : null
}
export default VideoDisplay
