import { PaginationModel, EntitiesPaginatedModel } from 'traveldesk-ui'
import BookingAgent from '../pages/Agent/Models/BookingAgent'
import BookingsRequest from '../pages/Agent/Models/BookingsRequest'
import { LoadingStore } from 'traveldesk-ui'
import AgencyAreaApiService from '../services/AgencyAreaApiService'
import { AppThunkAction } from '.'
import { Reducer, Action } from 'redux'
import OfferAgent from '../pages/Agent/Models/OfferAgent'
import Hotel from '../models/Hotel'
import IdNameModel from '../models/IdNameModel'

export interface State {
    data?: EntitiesPaginatedModel<BookingAgent>
    offers: OfferAgent[]
    offersHash: Map<number, OfferAgent>
    hotels: Hotel[]
    hotelsHash: Map<number, Hotel>
    agents: IdNameModel[]
    agentsHash: Map<number, IdNameModel>
}

const RECEIVE_AGENCY_COMMISSIONS = 'A_RECEIVE_AGENCY_COMMISSIONS'
interface ReceiveAgentCommissions {
    type: 'A_RECEIVE_AGENCY_COMMISSIONS'
    payload: EntitiesPaginatedModel<BookingAgent>
}
const RECEIVE_AGENT_AGENTS = 'A_RECEIVE_AGENT_AGENTS'
interface ReceiveAgentAgents {
    type: 'A_RECEIVE_AGENT_AGENTS'
    payload: IdNameModel[]
}
const RECEIVE_AGENT_OFFERS = 'A_RECEIVE_AGENT_OFFERS'
interface ReceiveAgentOffers {
    type: 'A_RECEIVE_AGENT_OFFERS'
    payload: OfferAgent[]
}
const RECEIVE_AGENT_HOTELS = 'A_RECEIVE_AGENT_HOTELS'
interface ReceiveAgentHotels {
    type: 'A_RECEIVE_AGENT_HOTELS'
    payload: Hotel[]
}
type KnownAction = ReceiveAgentCommissions | ReceiveAgentOffers | ReceiveAgentHotels | ReceiveAgentAgents

export const actionCreators = {
    requestAgencyAgents: (): AppThunkAction<KnownAction | LoadingStore.KnownAction> => async (dispatch) => {
        dispatch(LoadingStore.actionCreators.incrementLoading())
        var fetchTask = await AgencyAreaApiService.getCommissionAgents(dispatch)
        if (fetchTask.IsOk && fetchTask.Content) {
            dispatch({
                type: RECEIVE_AGENT_AGENTS,
                payload: fetchTask.Content.sort((a, b) => (a.name > b.name ? 1 : -1)),
            })
        }
        dispatch(LoadingStore.actionCreators.decrementLoading())
    },
    requestAgencyHotels: (): AppThunkAction<KnownAction | LoadingStore.KnownAction> => async (dispatch) => {
        dispatch(LoadingStore.actionCreators.incrementLoading())
        var fetchTask = await AgencyAreaApiService.getCommissionHotels(dispatch)
        if (fetchTask.IsOk && fetchTask.Content) {
            dispatch({
                type: RECEIVE_AGENT_HOTELS,
                payload: fetchTask.Content.sort((a, b) => (a.name > b.name ? 1 : -1)).map((x) => Hotel.Create(x)),
            })
        }
        dispatch(LoadingStore.actionCreators.decrementLoading())
    },
    requestAgencyOffers: (): AppThunkAction<KnownAction | LoadingStore.KnownAction> => async (dispatch) => {
        dispatch(LoadingStore.actionCreators.incrementLoading())
        var fetchTask = await AgencyAreaApiService.getCommissionOffers(dispatch)
        if (fetchTask.IsOk && fetchTask.Content) {
            dispatch({
                type: RECEIVE_AGENT_OFFERS,
                payload: fetchTask.Content.sort((a, b) => (a.name > b.name ? 1 : -1)).map((x) => OfferAgent.Create(x)),
            })
        }
        dispatch(LoadingStore.actionCreators.decrementLoading())
    },
    requestAgencyCommissions: (
        request: BookingsRequest,
    ): AppThunkAction<KnownAction | LoadingStore.KnownAction> => async (dispatch) => {
        dispatch(LoadingStore.actionCreators.incrementLoading())
        var fetchTask = await AgencyAreaApiService.getCommissions(request, dispatch)
        const data = fetchTask.Content
            ? Object.assign(new EntitiesPaginatedModel<BookingAgent>(), {
                  entities: fetchTask.Content.entities.map((x) => BookingAgent.Create(x)),
                  pagingInfo: Object.assign(new PaginationModel(), fetchTask.Content.pagingInfo),
              })
            : new EntitiesPaginatedModel<BookingAgent>()
        if (fetchTask.IsOk && data) {
            dispatch({ type: RECEIVE_AGENCY_COMMISSIONS, payload: data })
        }
        dispatch(LoadingStore.actionCreators.decrementLoading())
    },
}
const unloadedState: State = {
    data: undefined,
    offers: [],
    offersHash: new Map<number, OfferAgent>(),
    hotels: [],
    hotelsHash: new Map<number, Hotel>(),
    agents: [],
    agentsHash: new Map<number, IdNameModel>(),
}

export const reducer: Reducer<State> = (state: State | undefined, incomingAction: Action): State => {
    if (state === undefined) {
        return unloadedState
    }

    const action = incomingAction as KnownAction
    switch (action.type) {
        case RECEIVE_AGENCY_COMMISSIONS:
            return {
                ...state,
                data: action.payload,
            }
        case RECEIVE_AGENT_AGENTS:
            const agentsHash = action.payload.reduce((res, next) => {
                res.set(next.id as number, next)
                return res
            }, new Map<number, IdNameModel>())
            return {
                ...state,
                agents: action.payload,
                agentsHash,
            }
        case RECEIVE_AGENT_HOTELS:
            return {
                ...state,
                hotels: action.payload,
                hotelsHash: action.payload.reduce((res, next) => {
                    res.set(next.id, next)
                    return res
                }, new Map<number, Hotel>()),
            }
        case RECEIVE_AGENT_OFFERS:
            return {
                ...state,
                offers: action.payload,
                offersHash: action.payload.reduce((res, next) => {
                    res.set(next.id, next)
                    return res
                }, new Map<number, OfferAgent>()),
            }
        default:
            return state
    }
}
