import * as Global from './Global'
import * as Counter from './Counter'
import * as Cities from './Cities'
import { AuthStore } from 'traveldesk-ui'
import * as Tours from './Tours'
import * as ToursCategories from './ToursCategories'
import * as Bookings from './Bookings'
import { LoadingStore as Loading, Localization, MediaStore, LanguagesStore, NotificationsStore, SettingsStore } from 'traveldesk-ui'
import * as Account from './Account'
import * as Tickets from './Tickets'
import * as TimeSlots from './TimeSlots'
import * as PickUpTimes from './PickUpTimes'
import * as Regions from './Regions'
import * as Supplier from './Supplier'
import * as Countries from './Countries'
import * as AgencyCommissions from './AgencyCommissions'
import * as AgencyBookings from './AgencyBookings'
import * as Arrivals from './Arrivals'
import * as ToursRecommendedFor from './ToursRecommendedFor'

// The top-level state object
export interface ApplicationState {
    arrivals: Arrivals.State
    agencyCommissions: AgencyCommissions.State
    agencyBookings: AgencyBookings.State
    global: Global.State
    auth: AuthStore.State
    cities: Cities.State
    localization: Localization.State
    regions: Regions.State
    tours: Tours.State
    toursCategories: ToursCategories.State
    toursRecommendedFor: ToursRecommendedFor.State
    languages: LanguagesStore.State
    bookings: Bookings.State
    notifications: NotificationsStore.State
    photos: MediaStore.State
    loading: Loading.State
    account: Account.State
    tickets: Tickets.State
    timeSlots: TimeSlots.State
    pickUpTimes: PickUpTimes.State
    supplier: Supplier.State
    countries: Countries.State
    settings: SettingsStore.State
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    auth: AuthStore.reducer,
    arrivals: Arrivals.reducer,
    agencyCommissions: AgencyCommissions.reducer,
    agencyBookings: AgencyBookings.reducer,
    account: Account.reducer,
    localization: Localization.reducer,
    tours: Tours.reducer,
    toursCategories: ToursCategories.reducer,
    toursRecommendedFor: ToursRecommendedFor.reducer,
    global: Global.reducer,
    counter: Counter.reducer,
    languages: LanguagesStore.reducer,
    cities: Cities.reducer,
    bookings: Bookings.reducer,
    notifications: NotificationsStore.reducer,
    photos: MediaStore.reducer,
    loading: Loading.reducer,
    tickets: Tickets.reducer,
    timeSlots: TimeSlots.reducer,
    pickUpTimes: PickUpTimes.reducer,
    regions: Regions.reducer,
    supplier: Supplier.reducer,
    countries: Countries.reducer,
    settings: SettingsStore.reducer
}

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void
}
