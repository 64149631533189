import { apiCallWrapper } from 'traveldesk-ui'
import Offer from '../models/Offer'
export default class OffersApiService {
    static save = async (dispatch: any, model: Offer) => {
        return await apiCallWrapper<Offer>('/api/offers', model.id == 0 ? 'POST' : 'PUT', dispatch, model)
    }
    static getOfferForEdit = async (dispatch: any, id: number) => {
        return await apiCallWrapper<Offer>(`/api/offers/manage/${id}`, 'GET', dispatch)
    }
}
