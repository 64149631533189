import * as React from 'react'
import { RouteComponentProps, Route } from 'react-router'
import { ApplicationState } from '../../store'
import { useSelector } from 'react-redux'
import AdminCoreInfo from './AdminCoreInfo'
import AdminContacts from './AdminContacts'
import AdminContracting from './AdminContracting'
import AdminStaff from './AdminStaff'
import AdminDefaultSettings from './AdminDefaultSettings'
import { Link } from 'react-router-dom'

interface Props extends RouteComponentProps<{ tab: string }> { }
const urls = [
    { url: 'core', name: 'Core Info', component: AdminCoreInfo },
    { url: 'contacts', name: 'Contacts', component: AdminContacts },
    { url: 'contracting', name: 'Contracting', component: AdminContracting },
    { url: 'staff', name: 'Staff', component: AdminStaff },
    { url: 'defaultsettings', name: 'Default Settings', component: AdminDefaultSettings },
]
const AdminLayout = (props: Props) => {
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    return (
        <div className="tabs-wrapper is-vertical">
            <div className="tab-head">
                {urls.map((url, index) => (
                    <Link key={index} to={url.url}>
                        <div
                            key={url.url}
                            className={`tab-head-item${url.url == props.match.params.tab ? ' is-active' : ''}`}
                        >
                            {Localization.getString(url.name)}
                        </div>
                    </Link>
                ))}
                {/* <div className={`tab-head-item$`}>{Localization.getString('Core Info')}</div>
                <div className={`tab-head-item`}>{Localization.getString('Contacts')}</div>
                <div className={`tab-head-item`}>{Localization.getString('Contracting')}</div>
                <div className={`tab-head-item`}>{Localization.getString('Staff')}</div>
                <div className={`tab-head-item`}>{Localization.getString('Default Settings')}</div> */}
            </div>
            <div className="tab-content-wrap">
                {urls.map((url, index) => (
                    <Route key={index} path={`/admin/${url.url}`} component={url.component} />
                ))}
            </div>
        </div>
    )
}
export default AdminLayout
