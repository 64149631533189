import * as React from 'react'
import { actionCreators as countriesActions } from './../store/Countries'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from './../store/index'
export const useCountriesOptions = () => {
    const dispatch = useDispatch()
    const countriesOptions = useSelector((state: ApplicationState) => state.countries.countriesOptions)
    React.useEffect(() => {
        if (countriesOptions.length == 0) {
            dispatch(countriesActions.requestCountriesOptions())
        }
    }, [])
    return countriesOptions
}
