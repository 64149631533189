import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from '../../store'
import { useForm, Controller } from 'react-hook-form'
import { actionCreators as supplierActions } from './../../store/Supplier'
import { Supplier, SupplierPaymentDetails } from '../../models/Supplier'
import { nameof } from 'traveldesk-ui'
import {
    Row,
    Col,
    TextFormField,
    TextAreaField,
    SelectFormField,
    FormFieldLabel,
    Buttons,
    SelectOptionModel,
    FormFieldWrapper,
} from 'traveldesk-ui'
import { useCountriesOptions } from './../../effects/useCountriesOptions'
import { useSupplier } from './../../effects/useSupplier'
interface Props extends RouteComponentProps<{ tab: string }> {}
const AdminCoreInfo = (props: Props) => {
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const supplier = useSupplier()
    const countriesOptions = useCountriesOptions()
    const dispatch = useDispatch()
    const { reset, register, handleSubmit, formState: {errors}, control } = useForm({ defaultValues: supplier })
    const onSubmit = (data: any) => {
        dispatch(supplierActions.updateSupplier(supplier, data))
    }
    React.useEffect(() => {
        reset(supplier)
    }, [supplier])
    return (
        <div className="tab-content-block is-active" data-tab="tab-v01">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col type="100">
                        <h2>{Localization.getString('Basic Details')}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col type="50">
                        <TextFormField
                            defaultValue={supplier?.code}
                            label={Localization.getString('Business Code')}
                            subLabel={Localization.getString('A short code to symbolize your business')}
                            isRequired
                            register={register}
                            options={{ required: Localization.getString('Business code is required') }}
                            name={nameof<Supplier>('code')}
                            error={errors.code ? errors.code.message : ''}
                        />
                        <TextFormField
                            defaultValue={supplier?.name}
                            label={Localization.getString('Legal Name')}
                            subLabel={Localization.getString(
                                'Official name as per your business registration documents',
                            )}
                            isRequired
                            register={register}
                            options={{ required: Localization.getString('Legal name is required')}}
                            name={nameof<Supplier>('name')}
                            error={errors.name ? errors.name.message : ''}
                        />
                        <TextFormField
                            defaultValue={supplier?.taxRegistrationNumber}
                            subLabel={Localization.getString('Any tax reg. that must be present on your invoices')}
                            label={Localization.getString('Tax Registration #')}
                            register={register}
                            name={nameof<Supplier>('taxRegistrationNumber')}
                            error={errors.taxRegistrationNumber ? errors.taxRegistrationNumber.message : ''}
                        />
                        <TextFormField
                            defaultValue={supplier?.tradeLicenseNumber}
                            subLabel={Localization.getString('Government issued business registration number')}
                            label={Localization.getString('Trade License #')}
                            register={register}
                            name={nameof<Supplier>('tradeLicenseNumber')}
                            error={errors.tradeLicenseNumber ? errors.tradeLicenseNumber.message : ''}
                        />
                    </Col>
                    <Col type="50">
                        <Controller
                            name={nameof<Supplier>('countryId')}
                            render={({field}) =>(
                                <SelectFormField
                                    label={Localization.getString('Country')}
                                    name={nameof<Supplier>('countryId')}
                                    options={countriesOptions}
                                    subLabel={Localization.getString(
                                        'Select the country where you provide services. Note: only 1 country per profile',
                                    )}
                                    onChange={([e]: any) => {
                                        return (e as SelectOptionModel).value
                                    }}
                                />
                                )
                            }
                            defaultValue={supplier?.countryId}
                            control={control}
                        />
                        <TextAreaField
                            defaultValue={supplier?.address}
                            subLabel={Localization.getString('Legal address as per business registration documents')}
                            label={Localization.getString('Address')}
                            register={register}
                            name={nameof<Supplier>('address')}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col type="100">
                        <h2>{Localization.getString('Payment Details')}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col type="50">
                        <TextFormField
                            defaultValue={supplier?.paymentDetails.accountNumber}
                            label={Localization.getString('Account Number')}
                            // isRequired
                            register={register}
                            name={`${nameof<Supplier>('paymentDetails')}.${nameof<SupplierPaymentDetails>(
                                'accountNumber',
                            )}`}
                        />
                        <TextFormField
                            defaultValue={supplier?.paymentDetails.ibanNumber}
                            label={Localization.getString('IBAN Number')}
                            // isRequired
                            register={register}
                            name={`${nameof<Supplier>('paymentDetails')}.${nameof<SupplierPaymentDetails>(
                                'ibanNumber',
                            )}`}
                        />
                        <TextFormField
                            defaultValue={supplier?.paymentDetails.swiftCode}
                            label={Localization.getString('SWIFT Code')}
                            // isRequired
                            register={register}
                            name={`${nameof<Supplier>('paymentDetails')}.${nameof<SupplierPaymentDetails>(
                                'swiftCode',
                            )}`}
                        />
                    </Col>
                    <Col type="50">
                        <TextFormField
                            defaultValue={supplier?.paymentDetails.bankName}
                            label={Localization.getString('Bank Name')}
                            // isRequired
                            register={register}
                            name={`${nameof<Supplier>('paymentDetails')}.${nameof<SupplierPaymentDetails>('bankName')}`}
                        />
                        <TextAreaField
                            defaultValue={supplier?.paymentDetails.bankBranchAddress}
                            label={Localization.getString('Bank Branch Address')}
                            register={register}
                            name={`${nameof<Supplier>('paymentDetails')}.${nameof<SupplierPaymentDetails>(
                                'bankBranchAddress',
                            )}`}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col type="100">
                        <h2>{Localization.getString('Payments')}</h2>
                        <FormFieldWrapper>
                            <FormFieldLabel label={Localization.getString('Settlement Period')} />
                            <p>{Localization.getString('Settlement Period Info')}</p>
                        </FormFieldWrapper>
                        <FormFieldWrapper>
                            <FormFieldLabel label={Localization.getString('Currency')} />
                            <p>{Localization.getString('Currency Info')}</p>
                        </FormFieldWrapper>
                        <FormFieldWrapper>
                            <FormFieldLabel label={Localization.getString('Payment method')} />
                            <p>{Localization.getString('Payment Method Info')}</p>
                        </FormFieldWrapper>
                        <FormFieldWrapper>
                            <FormFieldLabel label={Localization.getString('Reports')} />
                            <p>{Localization.getString('Reports Info')}</p>
                        </FormFieldWrapper>
                        <hr />
                        <p className="p-strong">{Localization.getString('I acknowledge')}</p>
                        {/* {supplier?.isAcknowledged ? ""} */}
                    </Col>
                </Row>
                <Buttons.StickySaveButtonTabs>{Localization.getString('Save')}</Buttons.StickySaveButtonTabs>
            </form>
        </div>
    )
}
export default AdminCoreInfo
