import TimeSlot from './TimeSlot'

export default class TimeSlotGroup {
    static Create(obj: TimeSlotGroup) {
        return Object.assign(new TimeSlotGroup(), obj, {
            timeSlots: (obj.timeSlots || []).map((ts) => TimeSlot.Create(ts, obj)),
        })
    }
    id: number = 0
    name: string = ''
    pickUpTimeId?: number
    capacity: number = 1
    minimumAdvancedBooking?: number = 0
    isTimeSlotsIndependant: boolean = false
    timeSlots: TimeSlot[] = []
}
