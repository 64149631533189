class PickUpTimeValue {
    timeStart: number = 0
    timeEnd: number = 0
    name: string = ''
}
export class PickUpTimeRegionValue extends PickUpTimeValue {
    regionId: number = 0
    cityId: number = 0
    cityName: string = ''
    isActive: boolean = false
}
export class PickUpTimeHotelValue extends PickUpTimeValue {
    hotelId: number = 0
    regionId?: number = 0
    regionName: string = ''
    cityId: number = 0
    cityName: string = ''
}
