import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from '../store'
import * as BookingsState from '../store/Bookings'
import BookingsOperationsFilter from '../serviceModels/BookingsOperationsFilter'
import { DataTableColumnModel, FilterType, DataTableModelCustomAction, DataTableModel, DataTable } from 'traveldesk-ui'
const BookingsPage = () => {
    const tours = useSelector((state: ApplicationState) => state.tours)
    const bookings = useSelector((state: ApplicationState) => state.bookings)
    const dispatch = useDispatch()
    const [filter, setFilter] = React.useState(new BookingsOperationsFilter())
    React.useEffect(() => {
        getBookings(filter)
    }, [filter])
    const getBookings = (filter: BookingsOperationsFilter) => {
        dispatch(BookingsState.actionCreators.requestBookingsOperations(filter))
    }
    const onSortChanged = (fieldName: string, sortType: string) => {
        const f = Object.assign(new BookingsOperationsFilter(), filter, {
            SortField: fieldName,
            SortType: sortType,
        })
        setFilter(f)
    }
    const initDataTable = () => {
        const columns = [
            new DataTableColumnModel({
                displayName: 'ID',
                fieldName: 'id',
                filterType: FilterType.TEXT,
            }),
            new DataTableColumnModel({
                displayName: 'Date',
                fieldName: 'date',
                filterType: FilterType.DATERANGE,
                filterValue: { from: filter.from, to: filter.to },
                sortable: true,
            }),
            new DataTableColumnModel({
                displayName: 'Tour',
                fieldName: 'tour',
            }),
            new DataTableColumnModel({
                displayName: 'Ticket',
                fieldName: 'ticket',
            }),
            new DataTableColumnModel({
                displayName: 'Hotel',
                filterFieldName: "hotelId",
                fieldName: 'hotelName',
            }),
            new DataTableColumnModel({
                displayName: 'Region',
                fieldName: 'region',
            }),
            new DataTableColumnModel({
                displayName: 'Guest Name',
                fieldName: 'guestName',
            }),
            new DataTableColumnModel({
                displayName: 'Tel.',
                fieldName: 'tel',
            }),
            new DataTableColumnModel({
                displayName: 'Ad.',
                fieldName: 'ad',
                showSum: true,
            }),
            new DataTableColumnModel({
                displayName: 'Ch.',
                fieldName: 'ch',
                showSum: true,
            }),
            new DataTableColumnModel({
                displayName: 'Inf.',
                fieldName: 'inf',
                showSum: true,
            }),
            new DataTableColumnModel({
                displayName: 'foc',
                fieldName: 'foc',
                showSum: true,
            }),
        ]
        return new DataTableModel({
            columns,
            name: 'Bookings',
            fileName: 'bookings',
            showSum: true,
            customActions: [
                new DataTableModelCustomAction(
                    () => { },
                    () => <i className="fa fa-plus" />,
                ),
            ],
        })
    }
    const onClearFilter = () => {
        setFilter(new BookingsOperationsFilter())
    }
    const onPageChanged = (page: number) => {
        setFilter(Object.assign(new BookingsOperationsFilter(), filter, { page }))
    }
    return (
        <DataTable
            isFullScreen
            sortField="date"
            sortType="ASC"
            data={bookings.bookings.entities}
            pagingInfo={bookings.bookings.pagingInfo}
            dataTable={initDataTable()}
            onSortChanged={onSortChanged}
            onClearFilter={onClearFilter}
            onPageChange={onPageChanged}
        />
    )
}
export default BookingsPage
