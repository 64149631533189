import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from '../../store'
import { useForm, Controller } from 'react-hook-form'
import { useSupplier } from '../../effects/useSupplier'
import { actionCreators as supplierActions } from './../../store/Supplier'
import { nameof } from 'traveldesk-ui'

import { Supplier, SupplierDefaultSettings } from '../../models/Supplier'
import Cancellations from '../TourForm/Cancellations'
import CancellationPolicy from './../../models/CancellationPolicy'
import { Buttons, Row, Col, WysiwygFieldController, WysiwygFormField } from 'traveldesk-ui'
interface Props extends RouteComponentProps<{ tab: string }> {}
const AdminDefaultSettings = (props: Props) => {
    const dispatch = useDispatch()
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const supplier = useSupplier()
    const [cancellations, setCancellations] = React.useState(supplier?.cancellationPolicy || [])
    const { reset, register, handleSubmit, control } = useForm({ defaultValues: supplier })
    const onSubmit = (data: any) => {
        data.cancellationPolicy = cancellations
        dispatch(supplierActions.updateSupplier(supplier, data))
    }
    React.useEffect(() => {
        reset(supplier)
        setCancellations(supplier?.cancellationPolicy ?? [])
    }, [supplier])
    const onChangeCancellationPolicy = (cancellation: CancellationPolicy[]) => {
        setCancellations(cancellation)
    }
    return (
        <div className="tab-content-block is-active" data-tab="tab-v01">
            <h2>{Localization.getString('Default Settings')}</h2>
            <p>
                {Localization.getString(
                    'The following settings will be used by default for the following fields in the configuration of your inventory',
                )}
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col type="50">
                        <Cancellations onChange={onChangeCancellationPolicy} cancellations={cancellations} />
                        <WysiwygFieldController
                            label={Localization.getString('Terms & Conditions')}
                            name={`${nameof<Supplier>('defaultSettings')}.${nameof<SupplierDefaultSettings>(
                                'termsAndConditions',
                            )}`}
                            control={control}
                        />
                        <WysiwygFieldController
                            label={Localization.getString('FAQ')}
                            name={`${nameof<Supplier>('defaultSettings')}.${nameof<SupplierDefaultSettings>('faq')}`}
                            control={control}
                        />
                    </Col>
                    <Col type="50">
                        <WysiwygFieldController
                            label={Localization.getString('Notes To Clients Post Booking')}
                            name={`${nameof<Supplier>('defaultSettings')}.${nameof<SupplierDefaultSettings>(
                                'notesToClientPostBooking',
                            )}`}
                            control={control}
                        />
                    </Col>
                </Row>
                <Buttons.StickySaveButtonTabs>{Localization.getString('Save')}</Buttons.StickySaveButtonTabs>
            </form>
        </div>
    )
}
export default AdminDefaultSettings
