import * as moment from 'moment'

export default class NewBookingAgent {
    date?: moment.Moment = undefined
    arrivalId: number = 0
    hotelId: number = 0
    tourId: number = 0
    offerId: number = 0
    timeSlotId: number = 0
    adults: number = 1
    children: number = 0
    infants: number = 0
    guestName: string = ''
    roomNumber: string = ''
    paid: number = 0
    paidOrig: number = 0
    collect: number = 0
    collectOrig: number = 0
    voucherNumber: string = ''
    notes: string = ''
    phones: string = ''
    groupId: string = ''
    groupInfoId?: number = undefined
    startTime?: number = undefined
    endTime?: number = undefined
    validate() {
        const errors = new Map<string, string>()
        if (!this.date) {
            errors.set('Date', 'Date is required')
        }
        if (this.offerId == 0) {
            errors.set('OfferId', 'Offer is required')
        }
        if (this.adults == 0) {
            errors.set('Adults', 'At least one adult is required')
        }
        if (!this.guestName || this.guestName.length == 0) {
            errors.set('GuestName', 'Guest name is required')
        }
        return errors
    }
}
