import * as React from 'react'
import { Tooltip } from 'traveldesk-ui'
import { useSelector } from 'react-redux'
import { ApplicationState } from './../../store/index'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
const I = styled.i`
    color: orange;
`
const NoDefaultValue = () => {
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    return (
        <>
            Use defaults{' '}
            <Tooltip
                style={{ width: 300 }}
                richText={
                    <>
                        <p>{Localization.getString('No Defaults Warning')}</p>
                        <p>
                            {Localization.getString('Click')} {Localization.getString('to specify default value')}
                        </p>
                    </>
                }
            >
                <Link target="_blank" to="/admin/defaultsettings">
                    <I className="fa fa-warning" />
                </Link>
            </Tooltip>
        </>
    )
}
export default NoDefaultValue
