import Offer, { OfferManageTourModel, OfferTourManageTableModel } from '../models/Offer'
import { Reducer, Action } from 'redux'
import { AppThunkAction } from './'
import { LoadingStore } from 'traveldesk-ui'
import { TourManageModel, TourManageTableModel } from '../models/TourManageModel'
import { NotificationsStore } from 'traveldesk-ui'
import { push } from 'react-router-redux'
import ToursApiService from '../services/ToursApiService'
// import { SetAccountDefaultCommission } from './Account'
import OfferValidPeriod from './../models/OfferValidPeriod'
import ValidPeriodsApiService from '../services/ValidPeriodsService'
import OffersApiService from './../services/OffersApiService'
import { RouterAction } from 'connected-react-router'
import { NotificationType, NotificationModel } from 'traveldesk-ui'
import { AccountSettingsModel } from '../models/AccountSettingsModel'
export interface State {
    toursManage: TourManageTableModel[]
    tour?: TourManageModel
    offer?: Offer
    addValidPeriod: boolean
}
interface SetTourManageAction {
    type: 'A_SET_TOUR_MANAGE'
    payload: TourManageModel | undefined
}
interface SetOfferManageAction {
    type: 'A_SET_OFFER_MANAGE'
    payload: Offer | undefined
}
const RECEIVE_TOURS_MANAGE = 'A_RECEIVE_TOURS_MANAGE'

interface ReceiveToursManageAction {
    type: 'A_RECEIVE_TOURS_MANAGE'
    payload: TourManageTableModel[]
}
interface ReceiveToursAction {
    type: 'A_RECIEVE_TOURS'
    payload: TourManageModel[]
}
const TOUR_SAVED = 'A_TOUR_SAVED'
interface TourSaved {
    type: 'A_TOUR_SAVED'
    payload: TourManageModel
}
const OFFER_SAVED = 'A_OFFER_SAVED'
interface OfferSaved {
    type: 'A_OFFER_SAVED'
    payload: Offer
}
const VALID_PERIOD_SAVED = 'A_VALID_PERIOD_SAVED'
interface ValidPeriodSaved {
    type: 'A_VALID_PERIOD_SAVED'
    payload: OfferValidPeriod
}
const SET_ADD_VALID_PERIOD = 'A_SET_ADD_VALID_PERIOD'
interface SetAddValidPeriod {
    type: 'A_SET_ADD_VALID_PERIOD'
    payload: boolean
}
type KnownAction =
    | ReceiveToursAction
    | ReceiveToursManageAction
    | SetTourManageAction
    | SetOfferManageAction
    | TourSaved
    | OfferSaved
    // | SetAccountDefaultCommission
    | ValidPeriodSaved
    | SetAddValidPeriod
    | RouterAction

export const actionCreators = {
    manageTourClear: () => ({ type: 'A_SET_TOUR_MANAGE', payload: undefined }),
    manageOfferClear: () => ({ type: 'A_SET_OFFER_MANAGE', payload: undefined }),

    manageTour:
        (id: number, accountSettings: AccountSettingsModel): AppThunkAction<KnownAction | LoadingStore.KnownAction | NotificationsStore.KnownAction> =>
            async (dispatch, getState) => {
                if (id == 0) {
                    dispatch({ type: 'A_SET_TOUR_MANAGE', payload: TourManageModel.Create(new TourManageModel(), accountSettings) })
                } else {
                    const exTour = getState().tours.tour
                    if (exTour && exTour.id != id) {
                    }
                    dispatch(LoadingStore.actionCreators.incrementLoading())
                    let fetchTask = await ToursApiService.getTourForEdit(dispatch, id) //apiCallWrapper<TourModel>(`/api/tours/manage/${id}`, 'GET', dispatch, null)
                    const data = fetchTask.Content
                    if (fetchTask.IsOk && data) {
                        dispatch({ type: 'A_SET_TOUR_MANAGE', payload: TourManageModel.Create(data, accountSettings) })
                    } else {
                        const localization = getState().localization.locale
                        dispatch(
                            NotificationsStore.actionCreators.add(
                                new NotificationModel(
                                    NotificationType.error,
                                    localization.getString('Not found'),
                                    localization.getString('Tour not found or you are not allowed to manage it'),
                                ),
                            ),
                        )

                        dispatch(push('/tours'))
                    }
                    dispatch(LoadingStore.actionCreators.decrementLoading())
                }
            },

    manageOffer:
        (
            id: number,
            tourId: number,
            accountSettings: AccountSettingsModel
        ): AppThunkAction<KnownAction | LoadingStore.KnownAction | NotificationsStore.KnownAction> =>
            async (dispatch, getState) => {
                if (id == 0) {
                    dispatch({ type: 'A_SET_OFFER_MANAGE', payload: Offer.Create(new Offer(tourId), tourId, accountSettings) })
                } else {
                    const exTour = getState().tours.tour
                    if (exTour && exTour.id != id) {
                    }
                    dispatch(LoadingStore.actionCreators.incrementLoading())
                    let fetchTask = await OffersApiService.getOfferForEdit(dispatch, id)
                    const data = fetchTask.Content
                    if (fetchTask.IsOk && data) {
                        dispatch({ type: 'A_SET_OFFER_MANAGE', payload: Offer.Create(data, tourId, accountSettings) })
                    } else {
                        const localization = getState().localization.locale
                        dispatch(
                            NotificationsStore.actionCreators.add(
                                new NotificationModel(
                                    NotificationType.error,
                                    localization.getString('Not found'),
                                    localization.getString('Ticket not found or you are not allowed to manage it'),
                                ),
                            ),
                        )
                        dispatch(push(`/tours/${tourId}`))
                    }
                    dispatch(LoadingStore.actionCreators.decrementLoading())
                }
            },
    requestToursManage: (): AppThunkAction<KnownAction | LoadingStore.KnownAction> => async (dispatch) => {
        dispatch(LoadingStore.actionCreators.incrementLoading())
        let fetchTask = await ToursApiService.getToursManageTable(dispatch) // apiCallWrapper<TourManageTableModel[]>(`/api/tours/managelist`, 'GET', dispatch, null)
        const data = fetchTask.Content
        if (fetchTask.IsOk && data) {
            dispatch({ type: RECEIVE_TOURS_MANAGE, payload: data })
        }
        dispatch(LoadingStore.actionCreators.decrementLoading())
    },
    saveOffer:
        (offer: Offer): AppThunkAction<KnownAction | LoadingStore.KnownAction> =>
            async (dispatch) => {
                dispatch(LoadingStore.actionCreators.incrementLoading())
                let fetchTask = await OffersApiService.save(dispatch, offer)
                if (fetchTask.IsOk && fetchTask.Content) {
                    dispatch({ type: OFFER_SAVED, payload: fetchTask.Content })
                }
                if (fetchTask.Content) {
                    dispatch(push(`/tours/${fetchTask.Content.tourId}/${fetchTask.Content.id}`))
                }
                dispatch(LoadingStore.actionCreators.decrementLoading())
            },
    saveTour:
        (tour: TourManageModel): AppThunkAction<KnownAction | LoadingStore.KnownAction> =>
            async (dispatch) => {
                dispatch(LoadingStore.actionCreators.incrementLoading())
                tour.offers = []
                let fetchTask = await ToursApiService.save(dispatch, tour)
                if (fetchTask.IsOk && fetchTask.Content) {
                    dispatch({ type: TOUR_SAVED, payload: fetchTask.Content })
                    dispatch(push(`/tours/${fetchTask.Content.id}`))
                }
                dispatch(LoadingStore.actionCreators.decrementLoading())
            },
    saveOfferValidPeriod:
        (accountSettings: AccountSettingsModel, validPeriod: OfferValidPeriod, offerId: number): AppThunkAction<KnownAction | LoadingStore.KnownAction> =>
            async (dispatch, getState) => {
                dispatch(LoadingStore.actionCreators.incrementLoading())
                let fetchTask = await ValidPeriodsApiService.save(dispatch, validPeriod)
                if (fetchTask.IsOk && fetchTask.Content) {
                    const payload = OfferValidPeriod.Create(fetchTask.Content, accountSettings.totalTaxAmount, accountSettings.totalTaxK, accountSettings.currencyConversionSettings.convRate, accountSettings.currencyConversionSettings.convType, accountSettings.defaultCommission)
                    dispatch({ type: VALID_PERIOD_SAVED, payload })
                    const tour = getState().tours.toursManage.find((x) => x.offers.some((offer) => offer.id == offerId))
                    dispatch(push(`/tours/${tour?.id ?? 'add'}/${offerId}`))
                }
                dispatch(LoadingStore.actionCreators.decrementLoading())
            },
    setAddValidPeriod: (value: boolean) => ({ type: SET_ADD_VALID_PERIOD, payload: value }),
}
const unloadedState: State = {
    toursManage: [],
    tour: undefined,
    addValidPeriod: false,
    offer: undefined,
}
export const reducer: Reducer<State> = (state: State = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction
    switch (action.type) {
        case RECEIVE_TOURS_MANAGE:
            return {
                ...state,
                toursManage: action.payload,
            }
        case SET_ADD_VALID_PERIOD:
            return {
                ...state,
                addValidPeriod: action.payload,
            }
        // case 'A_SET_ACCOUNT_DEFAULT_COMMISSION':
        //     if (state.offer) {
        //         return { ...state, offer: Offer.Create(state.offer, action.payload) }
        //     }
        //     return state
        case 'A_SET_OFFER_MANAGE':
            return { ...state, offer: action.payload }
        case 'A_SET_TOUR_MANAGE':
            return { ...state, tour: action.payload }
        case VALID_PERIOD_SAVED:
            return {
                ...state,
                offer: Object.assign(new Offer(state.offer?.tourId ?? 0), state.offer, {
                    validPeriods: state.offer?.validPeriods.map((vp) =>
                        vp.id == action.payload.id ? action.payload : vp,
                    ),
                }),
                addValidPeriod: false,
            }
        default:
    }
    return state || unloadedState
}
