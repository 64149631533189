import { apiCallWrapper, apiCallWrapperNoDispatch } from 'traveldesk-ui'

import Hotel from './../models/Hotel'

export default class HotelsApiService {
    static getHotelsWithArrivalsByArrivals = async (dispatch: any) => {
        const res = await apiCallWrapper<Hotel[]>('/api/hotels/witharrivals', 'GET', dispatch)
        res.Content = res.Content?.map((x) => Hotel.Create(x))
        return res
    }
    static getHotelsInSuppliersCountry = async (dispatch: any) => {
        const res = await apiCallWrapper<Hotel[]>('/api/hotels/supplier', 'GET', dispatch)
        res.Content = res.Content?.map((x) => Hotel.Create(x))
        return res
    }
}
