import * as React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from '../store'
import { AuthStore } from 'traveldesk-ui'

interface Props extends RouteComponentProps<{ email: string }> {}
const LogMeInPage = (props: Props) => {
    const dispatch = useDispatch()
    const auth = useSelector((state: ApplicationState) => state.auth)
    dispatch(AuthStore.actionCreators.logMeIn(props.match.params.email))
    React.useEffect(() => {
        if (auth.authData?.email == props.match.params.email) {
            props.history.push('/')
        }
    }, [auth.authData?.email])
    return null
}
export default LogMeInPage
