import { Action, Reducer } from 'redux'
import { AppThunkAction } from '.'
import { apiCallWrapper, defaultLabelSort } from 'traveldesk-ui'
import { SelectOptionModel } from 'traveldesk-ui'
import ToursApiService from '../services/ToursApiService'
export interface State {
    options: SelectOptionModel[]
}
const RECEIVE_TOURS_CATEGORIES_OPTIONS = 'A_RECEIVE_TOURS_CATEGORIES_OPTIONS'
interface ReceiveToursCategoriesOptionsAction {
    type: 'A_RECEIVE_TOURS_CATEGORIES_OPTIONS'
    payload: SelectOptionModel[]
}

type KnownAction = ReceiveToursCategoriesOptionsAction

export const actionCreators = {
    requestOptions: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState()
        if (appState && appState.toursCategories && appState.toursCategories.options.length === 0) {
            const result = await ToursApiService.getCategoriesOptions(dispatch)
            if (result.IsOk) {
                dispatch({ type: RECEIVE_TOURS_CATEGORIES_OPTIONS, payload: result.Content || [] })
            }
        }
    },
}

const unloadedState: State = { options: [] as SelectOptionModel[] }

export const reducer: Reducer<State> = (state: State | undefined, incomingAction: Action): State => {
    if (state === undefined) {
        return unloadedState
    }

    const action = incomingAction as KnownAction
    switch (action.type) {
        case RECEIVE_TOURS_CATEGORIES_OPTIONS:
            return {
                ...state,
                options: action.payload.sort(defaultLabelSort),
            }
        default:
            return state
    }
}
