export default class NewBookingArrival {
    public static Create(obj: NewBookingArrival) {
        return Object.assign(new NewBookingArrival(), obj)
    }
    id: number = 0
    paxId: string = ''
    name: string = ''
    groupId: string = ''
    phoneNumbers: string = ''
    hotelId: number = 0
    groupInfoId?: number
}
