import moment from 'moment'
import { ConversionType, CurrencyConverter, guid, round } from 'traveldesk-ui'
import { dateFormatNoTime } from '../utils/dateFormatNoTime'
import { AccountSettingsModel } from './AccountSettingsModel'
export enum PricingPeriodModelPriceType {
    BaseCurrencyWithVat,
    BaseCurrency,
    SecCurrencyWithVat,
    SecCurrency
}
export const PricesTypes = [
    "Adult",
    "Child",
    "Trip"
]

export const CurrenciesFieldNames = [
    "BaseCurrency",
    "Usd"
]
class PricePer {
    recBaseCurWithVat: number = 0
    recBaseCur: number = 0
    recSecCurWithVat: number = 0
    recSecCur: number = 0
    netBaseCurWithVat?: number
    netBaseCur?: number
    netSecCurWithVat?: number
    netSecCur?: number
    dropNetPrices() {
        this.netBaseCurWithVat = undefined
        this.netBaseCur = undefined
        this.netSecCurWithVat = undefined
        this.netSecCur = undefined
    }
    updateNetPrices(comm?: number) {
        if (comm) {
            this.netBaseCurWithVat = this.recBaseCurWithVat - this.recBaseCurWithVat * comm / 100
            this.netBaseCur = this.recBaseCur - this.recBaseCur * comm / 100
            this.netSecCurWithVat = this.recSecCurWithVat - this.recSecCurWithVat * comm / 100
            this.netSecCur = this.recSecCur - this.recSecCur * comm / 100
        }
        else {
            this.netBaseCurWithVat = undefined
            this.netBaseCur = undefined
            this.netSecCurWithVat = undefined
            this.netSecCur = undefined
        }
    }
}
const PriceTypePostfixes = [
    "BaseCurrencyWithVat", "BaseCurrency", "UsdWithVat", "Usd"
]
export default class OfferValidPeriod {
    public static Create(obj: OfferValidPeriod, tax: number, taxK: number, convRate: number, convType: ConversionType, defaultCommission: number) {
        return Object.assign(new OfferValidPeriod(tax, taxK, convRate, convType, defaultCommission), obj, {
            periodStart: moment.utc(obj.periodStart),
            periodEnd: obj.periodEnd ? moment.utc(obj.periodEnd) : undefined
        })
    }
    public static CreateNew(accountSettings: AccountSettingsModel) {
        return Object.assign(new OfferValidPeriod(
            accountSettings.totalTaxAmount, accountSettings.totalTaxK,
            accountSettings.currencyConversionSettings.convRate, accountSettings.currencyConversionSettings.convType,
            accountSettings.defaultCommission))
    }

    id: number = 0
    offerId: number = 0
    minimumAdvancedPayment?: number
    periodStart: moment.Moment = moment.utc()
    periodEnd?: moment.Moment = moment.utc()
    //#region Rec Prices Base Currency
    pricePerAdultRecBaseCurrencyWithVat: number = 0
    pricePerAdultRecBaseCurrency: number = 0
    pricePerChildRecBaseCurrencyWithVat: number = 0
    pricePerChildRecBaseCurrency: number = 0
    pricePerTripRecBaseCurrencyWithVat: number = 0
    pricePerTripRecBaseCurrency: number = 0
    //#endregion
    //#region Rec Prices Sec Currency
    pricePerAdultRecUsdWithVat: number = 0
    pricePerAdultRecUsd: number = 0
    pricePerChildRecUsdWithVat: number = 0
    pricePerChildRecUsd: number = 0
    pricePerTripRecUsdWithVat: number = 0
    pricePerTripRecUsd: number = 0
    //#endregion
    defaultNetPrice(priceFor: string, priceType: PricingPeriodModelPriceType) {
        const priceTypePostfix = PriceTypePostfixes[priceType]
        const recPrice = this.getValueByFieldName<number>(`pricePer${priceFor}Rec${priceTypePostfix}`)
        return recPrice - (recPrice * this.defaultCommission / 100)
    }
    private getValueByFieldName<T>(fieldName: string) {
        return this[fieldName as keyof this] as unknown as T
    }
    private setValueByFieldName(fieldName: string, value: any) {
        this[fieldName as keyof this] = value as any
    }
    setPrices(priceFor: string, p: PricePer) {
        this.setValueByFieldName(`pricePer${priceFor}RecBaseCurrencyWithVat`, round(p.recBaseCurWithVat, 4))
        this.setValueByFieldName(`pricePer${priceFor}RecBaseCurrency`, round(p.recBaseCur, 4))
        this.setValueByFieldName(`pricePer${priceFor}RecUsdWithVat`, round(p.recSecCurWithVat, 4))
        this.setValueByFieldName(`pricePer${priceFor}RecUsd`, round(p.recSecCur, 4))
        this.setValueByFieldName(`netPricePer${priceFor}BaseCurrencyWithVat`, round(p.netBaseCurWithVat, 4))
        this.setValueByFieldName(`netPricePer${priceFor}BaseCurrency`, round(p.netBaseCur, 4))
        this.setValueByFieldName(`netPricePer${priceFor}UsdWithVat`, round(p.netSecCurWithVat, 4))
        this.setValueByFieldName(`netPricePer${priceFor}Usd`, round(p.netSecCur, 4))
    }
    setNetPrices(priceFor: string, p: PricePer) {
        this.setValueByFieldName(`netPricePer${priceFor}BaseCurrencyWithVat`, round(p.netBaseCurWithVat, 4))
        this.setValueByFieldName(`netPricePer${priceFor}BaseCurrency`, round(p.netBaseCur, 4))
        this.setValueByFieldName(`netPricePer${priceFor}UsdWithVat`, round(p.netSecCurWithVat, 4))
        this.setValueByFieldName(`netPricePer${priceFor}Usd`, round(p.netSecCur, 4))
        const recBaseCurWithVat = this.getValueByFieldName<number>(`pricePer${priceFor}RecBaseCurrencyWithVat`)
        if (p.netBaseCurWithVat) {
            const commAmmount = recBaseCurWithVat - p.netBaseCurWithVat
            const comm = (commAmmount / recBaseCurWithVat) * 100
            this.setValueByFieldName(`netCommissionPer${priceFor}`, round(comm, 4))
        }
        else {
            this.setValueByFieldName(`netCommissionPer${priceFor}`, undefined)
        }
    }
    setRecPriceBaseCurrencyWithVat(value: number, priceFor: string) {
        // Base cur with vat
        const p = new PricePer()
        p.recBaseCurWithVat = value
        p.recBaseCur = value / this.vatK
        p.recSecCurWithVat = CurrencyConverter.convert(value, this.convRate, this.convType)
        p.recSecCur = CurrencyConverter.convert(p.recBaseCur, this.convRate, this.convType)
        const comm = this.getValueByFieldName<number | undefined>(`netCommissionPer${priceFor}`)
        p.updateNetPrices(comm)
        this.setPrices(priceFor, p)
    }
    setRecPriceBaseCurrency(valueNoVat: number, priceFor: string) {
        // Base cur
        const p = new PricePer()
        p.recBaseCurWithVat = valueNoVat * this.vatK
        p.recBaseCur = valueNoVat
        p.recSecCur = CurrencyConverter.convert(valueNoVat, this.convRate, this.convType)
        p.recSecCurWithVat = p.recSecCur * this.vatK
        const comm = this.getValueByFieldName<number | undefined>(`netCommissionPer${priceFor}`)
        p.updateNetPrices(comm)
        this.setPrices(priceFor, p)
    }



    setRecPriceSecCurrencyWithVat(value: number, priceFor: string) {
        // Sec Cur With Vat
        const p = new PricePer()
        p.recSecCurWithVat = value
        p.recSecCur = value / this.vatK
        p.recBaseCurWithVat = CurrencyConverter.convertBack(value, this.convRate, this.convType)
        p.recBaseCur = CurrencyConverter.convertBack(p.recSecCur, this.convRate, this.convType)
        const comm = this.getValueByFieldName<number | undefined>(`netCommissionPer${priceFor}`)
        p.updateNetPrices(comm)
        this.setPrices(priceFor, p)
    }

    setRecPriceSecCurrency(valueNoVat: number, priceFor: string) {
        // Sec Cur 
        const p = new PricePer()
        p.recSecCur = valueNoVat
        p.recSecCurWithVat = valueNoVat * this.vatK
        p.recBaseCurWithVat = CurrencyConverter.convertBack(p.recSecCurWithVat, this.convRate, this.convType)
        p.recBaseCur = CurrencyConverter.convertBack(valueNoVat, this.convRate, this.convType)
        const comm = this.getValueByFieldName<number | undefined>(`netCommissionPer${priceFor}`)
        p.updateNetPrices(comm)
        this.setPrices(priceFor, p)
    }

    setCommission(comm: number | undefined, priceFor: string) {
        if (comm) {
            const recPriceBaseCurWithVat = this.getValueByFieldName<number>(`pricePer${priceFor}RecBaseCurrencyWithVat`)
            const recPriceBaseCur = this.getValueByFieldName<number>(`pricePer${priceFor}RecBaseCurrency`)
            const recPriceSecCurWithVat = this.getValueByFieldName<number>(`pricePer${priceFor}RecUsdWithVat`)
            const recPriceSecCur = this.getValueByFieldName<number>(`pricePer${priceFor}RecUsd`)
            const netPriceBaseCurWithVat = recPriceBaseCurWithVat - recPriceBaseCurWithVat * comm / 100
            const netPriceBaseCur = recPriceBaseCur - recPriceBaseCur * comm / 100
            const netPriceSecCurWithVat = recPriceSecCurWithVat - recPriceSecCurWithVat * comm / 100
            const netPriceSecCur = recPriceSecCur - recPriceSecCur * comm / 100
            this.setValueByFieldName(`netCommissionPer${priceFor}`, comm)
            this.setValueByFieldName(`netPricePer${priceFor}BaseCurrencyWithVat`, round(netPriceBaseCurWithVat, 4))
            this.setValueByFieldName(`netPricePer${priceFor}BaseCurrency`, round(netPriceBaseCur, 4))
            this.setValueByFieldName(`netPricePer${priceFor}UsdWithVat`, round(netPriceSecCurWithVat, 4))
            this.setValueByFieldName(`netPricePer${priceFor}Usd`, round(netPriceSecCur, 4))
        }
        else {
            this.setValueByFieldName(`netCommissionPer${priceFor}`, undefined)
            this.setValueByFieldName(`netPricePer${priceFor}BaseCurrencyWithVat`, undefined)
            this.setValueByFieldName(`netPricePer${priceFor}BaseCurrency`, undefined)
            this.setValueByFieldName(`netPricePer${priceFor}UsdWithVat`, undefined)
            this.setValueByFieldName(`netPricePer${priceFor}Usd`, undefined)
        }
    }

    netCommissionPerAdult?: number
    netCommissionPerChild?: number
    netCommissionPerTrip?: number
    netPricePerAdultUsd?: number
    netPricePerAdultUsdWithVat?: number
    netPricePerChildUsd?: number
    netPricePerChildUsdWithVat?: number
    netPricePerTripUsd?: number
    netPricePerTripUsdWithVat?: number
    netPricePerAdultBaseCurrency?: number
    netPricePerAdultBaseCurrencyWithVat?: number
    netPricePerChildBaseCurrency?: number
    netPricePerChildBaseCurrencyWithVat?: number
    netPricePerTripBaseCurrency?: number
    netPricePerTripBaseCurrencyWithVat?: number

    setNetPriceBaseCurWithVat(value: number | undefined, priceFor: string) {
        const p = new PricePer()
        if (value) {
            p.netBaseCurWithVat = value
            p.netBaseCur = value / this.vatK
            p.netSecCurWithVat = CurrencyConverter.convertBack(value, this.convRate, this.convType)
            p.netSecCur = CurrencyConverter.convertBack(p.recSecCur, this.convRate, this.convType)
        }
        else {
            p.dropNetPrices()
        }
        this.setNetPrices(priceFor, p)

    }
    setNetPriceBaseCur(valueNoVat: number | undefined, priceFor: string) {
        const p = new PricePer()
        if (valueNoVat) {
            p.netBaseCurWithVat = valueNoVat * this.vatK
            p.netBaseCur = valueNoVat
            p.netSecCur = CurrencyConverter.convertBack(valueNoVat, this.convRate, this.convType)
            p.netSecCurWithVat = p.netSecCur * this.vatK
        }
        else {
            p.dropNetPrices()
        }
        this.setNetPrices(priceFor, p)
    }
    setNetPriceSecCurWithVat(value: number | undefined, priceFor: string) {
        const p = new PricePer()
        if (value) {
            p.netSecCurWithVat = value
            p.netSecCur = value / this.vatK
            p.netBaseCurWithVat = CurrencyConverter.convertBack(value, this.convRate, this.convType)
            p.netBaseCur = CurrencyConverter.convertBack(p.netSecCur, this.convRate, this.convType)
        }
        else {
            p.dropNetPrices();
        }
        this.setNetPrices(priceFor, p)
    }
    setNetPriceSecCur(valueNoVat: number | undefined, priceFor: string) {
        const p = new PricePer()
        if (valueNoVat) {
            p.netSecCur = valueNoVat
            p.netSecCurWithVat = valueNoVat * this.vatK
            p.netBaseCurWithVat = CurrencyConverter.convertBack(p.netSecCurWithVat, this.convRate, this.convType)
            p.netBaseCur = CurrencyConverter.convertBack(valueNoVat, this.convRate, this.convType)
        }
        else {
            p.dropNetPrices()
        }
        this.setNetPrices(priceFor, p)
    }


    guid: string
    vat: number
    vatK: number
    convRate: number
    convType: ConversionType
    defaultCommission: number
    constructor(vat: number, vatK: number, convRate: number, convType: ConversionType, defaultCommission: number) {
        const d = new Date();
        this.id = 0;
        this.vat = vat
        this.vatK = vatK
        this.convRate = convRate
        this.convType = convType
        this.defaultCommission = defaultCommission;
        this.periodStart = moment(new Date(d.getFullYear(), d.getMonth(), d.getDate(), 12, 0, 0));
        this.periodEnd = moment(new Date(d.getFullYear(), d.getMonth() + 1, d.getDate(), 12, 0, 0));
        this.guid = guid();
    }
    toString() {
        return `${this.periodStart.format(dateFormatNoTime)}-${this.periodEnd?.format(dateFormatNoTime)}`
    }
}
