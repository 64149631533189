import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import Hotel from '../../models/Hotel'
import moment from 'moment'
import {
    DataTableColumnModel,
    FilterType,
    DataTableModel,
    DataTable,
    FilterValueType,
    FilterValuesWrapper,
    DataTableModelCustomAction,
    CheckBox,
} from 'traveldesk-ui'
import { BookingStatusOptions, BookingStatusDescription } from '../../models/BookingOperations'
import BookingAgent from './Models/BookingAgent'
import OfferAgent from './Models/OfferAgent'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import BookingsRequest from './Models/BookingsRequest'
import * as AgencyCommissionsState from '../../store/AgencyCommissions'
import { PaginationModel } from 'traveldesk-ui'
import IdNameModel from '../../models/IdNameModel'
import { Link } from 'react-router-dom'
interface Props extends RouteComponentProps { }
const Commissions = (props: Props) => {
    const dispatch = useDispatch()
    const commissions = useSelector((state: ApplicationState) => state.agencyCommissions.data)
    const agents = useSelector((state: ApplicationState) => state.agencyCommissions.agents)
    const agentsHash = useSelector((state: ApplicationState) => state.agencyCommissions.agentsHash)
    const offers = useSelector((state: ApplicationState) => state.agencyCommissions.offers)
    const offersHash = useSelector((state: ApplicationState) => state.agencyCommissions.offersHash)
    const hotels = useSelector((state: ApplicationState) => state.agencyCommissions.hotels)
    const hotelsHash = useSelector((state: ApplicationState) => state.agencyCommissions.hotelsHash)
    React.useEffect(() => {
        dispatch(AgencyCommissionsState.actionCreators.requestAgencyAgents())
        dispatch(AgencyCommissionsState.actionCreators.requestAgencyOffers())
        dispatch(AgencyCommissionsState.actionCreators.requestAgencyHotels())
    }, [])
    const initFilter = () => {
        return new Map<string, FilterValueType>()
    }
    const [filter, setFilter] = React.useState(initFilter())
    const [request, setRequest] = React.useState(new BookingsRequest())
    const getData = () => {
        dispatch(AgencyCommissionsState.actionCreators.requestAgencyCommissions(request))
    }
    React.useEffect(() => {
        getData()
    }, [request])
    const initDataTable = (
        offers: OfferAgent[],
        offersHash: Map<number, OfferAgent>,
        hotels: Hotel[],
        hotelsHash: Map<number, Hotel>,
        agents: IdNameModel[],
        agentsHash: Map<number, IdNameModel>,
    ) => {
        const dataTableColumns: DataTableColumnModel[] = [
            new DataTableColumnModel({
                displayName: 'Id',
                fieldName: 'id',
                filterType: FilterType.TEXTNUMBER,
            }),
            new DataTableColumnModel({
                displayName: 'Date',
                fieldName: 'date',
                width: 80,
                filterType: FilterType.DATERANGE,
                sortable: true,
            }),
            new DataTableColumnModel({
                displayName: 'Offer',
                fieldName: 'offer',
                width: 200,
                filterType: FilterType.MULTISELECT,
                customRenderer: (obj: BookingAgent) => {
                    const h = offersHash.get(obj.offerId)
                    if (h) {
                        return h.name
                    }
                    return null
                },
                customExcelRenderer: (obj: BookingAgent) => {
                    const h = offersHash.get(obj.offerId)
                    if (h) {
                        return h.name
                    }
                    return ''
                },
                filterOptions: offers.map((x) => ({ value: x.id, label: x.name })),
                sortable: true,
            }),
            new DataTableColumnModel({
                displayName: 'Hotel',
                fieldName: 'hotel',
                width: 140,
                filterType: FilterType.MULTISELECT,
                filterOptions: hotels.map((x) => ({
                    value: x.id.toString(),
                    label: `${x.name} (${x.cityId}, ${x.regionId || ''})`,
                })),
                customRenderer: (obj: BookingAgent) => {
                    const h = hotelsHash.get(obj.hotelId)
                    if (h) {
                        return (
                            h && (
                                <div>
                                    {h.name}
                                    <br />
                                    <span style={{ fontSize: 8, color: '#aaa' }}>
                                        {h.cityName}, {h.regionName}
                                    </span>
                                </div>
                            )
                        )
                    }
                    return null
                },
                customExcelRenderer: (obj: BookingAgent) => {
                    const h = hotelsHash.get(obj.hotelId)
                    if (h) {
                        return h.name
                    }
                    return ''
                },
                sortable: true,
            }),
            new DataTableColumnModel({
                displayName: 'Room #',
                fieldName: 'roomNumber',
                width: 80,
                filterType: FilterType.TEXT,
                sortable: true,
            }),
            new DataTableColumnModel({
                displayName: 'Guest Name',
                fieldName: 'guestName',
                width: 120,
                filterType: FilterType.TEXT,
                sortable: true,
            }),
            new DataTableColumnModel({
                displayName: 'Tel',
                fieldName: 'tel',
                width: 80,
                filterType: FilterType.TEXT,
                sortable: true,
            }),
            new DataTableColumnModel({
                displayName: 'Ad',
                fieldName: 'numAdults',
                width: 60,
                sortable: true,
            }),
            new DataTableColumnModel({
                displayName: 'Ch',
                fieldName: 'numChildren',
                width: 60,
                sortable: true,
            }),
            new DataTableColumnModel({
                displayName: 'Inf',
                fieldName: 'numInfants',
                width: 60,
                sortable: true,
            }),
            new DataTableColumnModel({
                displayName: 'FOC',
                fieldName: 'numFoc',
                width: 60,
                sortable: true,
            }),
            new DataTableColumnModel({
                displayName: 'Agent',
                fieldName: 'agent',
                sortable: true,
                filterType: FilterType.MULTISELECT,
                filterOptions: agents.map((x) => ({
                    value: x.id.toString(),
                    label: x.name,
                })),
                customRenderer: (obj: BookingAgent) => {
                    return agentsHash.get(obj.agentId)?.name ?? agentsHash.get(-obj.staffAgentId)?.name ?? ''
                },
                customExcelRenderer: (obj: BookingAgent) => {
                    return agentsHash.get(obj.agentId)?.name ?? agentsHash.get(-obj.staffAgentId)?.name ?? ''
                }
            }),
            new DataTableColumnModel({
                displayName: 'Voucher',
                fieldName: 'voucher',
                sortable: true,
                filterType: FilterType.TEXT,
            }),
            new DataTableColumnModel({
                displayName: 'Notes',
                fieldName: 'notes',
                sortable: true,
                filterType: FilterType.TEXT,
            }),
            new DataTableColumnModel({
                displayName: 'Gross Sales',
                fieldName: 'grossSales',
                customRenderer: (obj: BookingAgent) => obj.grossSales.toFixed(2),
                sortable: true,
            }),
            new DataTableColumnModel({
                displayName: 'Agency Receivable',
                fieldName: 'agencyReceivable',
                customRenderer: (obj: BookingAgent) => obj.agencyReceivable.toFixed(2),
            }),
            new DataTableColumnModel({
                displayName: 'Status',
                fieldName: 'Status',
                width: 80,
                filterType: FilterType.MULTISELECT,
                filterOptions: BookingStatusOptions,
                customRenderer: (obj: BookingAgent) => BookingStatusDescription[obj.status],
                customExcelRenderer: (obj: BookingAgent) => BookingStatusDescription[obj.status],
            }),
        ]
        return new DataTableModel({
            columns: dataTableColumns,
            name: 'Commissions',
            fileName: 'commissions.xlsx',
            customActions: [
                new DataTableModelCustomAction(
                    () => { },
                    () => (
                        <Link to="/agent/bookings/add">
                            <i className="fa fa-plus" />
                        </Link>
                    ),
                    'Add new booking',
                ),
            ],
        })
    }
    const onClearFilter = () => {
        setRequest(Object.assign(new BookingsRequest()))
        setFilter(initFilter())
    }
    const onPageChanged = (page: number) => {
        setRequest(Object.assign(new BookingsRequest(), request, { page }))
    }
    const onRefresh = () => {
        getData()
        // dispatch(AgencyCommissionsState.actionCreators.requestAgencyCommissions(request))
    }
    const onSortChanged = (sortField: string, sortType: string) => {
        const r = Object.assign(new BookingsRequest(new FilterValuesWrapper(filter)), {
            sortField,
            sortType,
        })
        console.log(r)
        setRequest(r)
    }
    const onFilterValueChange = (fieldName: string, filterValue: FilterValueType) => {
        filter.set(fieldName, filterValue)
        const newFilter = new Map<string, FilterValueType>(filter)
        setFilter(newFilter)
        setRequest(new BookingsRequest(new FilterValuesWrapper(filter)))
    }
    const dataTableModel = React.useMemo(() => {
        const res = initDataTable(offers, offersHash, hotels, hotelsHash, agents, agentsHash)
        res.setFilter(filter)
        return res
    }, [offers, hotels, , agents, filter])
    return (
        <DataTable
            isFullScreen
            sortField={request.sortField}
            sortType={request.sortType}
            data={commissions?.entities ?? []}
            pagingInfo={commissions?.pagingInfo ?? new PaginationModel()}
            dataTable={dataTableModel}
            onSortChanged={onSortChanged}
            onFilterValueChange={onFilterValueChange}
            onClearFilter={onClearFilter}
            onRefreshData={onRefresh}
            onPageChange={onPageChanged}
        />
    )
}
export default Commissions
