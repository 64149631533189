import { apiCallWrapper } from 'traveldesk-ui'
import { Supplier } from '../models/Supplier'

export default class SupplierApiService {
    static getMy = async (dispatch: any) => {
        return await apiCallWrapper<Supplier>('/api/suppliers/my', 'GET', dispatch)
    }
    static update = async (dispatch: any, model: Supplier) => {
        return await apiCallWrapper<Supplier>('/api/suppliers', 'PUT', dispatch, model)
    }
}
