import * as React from 'react'
import CancellationPolicy from './../../models/CancellationPolicy'
import { useSelector } from 'react-redux'
import { ApplicationState } from './../../store/index'
import { FormFieldWrapper, FieldFooter, Tooltip } from 'traveldesk-ui'
import { Buttons } from 'traveldesk-ui'
import { DataTableColumnModel, InputType, DataTableModel, SetEditCallbackFunction, DataTable } from 'traveldesk-ui'
interface Props {
    onChange: (cancellations: CancellationPolicy[]) => void
    cancellations: CancellationPolicy[]
}
const Cancellations = (props: Props) => {
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const checkDuplicates = (c: CancellationPolicy[]) => {
        const hours = new Map<number, number>()
        c.forEach((x) => {
            if (hours.has(x.hours)) {
                return true
            }
        })
        return false
    }
    const onEdit = (prev: CancellationPolicy, next: CancellationPolicy) => {
        const cancellations = props.cancellations.map((c) => (c == prev ? next : c))
        if (!checkDuplicates(cancellations)) {
            props.onChange(cancellations)
        }
    }
    const onDelete = (c: CancellationPolicy) => {
        props.onChange(props.cancellations.filter((x) => x != c))
    }
    const initDataTable = () => {
        const columns = [
            new DataTableColumnModel({
                displayName: Localization.getString('Hours before tour'),
                fieldName: 'hours',
                sortable: true,
                isEditable: true,
                editType: InputType.Number,
                editStep: 1,
            }),
            new DataTableColumnModel({
                displayName: Localization.getString('Refund percent'),
                fieldName: 'percents',
                isEditable: true,
                editType: InputType.Number,
                editStep: 1,
            }),
        ]
        return new DataTableModel({
            columns,
            name: Localization.getString('Cancellation Policy'),
        })
    }
    const onAdd = () => {
        const c = CancellationPolicy.Create(0, 0)
        props.onChange(props.cancellations.concat(c))
        console.log('Add c')
        if (setEditCallback) {
            setEditCallback(c)
        }
    }
    const [setEditCallback, setSetEditCallback] = React.useState(
        () => (undefined as unknown) as SetEditCallbackFunction | undefined,
    )
    const isData = props.cancellations && props.cancellations.length > 0
    return (
        <FormFieldWrapper>
            <label>{Localization.getString('Cancellation Policy')}</label>
            <DataTable
                data={props.cancellations}
                hideClearFilter
                hideToolbar
                isHidden={!isData}
                sortField="hours"
                sortType="ASC"
                embededScreen
                setEditFromParentCallback={(callback) => setSetEditCallback(() => callback)}
                dataTable={initDataTable()}
                onEdit={onEdit}
                onDelete={onDelete}
                fixedHeight={150}
            />
            {!isData && [
                <FieldFooter
                    type="warning"
                    key={'ff'}
                    error={
                        <>
                            <i className="fa fa-warning" />
                            {Localization.getString(
                                'Warning: Cancellation policy is not specified. Tour is not refundable',
                            )}
                        </>
                    }
                />,
                <br key={'br'} />,
            ]}
            <Tooltip text={Localization.getString('Add cancellation policy option')}>
                <Buttons.ButtonSmallGreen onClick={onAdd}>
                    <i className="fa fa-plus" />
                </Buttons.ButtonSmallGreen>
            </Tooltip>
        </FormFieldWrapper>
    )
}
export default Cancellations
