import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
interface Props extends RouteComponentProps<{ tab: string }> {}
const AdminStaff = (props: Props) => {
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    return (
        <div className="tab-content-block is-active" data-tab="tab-v01">
            <h2>Admin Staff</h2>
            <p>
                Our experienced captain awaits you on board of a comfortable motor yacht, which has a convenient cabin
                with a bathroom, seating area in the front and the back of the boat.Cruising time- about 2 hours
            </p>
        </div>
    )
}
export default AdminStaff
