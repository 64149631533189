import * as React from 'react'
import ItineraryModel from '../../models/ItineraryModel'
import { useSelector } from 'react-redux'
import { Buttons, Col, Container, FieldFooter, FormFieldWrapper, Grid, IdFormField, Row, TextAreaField, TextFormField, TimePickerFormFieldController, Tooltip, indexDown, indexUp, minutesToTimeString, nameof, timeStringToMinutes } from 'traveldesk-ui'
import { ApplicationState } from 'traveldesk-ui/dist/store'
import { Controller, useForm } from 'react-hook-form'
import ItineraryStopModel from '../../models/ItineraryStopModel'
import { MapsLocationPicker } from 'traveldesk-ui'
import { MapsLocation } from 'traveldesk-ui'
import { GLOBAL_SETTINGS } from '../..'
interface Props {
    itinerary: ItineraryModel
    onChange: (itinerary: ItineraryModel) => void
}
const ItineraryDesigner = (props: Props) => {
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)

    const itinerary = props.itinerary
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        getValues,
        setValue,
        watch,
        reset,
        setError,
    } = useForm()
    const onSubmit = (data: any) => {
        props.onChange(data)
    }
    const getStopsFieldsNames = (index: number) => {
        return {
            index: `${nameof<ItineraryModel>('stops')}[${index}].${nameof<ItineraryStopModel>('index')}`,
            name: `${nameof<ItineraryModel>('stops')}[${index}].${nameof<ItineraryStopModel>('name')}`,
            description: `${nameof<ItineraryModel>('stops')}[${index}].${nameof<ItineraryStopModel>('description')}`,
            timeDescription: `${nameof<ItineraryModel>('stops')}[${index}].${nameof<ItineraryStopModel>('timeDescription')}`,
            timeEndDescription: `${nameof<ItineraryModel>('stops')}[${index}].${nameof<ItineraryStopModel>('timeEndDescription')}`,
            address: `${nameof<ItineraryModel>('stops')}[${index}].${nameof<ItineraryStopModel>('address')}`,
            lat: `${nameof<ItineraryModel>('stops')}[${index}].${nameof<ItineraryStopModel>('lat')}`,
            lng: `${nameof<ItineraryModel>('stops')}[${index}].${nameof<ItineraryStopModel>('lng')}`,

        }
    }
    const stops = watch('stops') as ItineraryStopModel[]
    const onAddStop = () => {
        const data = { ...getValues() } as ItineraryModel
        if (!data.stops) data.stops = []
        data.stops.push(new ItineraryStopModel(data.stops.length + 1, data.id))
        reset(data)
    }
    const onDeleteStop = (index: number) => {
        const data = { ...getValues() } as ItineraryModel
        data.stops = data.stops.filter(x => x.index != index).map(x => x.index > index ? {
            ...x,
            timeDescription: x.timeDescription, timeEndDescription: x.timeEndDescription, index: index - 1
        } : x)
        reset(data)
    }
    const onStopUp = (index: number) => {
        const data = { ...getValues() } as ItineraryModel
        data.stops = indexUp(data.stops, index)
        reset(data)
    }
    const onStopDown = (index: number) => {
        const data = { ...getValues() } as ItineraryModel
        data.stops = indexDown(data.stops, index)
        reset(data)
    }
    React.useEffect(() => {
        if (props.itinerary != null && props.itinerary.stops.length == 0) {
            onAddStop()
        }
    }, [])
    React.useEffect(() => {
        reset(props.itinerary)
    }, [props.itinerary])
    return <Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col type="100">
                    <IdFormField control={control} />
                    <TextFormField
                        label={Localization.getString('Itinerary Name')}
                        isRequired
                        error={errors.name ? errors.name.message : ''}
                        defaultValue={itinerary.name}
                        name={nameof<ItineraryModel>('name')}
                        register={register}
                        options={{ required: Localization.getString('Itinerary name is required') }}
                    />
                </Col>
            </Row>
            <hr />
            <Container style={{ padding: '50px' }}>
                <h3>{Localization.getString('Itinerary stops')}:</h3>
                <Tooltip text={Localization.getString('Add stop')}>
                    <Buttons.ButtonSmallGreen onClick={onAddStop}>
                        <i className="fa fa-plus" />
                    </Buttons.ButtonSmallGreen>
                </Tooltip>

                {stops && stops.length > 0 ? (
                    stops.map((stop, index) => {
                        const fNames = getStopsFieldsNames(index)
                        return [
                            <Row key={`label-${stop.index}`}>
                                <Col type='100'>
                                    <label>Itinerary Stop #{stop.index}
                                        <i className='fa fa-trash float-right clickable' onClick={() => onDeleteStop(index)} />
                                        {index > 0 && <i className='fa fa-arrow-up float-right clickable' onClick={() => onStopUp(itinerary.index)} />}
                                        {index < stops.length - 1 && <i className='fa fa-arrow-down float-right clickable' onClick={() => onStopDown(itinerary.index)} />}
                                    </label>
                                </Col>
                            </Row>,
                            <Row key={`${stop.index}.${index}`} id={`stop-${stop.index}`}>
                                <Col type="50">
                                    <Controller
                                        name={fNames.index}
                                        defaultValue={stops[index].index}
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                hidden
                                                value={stops[index].index}
                                                name="index"
                                                type="number"
                                                onChange={([e]: any) => parseInt(e.target.value, 10)}
                                            />
                                        )}
                                    />
                                    <TextFormField
                                        label={Localization.getString('Itinerary Stop Name')}
                                        isRequired
                                        error={
                                            errors.stops && errors.stops.length > 0 && !stop.name
                                                ? Localization.getString('itinerary stop name is required')
                                                : undefined
                                        }
                                        defaultValue={stops[index].name}
                                        name={fNames.name}
                                        register={register}
                                        options={{
                                            required: Localization.getString('itinerary stop name is required'),
                                        }}
                                    />
                                    <TextAreaField
                                        name={fNames.description}
                                        defaultValue={stop.description}
                                        label={`${Localization.getString("Description")}`}
                                        register={register}
                                    />
                                    <TimePickerFormFieldController
                                        name={fNames.timeDescription}
                                        defaultValue={stop.timeDescription}
                                        label={`${Localization.getString('Start Time')}`}
                                        error={
                                            errors[fNames.timeDescription]
                                                ? errors[fNames.timeDescription].message
                                                : ''
                                        }
                                        id={fNames.timeDescription}
                                        control={control}

                                    />
                                    <TimePickerFormFieldController
                                        name={fNames.timeEndDescription}
                                        defaultValue={stop.timeEndDescription}
                                        label={`${Localization.getString('End Time')}`}
                                        error={
                                            errors[fNames.timeEndDescription]
                                                ? errors[fNames.timeEndDescription].message
                                                : ''
                                        }
                                        id={fNames.timeEndDescription}
                                        control={control}

                                    />
                                    <TextFormField
                                        label={Localization.getString('Stop lat')}
                                        defaultValue={stops[index].lat}
                                        name={fNames.lat}
                                        register={register}

                                    />
                                    <TextFormField
                                        label={Localization.getString('Stop lng')}
                                        defaultValue={stops[index].lng}
                                        name={fNames.lng}
                                        register={register}

                                    />
                                </Col>
                                <Col type="50">
                                    <Controller
                                        defaultValue={new MapsLocation("", stop.lat, stop.lng)}
                                        render={({ field }) => {
                                            const values = getValues()
                                            return <MapsLocationPicker
                                                apiKey={GLOBAL_SETTINGS.GOOGLE_MAPS_API_KEY}
                                                onChange={(value: MapsLocation) => {
                                                    field.onChange(value.address)
                                                    setValue(fNames.lat, value.lat)
                                                    setValue(fNames.lng, value.lng)
                                                }}
                                                value={new MapsLocation(values[fNames.address], values[fNames.lat], values[fNames.lng])}
                                            />
                                        }}
                                        name={fNames.address}
                                        control={control}
                                    />
                                </Col>
                            </Row>,
                            <Row key={`${stop.index}.${index}.hr`}>
                                <Col type="100">
                                    <hr />
                                </Col>
                            </Row>,
                        ]
                    })
                ) : (
                    <FieldFooter
                        type="error"
                        error={
                            <>
                                <i className="fa fa-warning" />
                                {Localization.getString('Warning: Please add at least on stop')}
                            </>
                        }
                    />
                )}
                {stops && stops.length > 0
                    ? <Tooltip text={Localization.getString('Add stop')}>
                        <Buttons.ButtonSmallGreen onClick={onAddStop}>
                            <i className="fa fa-plus" />
                        </Buttons.ButtonSmallGreen>
                    </Tooltip>
                    : null}
            </Container>
            <Buttons.StickySaveButton>{Localization.getString('Save')}</Buttons.StickySaveButton>
        </form>
    </Grid>
}
export default ItineraryDesigner