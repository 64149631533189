import { Supplier } from './../models/Supplier'
import { Reducer, Action } from 'redux'
import { AppThunkAction } from '.'
import { LoadingStore } from 'traveldesk-ui'

import { NotificationsStore } from 'traveldesk-ui'
import SupplierApiService from './../services/SupplierApiService'

export interface State {
    supplier?: Supplier
}

interface SetSupplier {
    type: 'A_SET_SUPPLIER'
    payload: Supplier
}

type KnownAction = SetSupplier | LoadingStore.KnownAction | NotificationsStore.KnownAction

export const actionCreators = {
    updateSupplier:
        (supplier: Supplier | undefined, updates: Supplier): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            const model = Object.assign(Supplier.Create(supplier || new Supplier()), updates)
            dispatch(LoadingStore.actionCreators.incrementLoading())
            let fetchTask = await SupplierApiService.update(dispatch, model) //apiCallWrapper<TourModel>(`/api/tours/manage/${id}`, 'GET', dispatch, null)
            const data = fetchTask.Content
            if (fetchTask.IsOk && data) {
                dispatch({ type: 'A_SET_SUPPLIER', payload: Supplier.Create(data) })
            }
            dispatch(LoadingStore.actionCreators.decrementLoading())
        },
    requestSupplier: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch(LoadingStore.actionCreators.incrementLoading())
        let fetchTask = await SupplierApiService.getMy(dispatch) //apiCallWrapper<TourModel>(`/api/tours/manage/${id}`, 'GET', dispatch, null)
        const data = fetchTask.Content
        if (fetchTask.IsOk && data) {
            dispatch({ type: 'A_SET_SUPPLIER', payload: Supplier.Create(data) })
        }
        dispatch(LoadingStore.actionCreators.decrementLoading())
    },
}

const unloadedState: State = {}

export const reducer: Reducer<State> = (state: State = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction
    switch (action.type) {
        case 'A_SET_SUPPLIER':
            return {
                ...state,
                supplier: action.payload,
            }
    }
    return state || unloadedState
}
