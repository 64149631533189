import { ConversionType, LoadingStore } from 'traveldesk-ui'
import { NotificationsStore } from 'traveldesk-ui'
import { AppThunkAction } from '.'
import { Action, Reducer } from 'redux'
import AccountApiService from './../services/AccountApiService'
import { AccountSettingsModel, AccountTaxModel } from '../models/AccountSettingsModel'

export interface State {
    // defaultCommission?: number
    settings: AccountSettingsModel
}
export interface SetAccountSettings {
    type: 'A_SET_ACCOUNT_SETTINGS'
    payload: AccountSettingsModel
}

type KnownAction = SetAccountSettings

export const actionCreators = {
    getAccountSettings:
        (): AppThunkAction<KnownAction | LoadingStore.KnownAction | NotificationsStore.KnownAction> =>
            async (dispatch) => {
                dispatch(LoadingStore.actionCreators.incrementLoading())
                let fetchTask = await AccountApiService.getAccountSettings(dispatch)
                if (fetchTask.IsOk && fetchTask.Content) {
                    dispatch({ type: 'A_SET_ACCOUNT_SETTINGS', payload: fetchTask.Content })
                }
                dispatch(LoadingStore.actionCreators.decrementLoading())
            },
    // getDefaultCommission:
    //     (): AppThunkAction<KnownAction | LoadingStore.KnownAction | NotificationsStore.KnownAction> =>
    //         async (dispatch) => {
    //             dispatch(LoadingStore.actionCreators.incrementLoading())
    //             let fetchTask = await AccountApiService.getDefaultCommission(dispatch)
    //             if (fetchTask.IsOk && fetchTask.Content) {
    //                 dispatch({ type: 'A_SET_ACCOUNT_SETTINGS', payload: fetchTask.Content })
    //             }
    //             dispatch(LoadingStore.actionCreators.decrementLoading())
    //         },
}
const unloadedState: State = {
    // defaultCommission: undefined,
    settings: Object.assign(new AccountSettingsModel(), {
        taxes: [
            AccountTaxModel.CreateConstructor("vat", 5, 1.05, 3.65, ConversionType.DEV)
        ],
        defaultCommission: 20,
        currencyConversionSettings: Object.assign(new AccountSettingsModel(), { convRate: 3.65, convType: ConversionType.DEV })
    })
}
export const reducer: Reducer<State> = (state: State = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction
    switch (action.type) {
        case 'A_SET_ACCOUNT_SETTINGS':
            return {
                ...state,
                settings: action.payload,
            }

        default:
    }
    return state || unloadedState
}
