import ItineraryStopModel from "./ItineraryStopModel"

export default class ItineraryModel {
    constructor(tourId: number, index: number) {
        this.tourId = tourId
        this.index = index
        this.stops = []
    }
    id: number = 0
    index: number = 0
    tourId: number = 0
    name: string = ''
    stops: ItineraryStopModel[] = []
}