import Region from './../models/Region'
import RegionsApiService from './../services/RegionsApiService'
import { AppThunkAction } from '.'
import { Reducer, Action } from 'redux'

export interface State {
    supplierRegions: Region[]
}
const RECEIVE_SUPPLIER_REGIONS = 'A_RECEIVE_SUPPLIER_REGIONS'
interface ReceiveSupplierRegions {
    type: 'A_RECEIVE_SUPPLIER_REGIONS'
    payload: Region[]
}

type KnownAction = ReceiveSupplierRegions

export const actionCreators = {
    requestSupplierRegions: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState()
        if (appState && appState.regions && appState.regions.supplierRegions.length === 0) {
            const result = await RegionsApiService.getSupplierRegions(dispatch)
            if (result.IsOk) {
                dispatch({ type: RECEIVE_SUPPLIER_REGIONS, payload: result.Content || [] })
            }
        }
    },
}

const unloadedState: State = { supplierRegions: [] as Region[] }

export const reducer: Reducer<State> = (state: State | undefined, incomingAction: Action): State => {
    if (state === undefined) {
        return unloadedState
    }

    const action = incomingAction as KnownAction
    switch (action.type) {
        case RECEIVE_SUPPLIER_REGIONS:
            return {
                supplierRegions: action.payload,
            }
        default:
            return state
    }
}
