import * as React from 'react'
import {
    DataTable,
    DataTableColumnModel,
    FilterType,
    DataTableModel,
    DataTableModelCustomAction,
    InputType,
} from 'traveldesk-ui'
import { PInTableList, SelectOptionModel, fromNumber, CheckBox } from 'traveldesk-ui'
import { useLanguagesManageTable } from '../../effects/useLanguages'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import ArrivalsDetailsFilter from './ArrivalsFilterModel'
import ArrivalGroupInfoModel from './ArrivalGroupInfoModel'
import ArrivalModel from './ArrivalModel'
import * as ArrivalsState from '../../store/Arrivals'
import Hotel from '../../models/Hotel'
import { Link, RouteComponentProps } from 'react-router-dom'
type Props = RouteComponentProps<{ groupId?: string }>
const ArrivalsListPage = (props: Props) => {
    const auth = useSelector((state: ApplicationState) => state.auth)
    const languages = useLanguagesManageTable()
    const arrivals = useSelector((state: ApplicationState) => state.arrivals)
    const [filter, setFilter] = React.useState(new ArrivalsDetailsFilter())
    React.useEffect(() => {
        setFiltersOptions()
    }, [arrivals])
    const setFiltersOptions = () => { }
    React.useEffect(() => {
        if (props.match.params.groupId) {
            filter.SetFilter('groupId', props.match.params.groupId)
        }
    }, [props.match.params.groupId])
    const initDataTable = (
        filter: ArrivalsDetailsFilter,
        salesOptions: SelectOptionModel[],
        hotelsHash: Map<number, Hotel>,
        hotels: Hotel[],
    ) => {
        const hotelsOptions = hotels.map((x) => ({ value: x.id.toString(), label: x.name }))
        const columns = [
            new DataTableColumnModel({
                displayName: 'Status',
                fieldName: 'status',
                filterValue: filter.GetMultiselectFilterValue('status'),
                customCellStyleFunction: (obj: ArrivalModel) =>
                    ArrivalGroupInfoModel.StatusStyle(
                        obj.Status,
                        obj.CommunicationStatus,
                        obj.isDeleted,
                        obj.groupInfo?.numberOfBookings ?? 0,
                    ),
                filterType: FilterType.MULTISELECT,
                filterOptions: ArrivalGroupInfoModel.GetStatusOptionsFilter,
                customExcelRenderer: (obj: ArrivalModel) =>
                    ArrivalGroupInfoModel.StatusDescription(
                        obj.Status,
                        obj.CommunicationStatus,
                        obj.isDeleted,
                        obj.groupInfo ? obj.groupInfo.numberOfBookings : 0,
                    ),
                customRenderer: (obj: ArrivalModel) => {
                    const numberOfBookings = obj.groupInfo?.numberOfBookings ?? 0
                    return !obj.isDeleted && numberOfBookings > 0 ? (
                        <Link to={`/agent/bookings/g-${obj.groupId}`}>{`${numberOfBookings} Booking${numberOfBookings > 1 ? 's' : ''
                            }`}</Link>
                    ) : (
                        ArrivalGroupInfoModel.StatusDescription(
                            obj.Status,
                            obj.CommunicationStatus,
                            obj.isDeleted,
                            obj.groupInfo ? obj.groupInfo.numberOfBookings : 0,
                        )
                    )
                },
            }),
            new DataTableColumnModel({
                displayName: 'Sales',
                fieldName: 'staffId',
                filterValue: filter.GetMultiselectFilterValue('staffId'),
                filterType: FilterType.MULTISELECT,
                filterOptions: salesOptions,
                // customFilterFunc: (filterValueParam: string[], data: ArrivalModel[]) => {
                //     const filterValue = filterValueParam.map((x) => parseInt(x))
                //     if (filterValue.length) {
                //         const showlWOutM = filterValue.indexOf(0) >= 0
                //         data = data.filter(
                //             (x) =>
                //                 x.sales.some((s) => filterValue.indexOf(s.id) >= 0) ||
                //                 (showlWOutM && x.sales.length == 0),
                //         )
                //     }
                //     return data
                // },
                customExcelRenderer: (obj: ArrivalModel) => obj.meetings.map((x) => x.staffName).join(', '),
                customRenderer: (obj: ArrivalModel) =>
                    obj.meetings ? obj.meetings.map((x) => <PInTableList key={x.staffId}>{x.staffName}</PInTableList>) : null,
            }),
            new DataTableColumnModel({
                displayName: "Meeting Date",
                fieldName: "meetingDate",
                filterValue: filter.GetDateRangeFilterValue('meetingDate'),
                filterType: FilterType.DATERANGE,
                customExcelRenderer: (obj: ArrivalModel) => obj.meetings.map((x) => x.dateTimeDisplay).join(', '),
                customRenderer: (obj: ArrivalModel) =>
                    obj.meetings ? obj.meetings.map((x) => <PInTableList key={x.staffId}>{x.dateTimeDisplay}</PInTableList>) : null,
            }),
            new DataTableColumnModel({
                displayName: 'GroupId',
                fieldName: 'groupId',
                sortable: true,
                // sortFunc: numberSortFunc,
                filterValue: filter.GetTextFilterValue('groupId'),
                filterType: FilterType.TEXT,
                width: 70,
            }),
            new DataTableColumnModel({
                displayName: 'Hotel',
                width: 150,
                fieldName: 'hotelName',
                sortable: false,
                filterFieldName: 'tdHotelId',
                filterOptions: hotelsOptions,
                filterValue: filter.GetMultiselectFilterValue('tdHotelId'),
                filterType: FilterType.MULTISELECT,
                customExcelRenderer: (obj: ArrivalModel) =>
                    obj.tdHotelId
                        ? hotelsHash.get(obj.tdHotelId)?.name ?? ''
                        : obj.hotelId == '9834f525-b87a-455d-96a5-bfef0ddaf25a'
                            ? 'No Hotel'
                            : obj.hotelId,
                customRenderer: (obj: ArrivalModel) => {
                    return obj.tdHotelId ? (
                        <>{hotelsHash.get(obj.tdHotelId)?.name ?? ''}</>
                    ) : (
                        <span style={{ backgroundColor: 'red', color: 'white' }}>
                            {obj.hotelId == '9834f525-b87a-455d-96a5-bfef0ddaf25a' ? 'No Hotel' : obj.hotelId}
                        </span>
                    )
                },
            }),
            new DataTableColumnModel({
                displayName: 'Tourist Name',
                fieldName: 'touristName',
                sortable: true,
                width: 100,
                filterType: FilterType.TEXT,
                filterValue: filter.GetTextFilterValue('touristName'),
                customExcelRenderer: (obj: ArrivalModel) => obj.touristName,
                // customRenderer: (obj: ArrivalModel) => {
                //     const toolTip = this.getTouristToolTip(obj)
                //     return toolTip ? (
                //         <ToolTip title={toolTip}>{obj.touristName}</ToolTip>
                //     ) : (
                //         <span>{obj.touristName}</span>
                //     )
                // },
            }),
            new DataTableColumnModel({
                displayName: 'Phone',
                fieldName: 'PhoneNumbers',
                isEditable: true,
                width: 80,
                editType: InputType.Text,
                filterType: FilterType.TEXT,
                filterValue: filter.GetTextFilterValue('PhoneNumbers'),
                // customFilterFunc: (value, data) => {
                //     return value && value.length > 0
                //         ? data.filter((x) => x.GroupInfo && x.GroupInfo.PhoneNumbers.indexOf(x) >= 0)
                //         : data
                // },
                // customExcelRenderer: (obj: ArrivalModel) => obj.RenderPhones(),
                // customRenderer: (obj: ArrivalModel) => obj.RenderPhones(),
            }),
            new DataTableColumnModel({
                displayName: 'Room #',
                width: 60,
                fieldName: 'RoomNumber',
                isEditable: true,
                editType: InputType.Text,
                filterType: FilterType.TEXT,
                filterValue: filter.GetTextFilterValue('RoomNumber'),
            }),
            new DataTableColumnModel({
                displayName: 'Age',
                fieldName: 'Age',
                width: 100,
            }),
            new DataTableColumnModel({
                displayName: 'Country',
                fieldName: 'touristCountry',
                sortable: true,
                // filterOptions: countriesOptions,
                filterValue: filter.GetTextFilterValue('touristCountry'),
                width: 70,
                filterType: FilterType.TEXT,
            }),
            new DataTableColumnModel({
                displayName: 'Agency',
                fieldName: 'tdAgencyName',
                // filterOptions: agenciesOptions,
                // filterType: FilterType.MULTISELECT,
                // filterValue: filter.GetMultiselectFilterValue('TdAgencyId'),
                sortable: false,
                width: 130,
                customExcelRenderer: (obj: ArrivalModel) => obj.tdAgencyName,
                customRenderer: (obj: ArrivalModel) => {
                    return <span>{obj.tdAgencyName}</span>
                },
            }),
            new DataTableColumnModel({
                displayName: 'Arrival Date',
                fieldName: 'arrivalDate',
                // sortFunc: momentSortFunc,
                sortable: true,
                width: 80,
                filterType: FilterType.DATERANGE,
                filterValue: filter.GetDateRangeFilterValue('arrivalDate'),
                // onFilterValueChanged: isDateRanged ? this.onArrivalFilterDateChanged : undefined,
                // customRenderer: (obj: ArrivalModel) => obj.arrivalDate.format(dateFormatNoTime),
                // customExcelRenderer: (obj: ArrivalModel) => obj.arrivalDate.format(dateFormatNoTime),
            }),
            new DataTableColumnModel({
                displayName: 'Arrival Time',
                fieldName: 'arrivalTime',
                sortable: true,
                // sortFunc: numberSortFunc,
                // filterType: !isDateRanged ? FilterType.MULTISELECT : undefined,
                // filterOptions: options.arrivalTimes.sort(numberSortFunc('ASC', 'value')),
                width: 80,
                customRenderer: (obj: ArrivalModel) => fromNumber(obj.arrivalTime),
                customExcelRenderer: (obj: ArrivalModel) => fromNumber(obj.arrivalTime),
            }),
            new DataTableColumnModel({
                displayName: 'Notes',
                fieldName: 'Notes',
                width: 150,
                isEditable: true,
                editType: InputType.Text,
            }),
            new DataTableColumnModel({
                displayName: 'Ar. TRMNL',
                fieldName: 'arrivalTerminal',
                filterType: FilterType.TEXT,
                // filterType: isDateRanged ? FilterType.TEXT : FilterType.MULTISELECT,
                filterValue: filter.GetTextFilterValue('ArrivalTerminal'),
                // filterOptions: arrivalsTerminalsOptions,
                width: 80,
            }),
            new DataTableColumnModel({
                displayName: 'Ar. Flight',
                fieldName: 'arrivalFlight',
                filterType: FilterType.TEXT,
                // filterType: isDateRanged ? FilterType.TEXT : FilterType.MULTISELECT,
                filterValue: filter.GetTextFilterValue('arrivalFlight'),
                // filterOptions: arrivalsFlightsOptions,
                width: 80,
            }),
            new DataTableColumnModel({
                displayName: 'Ar. Transport',
                fieldName: 'arrivalTransport',
                // filterType: isDateRanged ? FilterType.TEXT : FilterType.MULTISELECT,
                // filterValue: filter.GetTextFilterValue('ArrivalTransport'),
                // filterOptions: arrivalsTransportsOptions,
                width: 80,
            }),
            new DataTableColumnModel({
                displayName: 'Room Type',
                fieldName: 'roomType',
                // filterType: isDateRanged ? FilterType.TEXT : FilterType.MULTISELECT,
                filterType: FilterType.TEXT,
                filterValue: filter.GetTextFilterValue('roomType'),
                // filterOptions: roomTypesOptions,
                width: 100,
            }),
            new DataTableColumnModel({
                displayName: 'Acc Type',
                fieldName: 'accType',
                filterType: FilterType.TEXT,
                filterValue: filter.GetTextFilterValue('accType'),
                // filterOptions: accTypesOptions,
                width: 120,
            }),
            new DataTableColumnModel({
                displayName: 'Meal Type',
                fieldName: 'mealType',
                filterType: FilterType.TEXT,
                // filterType: isDateRanged ? FilterType.TEXT : FilterType.MULTISELECT,
                filterValue: filter.GetTextFilterValue('MealType'),
                // filterOptions: mealTypesOptions,
                width: 60,
            }),
            new DataTableColumnModel({
                displayName: 'Dep. Date',
                fieldName: 'departureDate',
                sortable: true,
                // sortFunc: dateMomentSortFunc,
                width: 80,
                // filterValue: isDateRanged && filter && filter.DepartureDate && filter.DepartureDate ? filter.DepartureDate : undefined,
                // onFilterValueChanged: isDateRanged ? this.onDepartureFilterDateChanged: undefined,
                filterType: FilterType.DATERANGE,
                filterValue: filter.GetDateRangeFilterValue('departureDate'),
                // customRenderer: (obj: ArrivalModel) => obj.departureDate.format(dateFormatNoTime),
                // customExcelRenderer: (obj: ArrivalModel) => obj.departureDate.format(dateFormatNoTime),
            }),
            new DataTableColumnModel({
                displayName: 'Dep. Time',
                fieldName: 'departureTime',
                sortable: true,
                // sortFunc: numberSortFunc,
                width: 80,
                // filterType: isDateRanged ? undefined : FilterType.MULTISELECT,
                // filterOptions: options.departureTimes.sort(numberSortFunc('ASC', 'value')),
                // customRenderer: (obj: ArrivalModel) => fromNumber(obj.DepartureTime),
                // customExcelRenderer: (obj: ArrivalModel) => fromNumber(obj.DepartureTime),
            }),
            new DataTableColumnModel({
                displayName: 'Dep. Flight',
                fieldName: 'departureFlight',
                filterType: FilterType.TEXT,
                filterValue: filter.GetTextFilterValue('departureFlight'),
                // filterOptions: departureFlightsOptions,
            }),
            new DataTableColumnModel({
                displayName: 'Dep. Terminal',
                fieldName: 'departureTerminal',
                // filterType: isDateRanged ? FilterType.TEXT : FilterType.MULTISELECT,
                filterValue: filter.GetTextFilterValue('DepartureTerminal'),
                // filterOptions: departureTerminalsOptions,
            }),
            new DataTableColumnModel({
                displayName: 'Dep. Transport',
                fieldName: 'departureTransport',
                // filterType: isDateRanged ? FilterType.TEXT : FilterType.MULTISELECT,
                filterValue: filter.GetTextFilterValue('DepartureTransport'),
                // filterOptions: departureTransportsOptions,
            }),
            new DataTableColumnModel({
                displayName: 'Pick Up Time',
                fieldName: 'PickUpTimeDescr',
            }),
            new DataTableColumnModel({
                displayName: 'Hotel Conf Number',
                fieldName: 'hotelConfNumber',
                width: 100,
                filterType: FilterType.TEXT,
                filterValue: filter.GetTextFilterValue('HotelConfNumber'),
            }),
            new DataTableColumnModel({
                displayName: 'Agent Conf Number',
                fieldName: 'agentConfNumber',
                width: 100,
                filterType: FilterType.TEXT,
                filterValue: filter.GetTextFilterValue('AgentConfNumber'),
            }),
        ]
        return new DataTableModel({
            columns,
            name: 'Arrivals',
            fileName: 'arrivals.xlsx',
            customActions: [
                new DataTableModelCustomAction(
                    () => { },
                    () => (
                        <CheckBox
                            name="inHouse"
                            label="In House"
                            onClick={onInHouseFilterChanged}
                            isChecked={filter.inHouse}
                        />
                    ),
                ),
            ],
        })
    }
    const onInHouseFilterChanged = () => {
        setFilter(
            Object.assign(new ArrivalsDetailsFilter(), filter, {
                inHouse: !filter.inHouse,
            }),
        )
    }
    React.useEffect(() => {
        getArrivals()
    }, [filter])
    const onRefresh = () => {
        getArrivals()
    }
    const dispatch = useDispatch()
    const getSales = async () => {
        dispatch(ArrivalsState.actionCreators.requestSales())
    }
    const getHotels = async () => {
        dispatch(ArrivalsState.actionCreators.requestHotels())
    }
    const getArrivals = async () => {
        dispatch(ArrivalsState.actionCreators.requestArrivals(filter))
    }
    React.useEffect(() => {
        getHotels()
        getSales()
        // getArrivals()
    }, [])
    const onEdit = (prev: ArrivalModel, next: ArrivalModel) => {
        if (next.groupInfo) {
            let gi = next.groupInfo
            dispatch(ArrivalsState.actionCreators.saveGroupInfo(gi))
        }
    }
    const onPageChange = (page: number) => {
        setFilter(Object.assign(new ArrivalsDetailsFilter(), filter, { page }))
    }
    const onFilterValueChanged = (fieldName: string, value: any) => {
        filter.SetFilter(fieldName, value)
        setFilter(Object.assign(new ArrivalsDetailsFilter(), filter))
    }
    const onSortChanged = (sortField: string, sortType: string) => {
        setFilter(
            Object.assign(new ArrivalsDetailsFilter(), filter, {
                sortField,
                sortType,
            }),
        )
    }
    const onClearFilter = () => {
        setFilter(new ArrivalsDetailsFilter())
    }
    const dataTableModel = React.useMemo(() => {
        const salesOptions = arrivals.sales
            .map((s) => ({ value: s.id, label: s.name }))
            .sort((a, b) => (a.label > b.label ? 1 : -1))
        return initDataTable(filter, salesOptions, arrivals.hotelsHash, arrivals.hotels)
    }, [arrivals.sales, arrivals.hotels, filter])
    return (
        <DataTable
            onFilterValueChange={onFilterValueChanged}
            onPageChange={onPageChange}
            onRefreshData={onRefresh}
            onClearFilter={onClearFilter}
            sortField="groupId"
            sortType="ASC"
            onEdit={onEdit}
            onSortChanged={onSortChanged}
            data={arrivals.arrivals.entities}
            pagingInfo={arrivals.arrivals.pagingInfo}
            dataTable={dataTableModel}
            isFullScreenable
            isFullScreen={true}
        />
    )
}
export default ArrivalsListPage
