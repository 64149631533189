import { ConversionType } from "traveldesk-ui"
import AccountSettingsCurrenciesModel from "./AccountSettingsCurrenciesModel"

export class AccountSettingsModel {
    taxes: AccountTaxModel[] = []
    defaultCommission: number = 20
    get currencies() {
        const res = [this.currencyConversionSettings.baseCurrency] as string[]
        if (this.currencyConversionSettings.secondaryCurrency) {
            res.push(this.currencyConversionSettings.secondaryCurrency)
        }
        return res
    }
    currencyConversionSettings: AccountSettingsCurrenciesModel = new AccountSettingsCurrenciesModel()
    get totalTaxAmount() {
        return this.taxes.reduce((res, next) => res + next.amount, 0)
    }
    get totalTaxK() {
        return this.taxes.reduce((res, next) => res + (next.amountK - 1), 1)
    }
}
export class AccountTaxModel {
    public static CreateConstructor(name: string, amount: number, amountK: number, convRate: number, convType: ConversionType) {
        return Object.assign(new AccountTaxModel(), { name, amount, amountK })
    }
    name: string = ''
    amount: number = 5
    amountK: number = 1.05
}