import * as React from 'react'
import { ApplicationState } from '../../store'
import { RouteComponentProps } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
    TextFormField,
    NumberFormField,
    ToggleFieldController,
    SelectFormFieldController,
    Row,
    Col,
    Grid,
    SectionTitle,
    WysiwygFieldController,
} from 'traveldesk-ui'
import { nameof } from 'traveldesk-ui'
import parseId from '../../services/parseId'
import { actionCreators as toursActions } from '../../store/Tours'
import { actionCreators as ticketsActions } from '../../store/Tickets'
import { actionCreators as citiesActions } from '../../store/Cities'
import { actionCreators as timeSlotsActions } from '../../store/TimeSlots'
import Offer from '../../models/Offer'
import OfferFormValidPeriods from './OfferFormValidPeriods'
import { MediaSelector } from 'traveldesk-ui'
import { Photo } from 'traveldesk-ui'
import { ScrollToTop, SelectOptionModel, IdFormField } from 'traveldesk-ui'
import { offerBookingTypeOptions } from './../../models/Offer'
import { Buttons, ContainerWithPadding } from 'traveldesk-ui'
import { LoadingSection } from '../../components/LoadingSection'
import { BookingTypeOptions } from '../../models/OfferBookingType'
interface Props extends RouteComponentProps<{ tourId: string; ticketId: string }> { }
const OfferForm = (props: Props) => {
    const dispatch = useDispatch()
    const citiesOptions = useSelector((state: ApplicationState) => {
        return state.cities.citiesOptions
    })
    const tickets = useSelector((state: ApplicationState) => {
        return state.tickets.types
    })
    const accountSettings = useSelector((state: ApplicationState) => state.account.settings)
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const ticketsOptions = React.useMemo(() => {
        return [{ value: undefined, label: Localization.getString('Not required') } as SelectOptionModel].concat(
            tickets.map((x) => ({ value: x.id, label: x.shortName })),
        )
    }, [tickets, Localization])
    const tour = useSelector((state: ApplicationState) => {
        return state.tours.tour
    })
    const offer = useSelector((state: ApplicationState) => {
        return state.tours.offer
    })
    const timeSlots = useSelector((state: ApplicationState) => {
        return state.timeSlots.timeSlotsGroups
    })
    const timeSlotsOptions = React.useMemo(
        () =>
            timeSlots.reduce((res, tsg) => {
                return res.concat(
                    tsg.timeSlots.map((ts) => ({
                        label: ts.name,
                        value: ts.id,
                        level: 2,
                        url: `/timeslots/${tsg.id}#ts-${ts.id}`
                    }))
                    // {
                    //     label: tsg.name,
                    //     value: -tsg.id,
                    //     level: 1,
                    //     options: tsg.timeSlots.map((ts) => ({
                    //         label: ts.name,
                    //         value: ts.id,
                    //         level: 2,
                    //     })),
                    // },
                )
            }, [] as SelectOptionModel[]),
        [timeSlots],
    )
    const onHeroImageChanged = (photo: Photo) => {
        setHeroImageId(photo.id)
    }
    const onDeletePhoto = (photo: Photo) => {
        setPhotos(photos.filter((x) => x.id != photo.id))
    }
    const onSelectPhotos = (newPhoto: Photo[]) => {
        setPhotos(photos.concat(newPhoto))
    }
    const bookingTypeOptions = React.useMemo(() => offerBookingTypeOptions(Localization), [Localization])
    const [photos, setPhotos] = React.useState(offer?.photos ?? [])
    const [heroImageId, setHeroImageId] = React.useState(offer?.heroImageId)
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        watch,
        reset,
        setValue,
    } = useForm({ defaultValues: offer })
    React.useEffect(() => {
        setPhotos(offer?.photos ?? [])
        setHeroImageId(offer?.heroImageId)
        reset(offer)
    }, [offer])
    React.useEffect(() => {
        ScrollToTop()
        const id = parseId(props.match.params.ticketId)
        const tourId = parseId(props.match.params.tourId)
        if (typeof tourId == undefined) {
            props.history.push('/tours')
        }
        if (typeof id == undefined) {
            props.history.push(`/tours${props.match.params.tourId}`)
        }
        if (!tour) {
            dispatch(toursActions.manageTour(tourId || 0, accountSettings))
        }
        if (tickets.length == 0) {
            dispatch(ticketsActions.requestTypes())
        }
        if (citiesOptions.length == 0) {
            dispatch(citiesActions.requestCitiesOptions())
        }
        if (timeSlots.length == 0) {
            dispatch(timeSlotsActions.requestTimeSlotsGroups())
        }
    }, [props.match.params.tourId, props.match.params.ticketId])
    React.useEffect(() => {
        ScrollToTop()
        const id = parseId(props.match.params.ticketId)
        const tourId = parseId(props.match.params.tourId)
        dispatch(toursActions.manageOffer(id || 0, tourId || 0, accountSettings))
    }, [props.match.params.ticketId])
    const onSubmit = (data: any) => {
        data.heroImageId = heroImageId
        data.photos = photos
        console.log(data)
        const offer = Offer.Create(data, parseInt(props.match.params.tourId), accountSettings)
        console.log(offer)
        // alert(JSON.stringify(data))
        dispatch(toursActions.saveOffer(offer))
    }
    const data = watch()
    return (
        <ContainerWithPadding>
            {tour && offer ? (
                <Grid>
                    <SectionTitle
                        title={Localization.getString(
                            offer.id
                                ? `${Localization.getString('Edit ticket')} '${offer.nameDisplay}' (${offer.nameInternal
                                })`
                                : Localization.getString('Add Ticket'),
                        )}
                    />
                    <Link className="a-back-to-list" to={`/tours/${props.match.params.tourId}`}>
                        {Localization.getString('Back to tour')}
                    </Link>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col type="50">
                                <h3>{Localization.getString('Basic Details')}</h3>
                                <IdFormField control={control} />

                                <TextFormField
                                    label={Localization.getString('Ticket Code')}
                                    subLabel={Localization.getString('Internal name of the ticket')}
                                    isRequired
                                    register={register}
                                    options={{ required: Localization.getString('Ticket code is required') }}
                                    name={nameof<Offer>('nameInternal')}
                                    error={errors.nameInternal ? errors.nameInternal.message : ''}
                                />
                                <TextFormField
                                    label={Localization.getString('Ticket Display Name')}
                                    subLabel={Localization.getString('The name visible to the public')}
                                    isRequired
                                    register={register}
                                    options={{ required: Localization.getString('Ticket display name is required') }}
                                    name={nameof<Offer>('nameDisplay')}
                                    error={errors.nameDisplay ? errors.nameDisplay.message : ''}
                                />
                                <SelectFormFieldController
                                    name={nameof<Offer>('origin')}
                                    isRequired
                                    label={Localization.getString('Origin')}
                                    options={citiesOptions}
                                    isMultiple
                                    subLabel={Localization.getString(
                                        'Select the cities FROM which this is ticket is valid for purchase',
                                    )}
                                    // error={errors.origin && errors.origin}
                                    control={control}
                                />
                                <NumberFormField
                                    register={register}
                                    label={`${Localization.getString('Minimum advanced payment')}, %`}
                                    min={0}
                                    subLabel={Localization.getString('MinPaymentSubheading')}
                                    max={100}
                                    name={nameof<Offer>('minimumAdvancedPayment')}
                                />
                                <NumberFormField
                                    register={register}
                                    label={Localization.getString('Min. Number of People')}
                                    isRequired
                                    min={1}
                                    options={{
                                        required: Localization.getString('Minimum number of people is required'),
                                    }}
                                    name={nameof<Offer>('minNumOfPeople')}
                                    error={errors.minNumOfPeople ? errors.minNumOfPeople.message : ''}
                                />
                                <NumberFormField
                                    register={register}
                                    label={Localization.getString('Max. Number of People')}
                                    isRequired
                                    min={1}
                                    options={{
                                        required: Localization.getString('Maximum number of people is required'),
                                    }}
                                    name={nameof<Offer>('maxNumOfPeople')}
                                    error={errors.maxNumOfPeople ? errors.maxNumOfPeople.message : ''}
                                />
                                <NumberFormField
                                    register={register}
                                    label={Localization.getString('Offer Duration in hours')}
                                    isRequired
                                    min={1}
                                    options={{
                                        required: Localization.getString('Minimum duration is 1 hour'),
                                    }}
                                    name={nameof<Offer>('numberOfHours')}
                                    error={errors.numberOfHours ? errors.numberOfHours.message : ''}
                                />
                                <ToggleFieldController
                                    name={nameof<Offer>('isDirectReporting')}
                                    label={Localization.getString('Is Direct Reporting')}
                                    control={control}
                                />
                                <ToggleFieldController
                                    name={nameof<Offer>('allowDirectCollection')}
                                    label={Localization.getString('Allow Direct Collection')}
                                    control={control}
                                />
                                <ToggleFieldController
                                    name={nameof<Offer>('isAppendTransactionFeeB2b')}
                                    label={Localization.getString('Append Transaction Fee')}
                                    control={control}
                                />
                                <WysiwygFieldController
                                    subLabel={Localization.getString(
                                        'This information goes onto the confirmation voucher',
                                    )}
                                    toolTip={Localization.getString('NoteToClientPostBToolTip')}
                                    label={Localization.getString('Notes To Clients Post Booking')}
                                    name={nameof<Offer>('notesToClientsPostBooking')}
                                    control={control}
                                />
                                <MediaSelector
                                    heroImageId={heroImageId}
                                    onHeroImageChanged={onHeroImageChanged}
                                    selected={photos}
                                    onSelectPhotos={onSelectPhotos}
                                    onDelete={onDeletePhoto}
                                />
                            </Col>
                            <Col type="50">
                                {/* subLabel={Localization.getString('SalesSubLabel')} */}
                                <WysiwygFieldController
                                    label={Localization.getString('Description')}
                                    name={nameof<Offer>('description')}
                                    control={control}
                                />
                                <h3>{Localization.getString('Age Policy & Attraction Tickets')}</h3>

                                <ToggleFieldController
                                    name={nameof<Offer>('childrenAllowed')}
                                    label={Localization.getString('Children allowed')}
                                    control={control}
                                />
                                <NumberFormField
                                    register={register}
                                    defaultValue={offer.childAge}
                                    label={Localization.getString('Child Age, Before')}
                                    isRequired
                                    min={1}
                                    max={18}
                                    name={nameof<Offer>('childAge')}
                                />
                                <ToggleFieldController
                                    name={nameof<Offer>('infantsAllowed')}
                                    label={Localization.getString('Infants allowed')}
                                    control={control}
                                />
                                <NumberFormField
                                    register={register}
                                    defaultValue={offer.infantAge}
                                    label={Localization.getString('Infant Age, Before')}
                                    isRequired
                                    min={1}
                                    max={18}
                                    name={nameof<Offer>('infantAge')}
                                />
                                <ToggleFieldController
                                    name={nameof<Offer>('isTicketsRequired')}
                                    label={Localization.getString('Are attraction tickets required for this ticket?')}
                                    control={control}
                                    onChange={(e: boolean) => {
                                        // if (!e) {
                                        //     setValue('ticketTypeAdult', undefined as any)
                                        //     setValue('ticketTypeChild', undefined as any)
                                        //     setValue('ticketTypeInfant', undefined as any)
                                        // }
                                    }}
                                />
                                {data.isTicketsRequired && (
                                    <SelectFormFieldController
                                        name={nameof<Offer>('ticketTypeAdult')}
                                        label={Localization.getString('Adult ticket')}
                                        options={ticketsOptions}
                                        isRequired
                                        control={control}
                                    />
                                )}
                                {data.isTicketsRequired && (
                                    <SelectFormFieldController
                                        name={nameof<Offer>('ticketTypeChild')}
                                        label={Localization.getString('Child ticket')}
                                        isRequired
                                        options={ticketsOptions}
                                        control={control}
                                    />
                                )}
                                {data.isTicketsRequired && (
                                    <SelectFormFieldController
                                        name={nameof<Offer>('ticketTypeInfant')}
                                        label={Localization.getString('Infant ticket')}
                                        isRequired
                                        options={ticketsOptions}
                                        control={control}
                                    />
                                )}
                                <h3>{Localization.getString('Availability settings')}</h3>
                                <SelectFormFieldController
                                    name={nameof<Offer>('bookingType')}
                                    label={Localization.getString('Booking Type')}
                                    options={BookingTypeOptions}
                                    control={control}
                                />
                                <SelectFormFieldController
                                    name={nameof<Offer>('timeSlots')}
                                    label={Localization.getString('Time Slots')}
                                    isMultiple
                                    options={timeSlotsOptions}
                                    control={control}
                                />
                            </Col>
                        </Row>
                        <Buttons.StickySaveButton>{Localization.getString('Save')}</Buttons.StickySaveButton>
                    </form>
                    {/* <OfferFormValidPeriods offer={offer} /> */}
                </Grid>
            ) : (
                <LoadingSection />
            )}
        </ContainerWithPadding>
    )
}
export default OfferForm
