import TimeSlotGroup from '../models/TimeSlotGroup'
import { LoadingStore, SelectOptionModel } from 'traveldesk-ui'
import TimeSlotsApiService from './../services/TimeSlotsApiService'
import { AppThunkAction } from '.'
import { Reducer, Action } from 'redux'
import { NotificationsStore, NotificationModel, NotificationType } from 'traveldesk-ui'
import { push } from 'react-router-redux'
import { RouterAction } from 'connected-react-router'

export interface State {
    timeSlotsGroups: TimeSlotGroup[]
    timeSlotGroup?: TimeSlotGroup
    externalProvidersOptions?: SelectOptionModel[]
    externalOffersOptions: Map<number, SelectOptionModel[]>
    isLoadingOffers: boolean
    externalTimeSlotsOptions: Map<number, Map<string, SelectOptionModel[]>>
    isLoadingTimeSlots: boolean
}

const RECEIVE_TIMESLOTS_GROUPS = 'A_RECEIVE_TIMESLOTS_GROUPS'
interface ReceiveTimeSlotsGroups {
    type: 'A_RECEIVE_TIMESLOTS_GROUPS'
    payload: TimeSlotGroup[]
}
const TIMESLOT_SAVED = 'A_TIMESLOT_SAVED'
interface TimeSlotSaved {
    type: 'A_TIMESLOT_SAVED'
    payload: TimeSlotGroup
}
const SET_TIMESLOT_MANAGE = 'A_SET_TIMESLOT_MANAGE'
interface SetTimeSlotManage {
    type: 'A_SET_TIMESLOT_MANAGE'
    payload: TimeSlotGroup
}
const RECEIVE_EXTERNAL_PROVIDERS = "RECEIVE_EXTERNAL_PROVIDERS"
interface ReceiveExternalProviders {
    type: "RECEIVE_EXTERNAL_PROVIDERS"
    payload: SelectOptionModel[]
}
const RECEIVE_EXTERNAL_OFFERS = "RECEIVE_EXTERNAL_OFFERS"
const RECEIVE_EXTERNAL_OFFERS_START = "RECEIVE_EXTERNAL_OFFERS_START"
const RECEIVE_EXTERNAL_OFFERS_END = "RECEIVE_EXTERNAL_OFFERS_END"
interface ReceiveExternalOffers {
    type: "RECEIVE_EXTERNAL_OFFERS"
    payload: { apiProviderId: number, offers: SelectOptionModel[] }
}
interface ReceiveExternalOffersStart {
    type: "RECEIVE_EXTERNAL_OFFERS_START"
}
interface ReceiveExternalOffersEnd {
    type: "RECEIVE_EXTERNAL_OFFERS_END"
}
const RECEIVE_EXTERNAL_TIMESLOTS = "RECEIVE_EXTERNAL_TIMESLOTS"
const RECEIVE_EXTERNAL_TIMESLOTS_START = "RECEIVE_EXTERNAL_TIMESLOTS_START"
const RECEIVE_EXTERNAL_TIMESLOTS_END = "RECEIVE_EXTERNAL_TIMESLOTS_END"
interface ReceiveExternalTimeSltos {
    type: "RECEIVE_EXTERNAL_TIMESLOTS",
    payload: { apiProviderId: number, productId: string, timeSlots: SelectOptionModel[] }
}
interface ReceiveExternalTimeSlotsStart {
    type: "RECEIVE_EXTERNAL_TIMESLOTS_START"
}
interface ReceiveExternalTimeSlotsEnd {
    type: "RECEIVE_EXTERNAL_TIMESLOTS_END"
}

type KnownAction = ReceiveTimeSlotsGroups | SetTimeSlotManage | TimeSlotSaved | RouterAction
    | ReceiveExternalProviders
    | ReceiveExternalOffers | ReceiveExternalOffersStart | ReceiveExternalOffersEnd
    | ReceiveExternalTimeSltos | ReceiveExternalTimeSlotsStart | ReceiveExternalTimeSlotsEnd


export const actionCreators = {
    requestExternalTimeSlots: (apiProviderId: number, offerId: string): AppThunkAction<KnownAction | LoadingStore.KnownAction> => async (dispatch, getState) => {
        if (getState().timeSlots.isLoadingTimeSlots) return
        if (getState().timeSlots.externalTimeSlotsOptions?.get(apiProviderId)?.has(offerId)) return
        dispatch(LoadingStore.actionCreators.incrementLoading())
        dispatch({ type: RECEIVE_EXTERNAL_TIMESLOTS_START })
        let fetchTask = await TimeSlotsApiService.getExternalTimeSlots(apiProviderId, offerId, dispatch)
        const data = fetchTask.Content
        if (fetchTask.IsOk && data) {
            dispatch({ type: RECEIVE_EXTERNAL_TIMESLOTS, payload: { apiProviderId, productId: offerId, timeSlots: data } })
        }
        dispatch({ type: RECEIVE_EXTERNAL_TIMESLOTS_END })
        dispatch(LoadingStore.actionCreators.decrementLoading())
    },
    requestExternalOffers: (apiProviderId: number): AppThunkAction<KnownAction | LoadingStore.KnownAction> => async (dispatch, getState) => {
        if (getState().timeSlots.isLoadingOffers) return
        dispatch({ type: RECEIVE_EXTERNAL_OFFERS_START })
        if (getState().timeSlots.externalOffersOptions?.has(apiProviderId)) return
        dispatch(LoadingStore.actionCreators.incrementLoading())
        dispatch({ type: RECEIVE_EXTERNAL_OFFERS_START })
        let fetchTask = await TimeSlotsApiService.getExternalOffers(apiProviderId, dispatch)
        const data = fetchTask.Content
        if (fetchTask.IsOk && data) {
            dispatch({ type: RECEIVE_EXTERNAL_OFFERS, payload: { apiProviderId: apiProviderId, offers: data } })
        }
        dispatch(LoadingStore.actionCreators.decrementLoading())
        dispatch({ type: RECEIVE_EXTERNAL_OFFERS_END })

    },
    requestExternalProviders: (): AppThunkAction<KnownAction | LoadingStore.KnownAction> => async (dispatch) => {
        dispatch(LoadingStore.actionCreators.incrementLoading())
        let fetchTask = await TimeSlotsApiService.getExternalProviders(dispatch)
        const data = fetchTask.Content
        if (fetchTask.IsOk && data) {
            dispatch({ type: RECEIVE_EXTERNAL_PROVIDERS, payload: data })
        }
        dispatch(LoadingStore.actionCreators.decrementLoading())
    },
    requestTimeSlotsGroups: (): AppThunkAction<KnownAction | LoadingStore.KnownAction> => async (dispatch) => {
        dispatch(LoadingStore.actionCreators.incrementLoading())
        let fetchTask = await TimeSlotsApiService.getTable(dispatch)
        const data = fetchTask.Content
        if (fetchTask.IsOk && data) {
            dispatch({ type: RECEIVE_TIMESLOTS_GROUPS, payload: data })
        }
        dispatch(LoadingStore.actionCreators.decrementLoading())
    },
    save:
        (tsg: TimeSlotGroup): AppThunkAction<KnownAction | LoadingStore.KnownAction> =>
            async (dispatch) => {
                dispatch(LoadingStore.actionCreators.incrementLoading())
                let fetchTask = await TimeSlotsApiService.save(tsg, dispatch)
                if (fetchTask.IsOk && fetchTask.Content) {
                    dispatch({ type: TIMESLOT_SAVED, payload: fetchTask.Content })
                }
                if (fetchTask.Content) {
                    dispatch(push(`/timeslots/${fetchTask.Content.id}`))
                }
                dispatch(LoadingStore.actionCreators.decrementLoading())
            },
    manageTimeSlotGroup:
        (id: number): AppThunkAction<KnownAction | LoadingStore.KnownAction | NotificationsStore.KnownAction> =>
            async (dispatch, getState) => {
                if (id == 0) {
                    dispatch({ type: SET_TIMESLOT_MANAGE, payload: TimeSlotGroup.Create(new TimeSlotGroup()) })
                } else {
                    const exTour = getState().tours.tour
                    if (exTour && exTour.id != id) {
                    }
                    dispatch(LoadingStore.actionCreators.incrementLoading())
                    let fetchTask = await TimeSlotsApiService.getForEdit(dispatch, id)
                    const data = fetchTask.Content
                    if (fetchTask.IsOk && data) {
                        dispatch({ type: SET_TIMESLOT_MANAGE, payload: TimeSlotGroup.Create(data) })
                    } else {
                        const localization = getState().localization.locale
                        dispatch(
                            NotificationsStore.actionCreators.add(
                                new NotificationModel(
                                    NotificationType.error,
                                    localization.getString('Not found'),
                                    localization.getString('Time slot group not found or you are not allowed to manage it'),
                                ),
                            ),
                        )
                        dispatch(push('/timeslots'))
                    }
                    dispatch(LoadingStore.actionCreators.decrementLoading())
                }
            },
}
const unloadedState: State = {
    timeSlotsGroups: [],
    timeSlotGroup: undefined as unknown as TimeSlotGroup,
    externalProvidersOptions: undefined as unknown as SelectOptionModel[],
    externalOffersOptions: new Map<number, SelectOptionModel[]>(),
    externalTimeSlotsOptions: new Map<number, Map<string, SelectOptionModel[]>>(),
    isLoadingOffers: false,
    isLoadingTimeSlots: false
}

export const reducer: Reducer<State> = (state: State = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction
    switch (action.type) {
        case RECEIVE_EXTERNAL_TIMESLOTS_START:
            return { ...state, isLoadingTimeSlots: true }
        case RECEIVE_EXTERNAL_TIMESLOTS_END:
            return { ...state, isLoadingTimeSlots: false }
        case RECEIVE_EXTERNAL_OFFERS_START:
            return { ...state, isLoadingOffers: true }
        case RECEIVE_EXTERNAL_OFFERS_END:
            return { ...state, isLoadingOffers: false }
        case RECEIVE_EXTERNAL_TIMESLOTS:
            const externalTimeSlotsOptionsNew = Object.assign(new Map<number, Map<string, SelectOptionModel[]>>(), state.externalTimeSlotsOptions)
            let apiProvidersProductsTimeSlots = externalTimeSlotsOptionsNew.get(action.payload.apiProviderId);
            if (!apiProvidersProductsTimeSlots) {
                apiProvidersProductsTimeSlots = new Map<string, SelectOptionModel[]>()
                externalTimeSlotsOptionsNew.set(action.payload.apiProviderId, apiProvidersProductsTimeSlots)
            }
            apiProvidersProductsTimeSlots.set(action.payload.productId, action.payload.timeSlots)
            return { ...state, externalTimeSlotsOptions: externalTimeSlotsOptionsNew }
        case RECEIVE_EXTERNAL_OFFERS:
            const externalOffersOptionsNew = Object.assign(new Map<number, SelectOptionModel[]>(), state.externalOffersOptions)
            externalOffersOptionsNew.set(action.payload.apiProviderId, action.payload.offers)
            return { ...state, externalOffersOptions: externalOffersOptionsNew }
        case RECEIVE_EXTERNAL_PROVIDERS:
            return { ...state, externalProvidersOptions: action.payload }
        case RECEIVE_TIMESLOTS_GROUPS:
            return { ...state, timeSlotsGroups: action.payload }
        case SET_TIMESLOT_MANAGE:
            return { ...state, timeSlotGroup: action.payload }
        case TIMESLOT_SAVED:
            const timeSlotsGroups = state.timeSlotsGroups.some((x) => x.id == action.payload.id)
                ? state.timeSlotsGroups.map((x) => (x.id == action.payload.id ? action.payload : x))
                : state.timeSlotsGroups.concat(action.payload)
            return { ...state, timeSlotsGroups }
    }
    return state || unloadedState
}
