import { GenericFilterModelText, GenericFilterModelMultiSelect } from './GenericServerFiltersModels'
import { FilterType } from 'traveldesk-ui'

export default class GenericFilterModel {
    page: number = 1
    textFilters: GenericFilterModelText[] = []
    multiSelectFilters: GenericFilterModelMultiSelect[] = []
    GetTextFilterValue(fieldName: string) {
        const fv = this.textFilters.find((x) => x.FieldName == fieldName)
        return fv ? fv.Value : undefined
    }
    GetMultiselectFilterValue(fieldName: string) {
        const fv = this.multiSelectFilters.find((x) => x.FieldName == fieldName)
        return fv ? fv.Values : undefined
    }
    SetFilter(fieldName: string, value: any) {
        if (value.type == FilterType.MULTISELECT) {
            const MultiSelectFilters =
                value.value && value.value.length > 0
                    ? this.multiSelectFilters.some((x) => x.FieldName == fieldName)
                        ? this.multiSelectFilters.map((x) =>
                              x.FieldName == fieldName
                                  ? Object.assign(new GenericFilterModelMultiSelect(), x, { Values: value.value })
                                  : x,
                          )
                        : this.multiSelectFilters.concat([
                              Object.assign(new GenericFilterModelMultiSelect(), {
                                  Values: value.value,
                                  FieldName: fieldName,
                              }),
                          ])
                    : this.multiSelectFilters.filter((x) => x.FieldName != fieldName)
            this.page = 1
            this.multiSelectFilters = MultiSelectFilters
            return
        }
        if (value.type == FilterType.TEXT) {
            this.page = 1
            this.textFilters =
                value.value && value.value.length > 0
                    ? this.textFilters.some((x) => x.FieldName == fieldName)
                        ? this.textFilters.map((x) =>
                              x.FieldName == fieldName
                                  ? Object.assign(new GenericFilterModelText(), x, { Value: value.value })
                                  : x,
                          )
                        : this.textFilters.concat([
                              Object.assign(new GenericFilterModelText(), { Value: value.value, FieldName: fieldName }),
                          ])
                    : this.textFilters.filter((x) => x.FieldName != fieldName)
            return
        }
    }
    sortField: string = ''
    sortType: string = ''
}
