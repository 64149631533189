import * as React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../store'
import { Roles } from 'traveldesk-ui'

interface Props extends RouteComponentProps<{}> {}
const Home = (props: Props) => {
    const auth = useSelector((state: ApplicationState) => state.auth)
    React.useEffect(() => {
        if (auth.authData) {
            if (auth.authData?.isInRole(Roles.Operations) || auth.authData?.isInRole(Roles.Accounts)) {
                props.history.push('/bookings')
            }
            if (auth.authData?.isInRole(Roles.Agent)) {
                props.history.push('/agent/bookings')
            }
            if (auth.authData?.isInRole(Roles.ArrivalsManager)) {
                props.history.push('/arrivals')
            }
        }
    }, [auth])
    return null
}

export default Home
