import * as React from 'react'
import {
    Grid,
    Row,
    Col,
    TimePickerFormFieldController,
    timeStringToMinutes,
    Popup,
    PopupFormWrapper,
} from 'traveldesk-ui'
import { useSelector } from 'react-redux'
import { ApplicationState } from './../../store/index'
import { useForm } from 'react-hook-form'
import { Buttons } from 'traveldesk-ui'

interface Props {
    onClose: () => void
    onSubmit: (value: { timeStart: number; timeEnd: number }) => void
}
const PickUpTimeFormMassEdit = (props: Props) => {
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const { handleSubmit, control } = useForm()
    const onSubmit = (data: any) => {
        data.timeStart = timeStringToMinutes(data.timeStart)
        data.timeEnd = timeStringToMinutes(data.timeEnd)
        props.onSubmit(data)
    }
    return (
        <Popup onClose={props.onClose}>
            <PopupFormWrapper>
                <Grid>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col type="100">
                                <h3>{Localization.getString('Mass set pick up times for selected hotels')}</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col type="50">
                                <TimePickerFormFieldController
                                    name={'timeStart'}
                                    defaultValue={'00:00'}
                                    label={`${Localization.getString('Start time')}`}
                                    id={'timeStart'}
                                    control={control}
                                    onChange={([e]: any) => e}
                                />
                            </Col>
                            <Col type="50">
                                <TimePickerFormFieldController
                                    name={'timeEnd'}
                                    defaultValue={'00:00'}
                                    label={`${Localization.getString('End time')}`}
                                    id={'timeEnd'}
                                    control={control}
                                    onChange={([e]: any) => e}
                                />
                            </Col>
                        </Row>
                        <Buttons.StickySaveButton>{Localization.getString('Save')}</Buttons.StickySaveButton>
                    </form>
                </Grid>
            </PopupFormWrapper>
        </Popup>
    )
}
export default PickUpTimeFormMassEdit
