import moment from 'moment'
import { FilterValuesWrapper, SortType } from 'traveldesk-ui'

export default class BookingsRequest {
    page: number = 1
    dateStart?: moment.Moment = undefined
    dateEnd?: moment.Moment = undefined
    search?: FilterValuesWrapper = undefined
    sortField: string = 'date'
    sortType: SortType = 'DESC'
    constructor(search?: FilterValuesWrapper) {
        this.search = search
    }
    get query(): string {
        let res = `page=${this.page}&orderBy=${this.sortField}&orderType=${this.sortType}`
        if (this.dateStart) {
            res += `&from=${this.dateStart.format('DD.MM.YYYY')}`
        }
        if (this.dateEnd) {
            res += `&to=${this.dateEnd.format('DD.MM.YYYY')}`
        }
        if (this.search) {
            res += `&f=${this.search.query}`
        }
        return res.toLowerCase()
    }
}
