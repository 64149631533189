import * as React from 'react'
const Controls = () => {
    return (
        <div className="container">
            <div className="content-wrap content-wrap-padding">
                <section className="element-section">
                    <h2 className="section-title">Typography</h2>
                    <div className="row-wrap">
                        <div className="col-66">
                            <div className="row-wrap">
                                <div className="col-50">
                                    <h6 className="with-overhead">
                                        <span>Titles</span>
                                        Google Font: Cabin
                                    </h6>

                                    <h1>H1 Heading</h1>
                                    <h2>H2 Heading</h2>
                                    <h3>H3 Heading</h3>
                                    <h4>H4 Heading</h4>
                                    <h5>H5 Heading</h5>
                                </div>
                                <div className="col-50">
                                    <h6 className="with-overhead">
                                        <span>Content</span>
                                        Google Font: Cabin
                                    </h6>
                                    <p className="description">
                                        We are offering you an outstanding sea cruise around the Palm Jumeirah, to Burj
                                        Al Arab,Dubai Marina and the “The World” Islands.
                                    </p>
                                    <p className="description">
                                        You will enjoy fascinating sea waves and gain a different perspective on the
                                        city of Dubai by seeing the skyline of the city from the water.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-33"></div>
                    </div>
                </section>

                <section className="element-section">
                    <h2 className="section-title">Colors</h2>
                    <div className="row-wrap">
                        <div className="col-100">
                            <ul className="colors-list">
                                <li>
                                    <div className="bg-green"></div>
                                    <span className="color-lgrey">#33B92D</span>
                                </li>
                                <li>
                                    <div className="bg-blue"></div>
                                    <span className="color-lgrey">#2B388F</span>
                                </li>
                                <li>
                                    <div className="bg-orange"></div>
                                    <span className="color-lgrey">#F6921E</span>
                                </li>
                                <li>
                                    <div className="bg-navy"></div>
                                    <span className="color-lgrey">#1B214A</span>
                                </li>
                                <li>
                                    <div className="bg-grey"></div>
                                    <span className="color-lgrey">#848484</span>
                                </li>
                                <li>
                                    <div className="bg-lgrey"></div>
                                    <span className="color-lgrey">#A5A5A5</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section className="element-section buttons-section">
                    <h2 className="section-title">Buttons</h2>
                    <div className="buttons-list">
                        <a href="#" className="button button--book-now is-green has-glow">
                            <i></i>
                            Book Now
                        </a>
                        <a href="#" className="button button--load-more has-glow">
                            Load More
                            <i></i>
                        </a>
                        <a href="#" className="button button--select-ticket is-orange has-glow">
                            <i></i>
                            Select Ticket
                        </a>
                        <a href="#" className="button button--cancel">
                            <i></i>
                            Cancel Booking
                        </a>
                        <a href="#" className="button button--voucher is-green has-glow">
                            <i></i>
                            Download Voucher
                        </a>
                        <a href="#" className="button button--ticket has-glow">
                            <i></i>
                            Download Ticket
                        </a>
                        <a href="#" className="button button--invoice is-navy has-glow">
                            <i></i>
                            Download Invoice
                        </a>
                        <a href="#" className="button has-glow">
                            Submit Review
                        </a>
                        <a href="#" className="button button--checkout is-green has-glow is-large">
                            Checkout
                        </a>
                        <a href="#" className="button button--voucher is-large is-green has-glow">
                            <i></i>
                            Download Voucher
                        </a>
                        <a href="#" className="button button--ticket is-large has-glow">
                            <i></i>
                            Download Tickets
                        </a>
                        <a href="#" className="button is-large is-navy has-glow">
                            <i></i>
                            Download Invoice
                        </a>
                        <a href="#" className="button button--account is-small is-green has-glow">
                            <i></i>
                            My Account
                        </a>
                        <a href="#" className="button button--view-tour is-orange is-small has-glow">
                            View Tour
                        </a>
                        <a href="#" className="button button--view-tour is-white is-small">
                            View Tour
                        </a>
                    </div>
                </section>

                <section className="element-section grid-section">
                    <h2 className="section-title">Buttons</h2>
                    <div className="row-wrap">
                        <div className="col-100 bg-lgrey2">col-100</div>
                    </div>

                    <div className="row-wrap">
                        <div className="col-50 bg-lgrey2">col-50</div>
                        <div className="col-50 bg-lgrey2">col-50</div>
                    </div>

                    <div className="row-wrap">
                        <div className="col-33 bg-lgrey2">col-33</div>
                        <div className="col-66 bg-lgrey2">col-66</div>
                    </div>

                    <div className="row-wrap">
                        <div className="col-25 bg-lgrey2">col-25</div>
                        <div className="col-50 bg-lgrey2">col-50</div>
                        <div className="col-25 bg-lgrey2">col-25</div>
                    </div>

                    <div className="row-wrap">
                        <div className="col-33 bg-lgrey2">col-33</div>
                        <div className="col-33 bg-lgrey2">col-33</div>
                        <div className="col-33 bg-lgrey2">col-33</div>
                    </div>

                    <div className="row-wrap">
                        <div className="col-25 bg-lgrey2">col-25</div>
                        <div className="col-25 bg-lgrey2">col-25</div>
                        <div className="col-25 bg-lgrey2">col-25</div>
                        <div className="col-25 bg-lgrey2">col-25</div>
                    </div>

                    <hr />

                    <div className="row-wrap small-gap">
                        <div className="col-50 bg-lgrey2">
                            small-gap
                            <br />
                            col-50
                        </div>
                        <div className="col-50 bg-lgrey2">
                            small-gap
                            <br />
                            col-50
                        </div>
                    </div>

                    <div className="row-wrap small-gap">
                        <div className="col-33 bg-lgrey2">
                            small-gap
                            <br />
                            col-33
                        </div>
                        <div className="col-66 bg-lgrey2">
                            small-gap
                            <br />
                            col-66
                        </div>
                    </div>

                    <div className="row-wrap small-gap">
                        <div className="col-25 bg-lgrey2">
                            small-gap
                            <br />
                            col-25
                        </div>
                        <div className="col-50 bg-lgrey2">
                            small-gap
                            <br />
                            col-50
                        </div>
                        <div className="col-25 bg-lgrey2">
                            small-gap
                            <br />
                            col-25
                        </div>
                    </div>

                    <div className="row-wrap small-gap">
                        <div className="col-33 bg-lgrey2">
                            small-gap
                            <br />
                            col-33
                        </div>
                        <div className="col-33 bg-lgrey2">
                            small-gap
                            <br />
                            col-33
                        </div>
                        <div className="col-33 bg-lgrey2">
                            small-gap
                            <br />
                            col-33
                        </div>
                    </div>

                    <div className="row-wrap small-gap">
                        <div className="col-25 bg-lgrey2">
                            small-gap
                            <br />
                            col-25
                        </div>
                        <div className="col-25 bg-lgrey2">
                            small-gap
                            <br />
                            col-25
                        </div>
                        <div className="col-25 bg-lgrey2">
                            small-gap
                            <br />
                            col-25
                        </div>
                        <div className="col-25 bg-lgrey2">
                            small-gap
                            <br />
                            col-25
                        </div>
                    </div>
                </section>

                <section className="element-section buttons-section">
                    <h2 className="section-title">Contact Form</h2>
                    <div className="row-wrap">
                        <div className="col-100">
                            <form method="get" className="form-demo">
                                <div className="field-wrap">
                                    <label htmlFor="name">
                                        Name <span>*</span>
                                    </label>
                                    <input id="name" name="name" type="text" value="Roman" />
                                    <div className="field-footer"></div>
                                </div>

                                <div className="field-wrap">
                                    <label htmlFor="email">
                                        Email <span>*</span>
                                    </label>
                                    <div className="email-field-wrap">
                                        <i></i>
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            className="with-icon"
                                            data-description="We don't send spam to our users"
                                            data-error=""
                                        />
                                    </div>
                                    <div className="field-footer has-description">
                                        <span className="field-description">
                                            <i></i>We don't send spam to our users
                                        </span>
                                    </div>
                                </div>

                                <div className="field-wrap">
                                    <label htmlFor="password-general" className="for-password">
                                        Password <span>*</span>
                                        <a href="#" className="show-password is-active">
                                            Show Password
                                        </a>
                                    </label>
                                    <div className="password-field-wrap">
                                        <input
                                            id="password-general"
                                            name="password-general"
                                            type="text"
                                            className="password-shown"
                                            data-description="Password must be at least 6 characters long"
                                            data-error="Incorrect Password. Please try again."
                                        />
                                        <span className="caps-state">Capslock</span>
                                    </div>
                                    <div className="field-footer has-error-message has-description">
                                        <span className="field-description">
                                            <i></i>Password must be at least 6 characters long
                                        </span>
                                        <span className="field-state">Incorrect Password. Please try again.</span>
                                    </div>
                                </div>

                                <div className="field-wrap is-error">
                                    <label htmlFor="password-confirm" className="for-password">
                                        Password <span>*</span>
                                        <a href="#" className="show-password">
                                            Show Password
                                        </a>
                                    </label>
                                    <div className="password-field-wrap">
                                        <input
                                            id="password-confirm"
                                            name="password-confirm"
                                            type="password"
                                            value="password"
                                            data-description="Password must be at least 6 characters long"
                                            data-error="Incorrect Password. Please try again."
                                        />
                                        <span className="caps-state">Capslock</span>
                                    </div>
                                    <div className="field-footer has-error-message has-description">
                                        <span className="field-description">
                                            <i></i>Password must be at least 6 characters long
                                        </span>
                                        <span className="field-state">Incorrect Password. Please try again.</span>
                                    </div>
                                </div>

                                <hr />

                                <div className="row-wrap small-gap">
                                    <div className="col-33">
                                        <div className="field-wrap">
                                            <label htmlFor="tel">Phone</label>
                                            <div className="tel-field-wrap">
                                                <div className="tel-code">+12</div>
                                                <input
                                                    id="tel"
                                                    name="tel"
                                                    type="tel"
                                                    value="(066) 123 45 78"
                                                    data-codes="+12,+7,+35,+38,+44"
                                                />
                                                <input type="hidden" className="tel-code-input" value="+12" />
                                                <ul className="tel-code-list" style={{ display: 'none' }}>
                                                    <li>+12</li>
                                                    <li>+7</li>
                                                    <li>+35</li>
                                                    <li>+38</li>
                                                    <li>+44</li>
                                                </ul>
                                            </div>
                                            <div className="field-footer"></div>
                                        </div>
                                    </div>
                                    <div className="col-33">
                                        <div className="field-wrap">
                                            <label htmlFor="state">State</label>
                                            <div className="custom_select_wrapper has-empty-value">
                                                <select
                                                    name="state"
                                                    id="state"
                                                    className="custom_select new-select select-hidden"
                                                >
                                                    <option value="">Your State</option>
                                                    <option value="option01">Option #1</option>
                                                    <option value="option02">Option #2</option>
                                                    <option value="option03">Option #3</option>
                                                </select>
                                                <div className="custom_select">Your State</div>
                                                <ul className="select-options" style={{}}>
                                                    <li className="is-selected">Your State</li>
                                                    <li>Option #1</li>
                                                    <li>Option #2</li>
                                                    <li>Option #3</li>
                                                </ul>
                                                <div className="custom_select_toggle"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-33">
                                        <div className="field-wrap">
                                            <label htmlFor="zip">Zip</label>
                                            <input id="zip" name="zip" type="text" />
                                            <div className="field-footer"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="field-wrap">
                                    <label htmlFor="state">City</label>
                                    <div className="custom_select_wrapper">
                                        <select
                                            name="city"
                                            id="city"
                                            className="custom_select new-select select-hidden"
                                        >
                                            <option value="roman">Roman</option>
                                            <option value="option01">Option #1</option>
                                            <option value="option02">Option #2</option>
                                            <option value="option03">Option #3</option>
                                        </select>
                                        <div className="custom_select">Roman</div>
                                        <ul className="select-options" style={{}}>
                                            <li className="is-selected">Roman</li>
                                            <li>Option #1</li>
                                            <li>Option #2</li>
                                            <li>Option #3</li>
                                        </ul>
                                        <div className="custom_select_toggle"></div>
                                    </div>
                                </div>

                                <div className="field-wrap">
                                    <label htmlFor="address">
                                        Address Line <span>*</span>
                                    </label>
                                    <input id="address" name="address" type="text" />
                                    <div className="field-footer"></div>
                                </div>

                                <hr />

                                <div className="row-wrap">
                                    <div className="col-50">
                                        <div className="field-wrap">
                                            <label htmlFor="single01">Single Option Standard</label>
                                            <div className="custom_select_wrapper has-empty-value">
                                                <select
                                                    name="single01"
                                                    id="single01"
                                                    className="custom_select select-hidden"
                                                >
                                                    <option value="">Select Option</option>
                                                    <option value="option01">Option #1</option>
                                                    <option value="option02">Option #2</option>
                                                    <option value="option03">Option #3</option>
                                                </select>
                                                <div className="custom_select">Select Option</div>
                                                <ul className="select-options" style={{}}>
                                                    <li className="is-selected">Select Option</li>
                                                    <li>Option #1</li>
                                                    <li>Option #2</li>
                                                    <li>Option #3</li>
                                                </ul>
                                                <div className="custom_select_toggle"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-50">
                                        <div className="field-wrap">
                                            <label htmlFor="single02">Single Option Active</label>
                                            <div className="custom_select_wrapper">
                                                <select
                                                    name="single02"
                                                    id="single02"
                                                    className="custom_select select-hidden"
                                                >
                                                    <option value="">Select Option</option>
                                                    <option value="option01">Option #1</option>
                                                    <option value="option02">Option #2</option>
                                                    <option value="option03">Option #3</option>
                                                </select>
                                                <div className="custom_select">Option #1</div>
                                                <ul className="select-options" style={{}}>
                                                    <li>Select Option</li>
                                                    <li className="is-selected">Option #1</li>
                                                    <li>Option #2</li>
                                                    <li>Option #3</li>
                                                </ul>
                                                <div className="custom_select_toggle"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row-wrap">
                                    <div className="col-50">
                                        <div className="field-wrap">
                                            <label htmlFor="single03">Select2 Option</label>
                                            <div className="custom_select_wrapper is-multiple">
                                                <select
                                                    name="single03"
                                                    id="single03"
                                                    className="custom_select2 select-hidden"
                                                    data-placeholder="Select Option"
                                                >
                                                    <option value="option01">Option #1</option>
                                                    <option value="option02">Option #2</option>
                                                    <option value="option03">Option #3</option>
                                                    <option value="option04">Option #4</option>
                                                    <option value="option05">Option #5</option>
                                                    <option value="option06">Option #6</option>
                                                    <option value="option07">Option #7</option>
                                                    <option value="option08">Option #8</option>
                                                    <option value="option09">Option #9</option>
                                                    <option value="option10">Option #10</option>
                                                </select>
                                                <input
                                                    className="custom_select2_input"
                                                    type="text"
                                                    placeholder="Select Option"
                                                />
                                                <ul className="select-options" style={{}}>
                                                    <li>Option #1</li>
                                                    <li>Option #2</li>
                                                    <li>Option #3</li>
                                                    <li>Option #4</li>
                                                    <li>Option #5</li>
                                                    <li>Option #6</li>
                                                    <li>Option #7</li>
                                                    <li>Option #8</li>
                                                    <li>Option #9</li>
                                                    <li>Option #10</li>
                                                </ul>
                                                <div className="custom_select_result"></div>
                                                <div className="custom_select_toggle"></div>
                                                <div className="field-footer"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-50">
                                        <div className="field-wrap">
                                            <label htmlFor="single04">Select2 Option Active</label>
                                            <div className="custom_select_wrapper is-multiple">
                                                <select
                                                    name="single04"
                                                    id="single04"
                                                    className="custom_select2 select-hidden"
                                                    data-placeholder="Select Option"
                                                >
                                                    <option value="option01">Option #1</option>
                                                    <option value="option02">Option #2</option>
                                                    <option value="option03">Option #3</option>
                                                </select>
                                                <input
                                                    className="custom_select2_input"
                                                    type="text"
                                                    placeholder="Select Option"
                                                />
                                                <ul className="select-options" style={{}}>
                                                    <li className="is-selected">Option #1</li>
                                                    <li className="is-selected">Option #2</li>
                                                    <li>Option #3</li>
                                                </ul>
                                                <div className="custom_select_result">
                                                    <span className="multiple-value-item" data-value="option01">
                                                        Option #1<i></i>
                                                    </span>
                                                    <span className="multiple-value-item" data-value="option02">
                                                        Option #2<i></i>
                                                    </span>
                                                </div>
                                                <div className="custom_select_toggle"></div>
                                                <div className="field-footer"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                <div className="field-wrap">
                                    <label htmlFor="file-upload">Upload File</label>
                                    <div className="file-field-wrap">
                                        <input
                                            type="file"
                                            name="file-upload"
                                            id="file-upload"
                                            data-button="Browse"
                                            placeholder=""
                                        />
                                        <div className="custom-file-input">
                                            {' '}
                                            <span className="custom-file-input--value"></span>{' '}
                                            <span className="custom-file-input--button">Browse</span>{' '}
                                        </div>
                                    </div>
                                </div>

                                <div className="field-wrap">
                                    <label htmlFor="file-drop-upload">Media Upload</label>
                                    <div className="drop-field-wrap">
                                        <input
                                            type="file"
                                            name="file-drop-upload"
                                            id="file-drop-upload"
                                            className="drop-file-upload"
                                        />
                                        <div className="drop-field-caption">Drag &amp; Drop Media Here</div>
                                        <span className="drop-field-or">or</span>
                                        <a href="#" className="drop-field-button">
                                            Select Files
                                        </a>
                                    </div>
                                </div>

                                <div className="field-wrap">
                                    <label htmlFor="file-drop-upload">Media Uploaded</label>
                                    <div className="drop-field-wrap">
                                        <input
                                            type="file"
                                            name="file-drop-upload2"
                                            id="file-drop-upload2"
                                            className="drop-file-upload"
                                        />
                                        <div className="drop-field-caption">Drag &amp; Drop Media Here</div>
                                        <span className="drop-field-or">or</span>
                                        <a href="#" className="drop-field-button">
                                            Select Files
                                        </a>

                                        <div className="drop-field-uploaded-wrap">
                                            <ul className="drop-field-uploaded">
                                                <li className="uploaded-item">
                                                    <img src="img/uploaded-image01.jpg" alt="Image 01" />
                                                    <div className="uploaded-item-tools">
                                                        <a
                                                            href="#"
                                                            className="uploaded-item-tools--main is-active"
                                                            data-tooltip="Main Photo"
                                                        ></a>
                                                        <a
                                                            href="#"
                                                            className="uploaded-item-tools--edit"
                                                            data-tooltip="Edit"
                                                        ></a>
                                                        <a
                                                            href="#"
                                                            className="uploaded-item-tools--remove"
                                                            data-tooltip="Delete"
                                                        ></a>
                                                    </div>
                                                </li>
                                                <li className="uploaded-item">
                                                    <img src="img/uploaded-image02.jpg" alt="Image 02" />
                                                    <div className="uploaded-item-tools">
                                                        <a
                                                            href="#"
                                                            className="uploaded-item-tools--main"
                                                            data-tooltip="Main Photo"
                                                        ></a>
                                                        <a
                                                            href="#"
                                                            className="uploaded-item-tools--edit"
                                                            data-tooltip="Edit"
                                                        ></a>
                                                        <a
                                                            href="#"
                                                            className="uploaded-item-tools--remove"
                                                            data-tooltip="Delete"
                                                        ></a>
                                                    </div>
                                                </li>
                                                <li className="uploaded-item uploaded-item-add-wrap">
                                                    <a href="#" className="uploaded-item-add">
                                                        <i></i>
                                                    </a>
                                                </li>
                                            </ul>
                                            <div className="edit-uploaded-item-popup">
                                                <a href="#" className="edit-popup-close"></a>
                                                <h4>Edit Photo</h4>
                                                <div className="field-wrap">
                                                    <label htmlFor="edit-title">Title</label>
                                                    <input type="text" name="edit-title" id="edit-title" />
                                                    <div className="field-footer"></div>
                                                </div>
                                                <div className="field-wrap">
                                                    <label htmlFor="edit-descr">Description</label>
                                                    <input type="text" name="edit-descr" id="edit-descr" />
                                                    <div className="field-footer"></div>
                                                </div>
                                                <div className="field-wrap">
                                                    <label htmlFor="edit-points">Points of interests</label>
                                                    <input type="text" name="edit-points" id="edit-points" />
                                                    <div className="field-footer"></div>
                                                </div>
                                                <div className="edit-popup-footer">
                                                    <a href="#" className="edit-popup--cancel">
                                                        Cancel
                                                    </a>
                                                    <a href="#" className="edit-popup--save button is-orange">
                                                        Save
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                <div className="field-wrap">
                                    <label htmlFor="calendar">Calendar</label>
                                    <div className="calendar-field-wrap">
                                        <input
                                            type="text"
                                            name="calendar"
                                            id="calendar"
                                            placeholder="dd / mm / yy"
                                            className="hasDatepicker"
                                        />
                                        <i></i>
                                    </div>
                                    <div className="field-footer"></div>
                                </div>

                                <hr />

                                <hr />
                                <div className="field-wrap">
                                    <label htmlFor="dropdown">Dropdown</label>
                                    <div className="custom-select-with-text">
                                        <div className="custom_select_wrapper has-empty-value">
                                            <select
                                                id="dropdown"
                                                name="dropdown"
                                                className="custom_select dropdown-with-text select-hidden"
                                            >
                                                <option value="">Choose</option>
                                                <option value="option01">Option #1</option>
                                                <option value="option02">Option #2</option>
                                                <option value="option03">Option #3</option>
                                            </select>
                                            <div className="custom_select">Choose</div>
                                            <ul className="select-options" style={{}}>
                                                <li className="is-selected">Choose</li>
                                                <li>Option #1</li>
                                                <li>Option #2</li>
                                                <li>Option #3</li>
                                            </ul>
                                            <div className="custom_select_toggle"></div>
                                        </div>
                                        <input type="text" id="dropdown-text" name="dropdown-text" />
                                        <div className="field-footer"></div>
                                    </div>
                                </div>

                                <hr />

                                <div className="field-wrap">
                                    <label htmlFor="gender-select">Gender</label>
                                    <div className="custom_select_wrapper">
                                        <select
                                            name="gender-select"
                                            id="gender-select"
                                            className="custom_select new-select select-hidden"
                                        >
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                        <div className="custom_select">Male</div>
                                        <ul className="select-options" style={{}}>
                                            <li className="is-selected">Male</li>
                                            <li>Female</li>
                                        </ul>
                                        <div className="custom_select_toggle"></div>
                                    </div>
                                </div>

                                <div className="field-wrap">
                                    <label className="is-inline">Gender</label>
                                    <div className="custom-radio-wrap">
                                        <input
                                            type="radio"
                                            id="radio-male"
                                            name="gender-radio"
                                            value="male"
                                            className="custom-radio"
                                            data-label="Male"
                                        />
                                        <span>Male</span>
                                    </div>
                                    <div className="custom-radio-wrap is-checked">
                                        <input
                                            type="radio"
                                            id="radio-female"
                                            name="gender-radio"
                                            value="female"
                                            className="custom-radio"
                                            data-label="Female"
                                            checked={true}
                                        />
                                        <span>Female</span>
                                    </div>
                                </div>

                                <hr />

                                <div className="field-wrap">
                                    <label htmlFor="message">Message</label>
                                    <textarea name="message" id="message"></textarea>
                                </div>

                                <div className="field-wrap">
                                    <label>Check Box</label>
                                    <div className="custom-checkbox-wrap is-default-style">
                                        <input
                                            type="checkbox"
                                            id="checkbox01"
                                            name="checkbox01"
                                            value="check01"
                                            className="custom-checkbox"
                                            data-label="Check this custom checkbox"
                                        />
                                        <span>Check this custom checkbox</span>
                                    </div>
                                    <div className="custom-checkbox-wrap is-default-style is-checked">
                                        <input
                                            type="checkbox"
                                            id="checkbox02"
                                            name="checkbox02"
                                            value="check02"
                                            className="custom-checkbox"
                                            checked={false}
                                            data-label="Check this custom checkbox"
                                        />
                                        <span>Check this custom checkbox</span>
                                    </div>
                                </div>

                                <div className="field-wrap">
                                    <label>Radio</label>
                                    <div className="custom-radio-wrap">
                                        <input
                                            type="radio"
                                            id="radio01-1"
                                            name="radio01"
                                            value="radio01-1"
                                            className="custom-radio"
                                            data-label="Check this custom radio button"
                                        />
                                        <span>Check this custom radio button</span>
                                    </div>
                                    <div className="custom-radio-wrap is-checked">
                                        <input
                                            type="radio"
                                            id="radio01-2"
                                            name="radio01"
                                            value="radio01-2"
                                            className="custom-radio"
                                            checked={false}
                                            data-label="Check this custom radio button"
                                        />
                                        <span>Check this custom radio button</span>
                                    </div>
                                </div>

                                <div className="field-wrap">
                                    <label>Toggle</label>
                                    <div className="custom-checkbox-wrap is-toggler-style is-checked">
                                        <input
                                            type="checkbox"
                                            id="checkbox03"
                                            name="checkbox03"
                                            value="check03"
                                            className="custom-toggler"
                                            checked={false}
                                            data-label="Toggle switch element"
                                        />
                                        <span>Toggle switch element</span>
                                    </div>
                                    <div className="custom-checkbox-wrap is-toggler-style">
                                        <input
                                            type="checkbox"
                                            id="checkbox04"
                                            name="checkbox04"
                                            value="check04"
                                            className="custom-toggler"
                                            data-label="Disabled switch element"
                                        />
                                        <span>Disabled switch element</span>
                                    </div>
                                </div>

                                <div className="field-wrap is-switch-container">
                                    <div className="custom-radio-wrap is-switch-style is-checked">
                                        <input
                                            type="radio"
                                            id="radio02-1"
                                            name="radio02"
                                            value="radio02-1"
                                            className="custom-switch"
                                            checked={false}
                                            data-label="Toggle switch element"
                                        />
                                        <span>Toggle switch element</span>
                                    </div>
                                    <div className="custom-radio-wrap is-switch-style">
                                        <input
                                            type="radio"
                                            id="radio02-2"
                                            name="radio02"
                                            value="radio02-2"
                                            className="custom-switch"
                                            data-label="Disabled switch element"
                                        />
                                        <span>Disabled switch element</span>
                                    </div>
                                </div>

                                <hr />

                                <div className="row-wrap">
                                    <div className="col-50">
                                        <div className="field-wrap">
                                            <label>Range</label>
                                            <div
                                                className="widget-range-slider-wrap"
                                                data-min="0"
                                                data-max="2000"
                                                data-init-min="0"
                                                data-init-max="1000"
                                                data-currency="$"
                                            >
                                                <div className="widget-range-inputs">
                                                    <span className="widget-range-label--from">From</span>
                                                    <input
                                                        type="number"
                                                        name="widget-range01-from"
                                                        id="widget-range01-from"
                                                        className="widget-range-from"
                                                        min="0"
                                                        max="2000"
                                                    />
                                                    <span className="widget-range-label--to">To</span>
                                                    <input
                                                        type="number"
                                                        name="widget-range01-to"
                                                        id="widget-range01-to"
                                                        className="widget-range-to"
                                                        min="0"
                                                        max="2000"
                                                    />
                                                    <a href="#" className="widget-range-button">
                                                        OK
                                                    </a>
                                                    <div className="field-footer"></div>
                                                    <div className="field-footer"></div>
                                                </div>
                                                <div
                                                    id="widget-range01"
                                                    className="widget-range-slider ui-slider ui-corner-all ui-slider-horizontal ui-widget ui-widget-content"
                                                >
                                                    <div
                                                        className="ui-slider-range ui-corner-all ui-widget-header"
                                                        style={{ left: '0%', width: '50%' }}
                                                    ></div>
                                                    <span
                                                        tabIndex={0}
                                                        className="ui-slider-handle ui-corner-all ui-state-default"
                                                        style={{ left: '0%' }}
                                                    ></span>
                                                    <span
                                                        tabIndex={0}
                                                        className="ui-slider-handle ui-corner-all ui-state-default"
                                                        style={{ left: '50%' }}
                                                    ></span>
                                                </div>
                                                <div className="widget-range-info">
                                                    <span className="widget-range-info--min">0</span>
                                                    <span className="widget-range-info--max">$1000</span>
                                                </div>
                                                <input
                                                    type="hidden"
                                                    name="widget-range"
                                                    className="widget-range--value"
                                                />
                                                <div className="field-footer"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-50"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>

                <section className="element-section buttons-section">
                    <h2 className="section-title">Tabs</h2>
                    <div className="row-wrap">
                        <div className="col-66">
                            <div className="tabs-wrapper is-horizontal">
                                <div className="tab-head">
                                    <div className="tab-head-item is-active" data-tab="tab01">
                                        Tab One
                                    </div>
                                    <div className="tab-head-item" data-tab="tab02">
                                        Tab Two
                                    </div>
                                    <div className="tab-head-item" data-tab="tab03">
                                        Tab Three
                                    </div>
                                </div>
                                <div className="tab-content-wrap">
                                    <div className="tab-content-block is-active" data-tab="tab01">
                                        <p>
                                            We are offering you an outstanding sea cruise around the Palm Jumeirah, to
                                            Burj Al Arab,Dubai Marina and the “The World” Islands. You will enjoy
                                            fascinating sea waves and gain a different perspective on the city of Dubai
                                            by seeing the skyline of the city from the water.
                                        </p>
                                        <p>
                                            Our experienced captain awaits you on board of a comfortable motor yacht,
                                            which has a convenient cabin with a bathroom, seating area in the front and
                                            the back of the boat.Cruising time- about 2 hours
                                        </p>
                                    </div>
                                    <div className="tab-content-block" data-tab="tab02">
                                        <p>
                                            Our experienced captain awaits you on board of a comfortable motor yacht,
                                            which has a convenient cabin with a bathroom, seating area in the front and
                                            the back of the boat.Cruising time- about 2 hours
                                        </p>
                                        <p>
                                            We are offering you an outstanding sea cruise around the Palm Jumeirah, to
                                            Burj Al Arab,Dubai Marina and the “The World” Islands. You will enjoy
                                            fascinating sea waves and gain a different perspective on the city of Dubai
                                            by seeing the skyline of the city from the water.
                                        </p>
                                    </div>
                                    <div className="tab-content-block" data-tab="tab03">
                                        <p>
                                            We are offering you an outstanding sea cruise around the Palm Jumeirah, to
                                            Burj Al Arab,Dubai Marina and the “The World” Islands. You will enjoy
                                            fascinating sea waves and gain a different perspective on the city of Dubai
                                            by seeing the skyline of the city from the water.
                                        </p>
                                        <p>
                                            Our experienced captain awaits you on board of a comfortable motor yacht,
                                            which has a convenient cabin with a bathroom, seating area in the front and
                                            the back of the boat.Cruising time- about 2 hours
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="tabs-wrapper is-vertical">
                                <div className="tab-head">
                                    <div className="tab-head-item is-active" data-tab="tab-v01">
                                        Tab One
                                    </div>
                                    <div className="tab-head-item" data-tab="tab-v02">
                                        Tab Two
                                    </div>
                                    <div className="tab-head-item" data-tab="tab-v03">
                                        Tab Three
                                    </div>
                                </div>
                                <div className="tab-content-wrap">
                                    <div className="tab-content-block is-active" data-tab="tab-v01">
                                        <p>
                                            We are offering you an outstanding sea cruise around the Palm Jumeirah, to
                                            Burj Al Arab,Dubai Marina and the “The World” Islands. You will enjoy
                                            fascinating sea waves and gain a different perspective on the city of Dubai
                                            by seeing the skyline of the city from the water.
                                        </p>
                                        <p>
                                            Our experienced captain awaits you on board of a comfortable motor yacht,
                                            which has a convenient cabin with a bathroom, seating area in the front and
                                            the back of the boat.Cruising time- about 2 hours
                                        </p>
                                    </div>
                                    <div className="tab-content-block" data-tab="tab-v02">
                                        <p>
                                            Our experienced captain awaits you on board of a comfortable motor yacht,
                                            which has a convenient cabin with a bathroom, seating area in the front and
                                            the back of the boat.Cruising time- about 2 hours
                                        </p>
                                        <p>
                                            We are offering you an outstanding sea cruise around the Palm Jumeirah, to
                                            Burj Al Arab,Dubai Marina and the “The World” Islands. You will enjoy
                                            fascinating sea waves and gain a different perspective on the city of Dubai
                                            by seeing the skyline of the city from the water.
                                        </p>
                                    </div>
                                    <div className="tab-content-block" data-tab="tab-v03">
                                        <p>
                                            We are offering you an outstanding sea cruise around the Palm Jumeirah, to
                                            Burj Al Arab,Dubai Marina and the “The World” Islands. You will enjoy
                                            fascinating sea waves and gain a different perspective on the city of Dubai
                                            by seeing the skyline of the city from the water.
                                        </p>
                                        <p>
                                            Our experienced captain awaits you on board of a comfortable motor yacht,
                                            which has a convenient cabin with a bathroom, seating area in the front and
                                            the back of the boat.Cruising time- about 2 hours
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-33"></div>
                    </div>
                </section>
                <section className="element-section buttons-section">
                    <h2 className="section-title">Accordeon &amp; Toggle</h2>
                    <div className="row-wrap">
                        <div className="col-66">
                            <div className="accordeon-wrapper">
                                <div className="accordeon-item-wrap is-active">
                                    <div className="accordeon-item-title">
                                        {' '}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="7"
                                            height="11"
                                            viewBox="0 0 7 11"
                                        >
                                            {' '}
                                            <path
                                                d="M2.021,5.121l3.83-3.83a.561.561,0,0,0,0-.792L5.515.164a.561.561,0,0,0-.792,0L.163,4.723a.565.565,0,0,0,0,.795l4.556,4.555a.561.561,0,0,0,.792,0l.335-.335a.561.561,0,0,0,0-.792Z"
                                                transform="translate(6.014 10.237) rotate(180)"
                                            ></path>{' '}
                                        </svg>
                                        Tab One
                                    </div>
                                    <div className="accordeon-item-content">
                                        <p>
                                            We are offering you an outstanding sea cruise around the Palm Jumeirah, to
                                            Burj Al Arab,Dubai Marina and the “The World” Islands. You will enjoy
                                            fascinating sea waves and gain a different perspective on the city of Dubai
                                            by seeing the skyline of the city from the water.
                                        </p>
                                    </div>
                                </div>
                                <div className="accordeon-item-wrap">
                                    <div className="accordeon-item-title">
                                        {' '}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="7"
                                            height="11"
                                            viewBox="0 0 7 11"
                                        >
                                            {' '}
                                            <path
                                                d="M2.021,5.121l3.83-3.83a.561.561,0,0,0,0-.792L5.515.164a.561.561,0,0,0-.792,0L.163,4.723a.565.565,0,0,0,0,.795l4.556,4.555a.561.561,0,0,0,.792,0l.335-.335a.561.561,0,0,0,0-.792Z"
                                                transform="translate(6.014 10.237) rotate(180)"
                                            ></path>{' '}
                                        </svg>
                                        Tab Two
                                    </div>
                                    <div className="accordeon-item-content" style={{ display: 'none' }}>
                                        <p>
                                            We are offering you an outstanding sea cruise around the Palm Jumeirah, to
                                            Burj Al Arab,Dubai Marina and the “The World” Islands. You will enjoy
                                            fascinating sea waves and gain a different perspective on the city of Dubai
                                            by seeing the skyline of the city from the water.
                                        </p>
                                    </div>
                                </div>
                                <div className="accordeon-item-wrap">
                                    <div className="accordeon-item-title">
                                        {' '}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="7"
                                            height="11"
                                            viewBox="0 0 7 11"
                                        >
                                            {' '}
                                            <path
                                                d="M2.021,5.121l3.83-3.83a.561.561,0,0,0,0-.792L5.515.164a.561.561,0,0,0-.792,0L.163,4.723a.565.565,0,0,0,0,.795l4.556,4.555a.561.561,0,0,0,.792,0l.335-.335a.561.561,0,0,0,0-.792Z"
                                                transform="translate(6.014 10.237) rotate(180)"
                                            ></path>{' '}
                                        </svg>
                                        Tab Three
                                    </div>
                                    <div className="accordeon-item-content" style={{ display: 'none' }}>
                                        <p>
                                            We are offering you an outstanding sea cruise around the Palm Jumeirah, to
                                            Burj Al Arab,Dubai Marina and the “The World” Islands. You will enjoy
                                            fascinating sea waves and gain a different perspective on the city of Dubai
                                            by seeing the skyline of the city from the water.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="toggle-wrapper">
                                <div className="toggle-item-wrap is-active">
                                    <div className="toggle-item-title">Tab One</div>
                                    <div className="toggle-item-content">
                                        <p>
                                            We are offering you an outstanding sea cruise around the Palm Jumeirah, to
                                            Burj Al Arab,Dubai Marina and the “The World” Islands. You will enjoy
                                            fascinating sea waves and gain a different perspective on the city of Dubai
                                            by seeing the skyline of the city from the water.
                                        </p>
                                    </div>
                                </div>
                                <div className="toggle-item-wrap">
                                    <div className="toggle-item-title">Tab Two</div>
                                    <div className="toggle-item-content" style={{ display: 'none' }}>
                                        <p>
                                            We are offering you an outstanding sea cruise around the Palm Jumeirah, to
                                            Burj Al Arab,Dubai Marina and the “The World” Islands. You will enjoy
                                            fascinating sea waves and gain a different perspective on the city of Dubai
                                            by seeing the skyline of the city from the water.
                                        </p>
                                    </div>
                                </div>
                                <div className="toggle-item-wrap">
                                    <div className="toggle-item-title">Tab Three</div>
                                    <div className="toggle-item-content" style={{ display: 'none' }}>
                                        <p>
                                            We are offering you an outstanding sea cruise around the Palm Jumeirah, to
                                            Burj Al Arab,Dubai Marina and the “The World” Islands. You will enjoy
                                            fascinating sea waves and gain a different perspective on the city of Dubai
                                            by seeing the skyline of the city from the water.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-33"></div>
                    </div>
                </section>

                <section className="element-section buttons-section">
                    <h2 className="section-title">Multiple Select</h2>
                    <div className="row-wrap">
                        <div className="col-50">
                            <div className="field-wrap">
                                <select
                                    name="single_multi01"
                                    id="single_multi01"
                                    className="custom_select_multi is-hidden"
                                    data-placeholder="Search"
                                >
                                    <option value="option01">Option #1</option>
                                    <option value="option02">Option #2</option>
                                    <option value="option03">Option #3</option>
                                    <option value="option04">Option #4</option>
                                    <option value="option05">Option #5</option>
                                    <option value="option06">Option #6</option>
                                    <option value="option07">Option #7</option>
                                    <option value="option08">Option #8</option>
                                    <option value="option09">Option #9</option>
                                    <option value="option10">Option #10</option>
                                </select>
                                <div className="select-multi-column-wrap">
                                    <div className="select-multi-column--left">
                                        <input type="text" placeholder="Search" />
                                        <ul className="select-multi-list--left">
                                            <li>Option #1</li>
                                            <li>Option #2</li>
                                            <li className="is-selected">Option #3</li>
                                            <li className="is-selected">Option #4</li>
                                            <li className="is-selected">Option #5</li>
                                            <li>Option #6</li>
                                            <li>Option #7</li>
                                            <li>Option #8</li>
                                            <li>Option #9</li>
                                            <li>Option #10</li>
                                        </ul>{' '}
                                        <div className="field-footer"></div>
                                    </div>{' '}
                                    <span></span>{' '}
                                    <div className="select-multi-column--right">
                                        {' '}
                                        <input type="text" placeholder="Search" />
                                        <ul className="select-multi-list--right">
                                            <li className="">Option #3</li>
                                            <li className="">Option #4</li>
                                            <li className="">Option #5</li>
                                        </ul>{' '}
                                        <div className="field-footer"></div>
                                    </div>{' '}
                                </div>
                            </div>
                        </div>
                        <div className="col-50"></div>
                    </div>
                </section>
            </div>
        </div>
    )
}
export default Controls
