import { apiCallWrapper } from 'traveldesk-ui'
import { SelectOptionModel } from 'traveldesk-ui'

export default class CitiesApiService {
    public static async getCitiesOptions(dispatch: any) {
        return await apiCallWrapper<SelectOptionModel>('/api/cities/options', 'GET', dispatch)
    }
    public static async getCitiesInSuppliersCountry(dispatch: any) {
        const res = await apiCallWrapper<SelectOptionModel[]>('/api/cities/supplier', 'GET', dispatch)
        return res
    }
}
