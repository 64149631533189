import * as React from 'react'
import { Redirect, Route } from 'react-router'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../store'
import UnathorizedPage from './../pages/Unathorized'
const PrivateRoute = (props: { roles?: string[]; component: any; path: string; exact?: boolean }) => {
    const auth = useSelector((state: ApplicationState) => state.auth)
    const Component = props.component
    return (
        <Route
            path={props.path}
            exact={props.exact}
            render={(componentProps) => {
                if (auth && auth.isAuthorized) {
                    if (
                        props.roles &&
                        props.roles.length > 0 &&
                        !props.roles.some((role) => auth.authData?.roles.some((arole) => arole == role))
                    ) {
                        return <UnathorizedPage />
                    }
                    return <Component {...componentProps} />
                }
                return <Redirect to={{ pathname: '/account/login' }} />
            }}
        />
    )
}

export default PrivateRoute
