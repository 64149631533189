import { apiCallWrapper } from 'traveldesk-ui'
import { AccountSettingsModel } from '../models/AccountSettingsModel'

export default class AccountApiService {
    static getDefaultCommission = async (dispatch: any) => {
        return await apiCallWrapper<number | undefined>(`/api/account/defaultcommission`, 'GET', dispatch)
    }
    static getAccountSettings = async (dispatch: any) => {
        return await apiCallWrapper<AccountSettingsModel>(`/api/account/settings`, 'GET', dispatch)
    }
}
