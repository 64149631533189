import { apiCallWrapper, EntitiesPaginatedModel } from 'traveldesk-ui'
import { SelectOptionModel } from 'traveldesk-ui'
import BookingAgent from '../pages/Agent/Models/BookingAgent'
import BookingsRequest from '../pages/Agent/Models/BookingsRequest'
import OfferAgent from '../pages/Agent/Models/OfferAgent'
import Hotel from '../models/Hotel'
import IdNameModel from '../models/IdNameModel'
import NewBookingAgent from '../pages/Agent/Models/NewBookingAgent'
import AgentOfferPrice from '../pages/Agent/Models/AgentOfferPrice'
import NewBookingArrival from '../pages/Agent/Models/NewBookingArrival'
import NewBookingGroupInfo from '../pages/Agent/Models/NewBookingArrivalGroupInfo'
import TourNewBookingModel from '../pages/Agent/Models/TourNewBookingModel'

export default class AgencyAreaApiService {
    static addBooking = async (model: NewBookingAgent, dispatch: any) => {
        return await apiCallWrapper<BookingAgent>(`/api/agent/bookings`, 'POST', dispatch, model)
    }
    static getBookingDetails = async (id: number, dispatch: any) => {
        return await apiCallWrapper<BookingAgent>(`api/agent/bookings/${id}`, 'GET', dispatch)
    }
    static getNewBookingTours = async (dispatch: any) => {
        return await apiCallWrapper<TourNewBookingModel[]>(`/api/agent/tours/fornewbooking`, 'GET', dispatch)
    }
    static getNewBookingGroupInfo = async (groupInfoId: number, hotelId: number, dispatch: any) => {
        return await apiCallWrapper<NewBookingGroupInfo>(
            `/api/agent/customers/groupInfo?hotelId=${hotelId}&groupInfoId=${groupInfoId}`,
            'GET',
            dispatch,
        )
    }
    static getNewBookingArrivals = async (hotelId: number, date: moment.Moment, dispatch: any) => {
        return await apiCallWrapper<NewBookingArrival[]>(
            `/api/agent/customers/inHouse?hotelId=${hotelId}&date=${date.format('YYYY-MM-DD')}`,
            'GET',
            dispatch,
        )
    }
    static getNewBookingOfferPrice = async (tourId: number, date: moment.Moment, dispatch: any) => {
        return await apiCallWrapper<AgentOfferPrice[]>(
            `/api/agent/offers/price?tourId=${tourId}&date=${date.format('YYYY-MM-DD')}`,
            'GET',
            dispatch,
        )
    }
    static getNewBookingHotels = async (dispatch: any) => {
        return await apiCallWrapper<Hotel[]>(`/api/agent/hotels/fornewbooking`, 'GET', dispatch)
    }
    // static getNewBookingOffers = async (dispatch: any) => {
    //     return await apiCallWrapper<IdNameModel[]>(`/api/agent/offers/fornewbooking`, 'GET', dispatch)
    // }
    static getBookingsAgents = async (dispatch: any) => {
        return await apiCallWrapper<IdNameModel[]>(`/api/agent/agents/bookings`, 'GET', dispatch)
    }
    static getCommissionAgents = async (dispatch: any) => {
        return await apiCallWrapper<IdNameModel[]>(`/api/agent/agents/commissions`, 'GET', dispatch)
    }
    static getBookingsHotels = async (dispatch: any) => {
        return await apiCallWrapper<Hotel[]>(`/api/agent/hotels/bookings`, 'GET', dispatch)
    }
    static getCommissionHotels = async (dispatch: any) => {
        return await apiCallWrapper<Hotel[]>(`/api/agent/hotels/commissions`, 'GET', dispatch)
    }
    static getBookingsOffers = async (dispatch: any) => {
        return await apiCallWrapper<OfferAgent[]>(`/api/agent/offers/bookings`, 'GET', dispatch)
    }
    static getCommissionOffers = async (dispatch: any) => {
        return await apiCallWrapper<OfferAgent[]>(`/api/agent/offers/commissions`, 'GET', dispatch)
    }
    static getCommissions = async (request: BookingsRequest, dispatch: any) => {
        return await apiCallWrapper<EntitiesPaginatedModel<BookingAgent>>(
            `/api/agent/commissions?${request.query}`,
            'GET',
            dispatch,
        )
    }
    static getBookings = async (request: BookingsRequest, dispatch: any) => {
        const { query } = request
        return await apiCallWrapper<EntitiesPaginatedModel<BookingAgent>>(
            `/api/agent/bookings?${query}`,
            'GET',
            dispatch,
        )
    }
}
