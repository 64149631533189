import { minutesToTimeString, timeStringToMinutes } from "traveldesk-ui"

export default class ItineraryStopModel {

    constructor(index: number, itineraryId: number) {
        this.index = index
        this.itineraryId = itineraryId
    }
    index: number = 0
    itineraryId: number = 0
    time: number = 0
    timeEnd: number = 0
    get timeDescription() {
        return minutesToTimeString(this.time)
    }
    set timeDescription(value: string) {
        this.time = timeStringToMinutes(value)
    }
    get timeEndDescription() {
        return minutesToTimeString(this.timeEnd)
    }
    set timeEndDescription(value: string) {
        this.timeEnd = timeStringToMinutes(value)
    }
    name: string = ''
    description: string = ""
    address: string = ''
    lat: number = 0
    lng: number = 0
}