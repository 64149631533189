import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from './../store/index'
import { actionCreators as supplierActions } from './../store/Supplier'
export const useSupplier = () => {
    const dispatch = useDispatch()
    const supplier = useSelector((state: ApplicationState) => state.supplier.supplier)
    React.useEffect(() => {
        if (!supplier) {
            dispatch(supplierActions.requestSupplier())
        }
    }, [])
    return supplier
}
