import * as React from 'react'
import { Autocomplete, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { FieldFooter, ToggleFormField } from 'traveldesk-ui';
import { BaseInputProps } from 'traveldesk-ui/dist/Form/BaseProps';

const containerStyle = {
    width: '100%',
    height: '400px'
};
interface Props {
    apiKey: string
    label: string
    placeIdLabel: string
    value?: string
    validationError?: string
    onChange: (placeId: string | undefined) => void
}
let autocomplete: google.maps.places.Autocomplete | undefined
const MapsPlaceIdPicker = (props: Props) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: props.apiKey,
        libraries: ['places']
    })
    const onLoadAutocomplete = (autocompleteParam: google.maps.places.Autocomplete) => {
        autocomplete = autocompleteParam
    }
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [isGooglePlaceId, setIdGooglePlaceId] = React.useState<boolean>(props.value ? true : false)
    const [map, setMap] = React.useState<google.maps.Map | null>(null)
    const [userLocation, setUserLocation] = React.useState({ lat: 25.187440546619328, lng: 55.28278819138045 })
    const setUserLocationFromNavigator = (position: any) => {
        const lat = position.coords.latitude
        const lng = position.coords.longitude
        setUserLocation({ lat, lng })
        if (window.google && window.google.maps) {
            const center = new window.google.maps.LatLng({ lat, lng });
            if (map)
                map.setCenter(center);
        }
    }
    React.useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(setUserLocationFromNavigator);
        }
    }, [])
    React.useEffect(() => {

    }, [props.apiKey])

    const onLoad = React.useCallback(function callback(map: google.maps.Map) {
        const center = new window.google.maps.LatLng(userLocation);
        map.setCenter(center);
        setMap(map)
    }, [userLocation])
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])
    const onChange = (placeId: string | undefined) => {
        setIsLoading(true)
        props.onChange(placeId)
        setIsLoading(false)

    }
    const onDropLocation = () => {
        onChange(undefined)
    }

    const onPlaceChanged = () => {
        if (autocomplete) {
            const place = autocomplete.getPlace()
            if (place.place_id) {
                onChange(place.place_id)
            }
            if (place && place.geometry && place.geometry.location) {
                const center = new window.google.maps.LatLng({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
                map?.setCenter(center);
            }
            else {
                alert("Could not retreive place information")
            }
        }
    }
    const onCheckIsGooglePlaceId = () => {
        if (isGooglePlaceId) onChange(undefined)
        setIdGooglePlaceId(!isGooglePlaceId)
    }
    const onClickMap = (e: google.maps.MapMouseEvent) => {
    }
    return <>
        {isLoaded ?
            <>
                <br />
                <ToggleFormField
                    label={props.label}
                    name="IsGooglePlaceId"
                    value={isGooglePlaceId}
                    onChange={onCheckIsGooglePlaceId}

                />

                {isGooglePlaceId ? <React.Fragment>
                    <input
                        type="text"
                        readOnly
                        placeholder="Search by address or Click on place on map to get id"
                        value={props.value}

                    />
                    <span>{props.placeIdLabel}</span>
                    <FieldFooter error={props.validationError} />

                    <br />
                    <br />
                    <Autocomplete
                        onLoad={onLoadAutocomplete}
                        onPlaceChanged={onPlaceChanged}
                    >
                        <input
                            type="text"
                            placeholder="Search for the place..."
                        />
                    </Autocomplete>
                    <br />
                </React.Fragment>
                    : null}
            </>
            : null}
        {isLoaded && isGooglePlaceId
            ? <GoogleMap
                mapContainerStyle={containerStyle}
                onClick={onClickMap}
                center={userLocation}
                zoom={18}
                onLoad={onLoad}
                onUnmount={onUnmount}
            >

            </GoogleMap>
            : null}
        {isGooglePlaceId && props.value && <label className="clickable" onClick={onDropLocation}>Clear place Id</label>}
    </>
}
export default React.memo(MapsPlaceIdPicker)