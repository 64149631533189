export default class OfferTicketType {
    constructor(ticketTypeId?: number, type?: OffersTicketTypesType) {
        this.ticketTypeId = ticketTypeId || 0
        this.type = type ?? OffersTicketTypesType.Adult
    }
    ticketTypeId: number
    type: OffersTicketTypesType
}
export enum OffersTicketTypesType {
    Adult,
    Child,
    Infant,
}
