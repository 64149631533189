import Offer, { OfferTourManageTableModel, OfferManageTourModel } from './Offer'
import { Photo } from 'traveldesk-ui'
import BaseEntity from './BaseEntity'
import CancellationPolicy from './CancellationPolicy'
import { AccountSettingsModel } from './AccountSettingsModel'
import TourFaqModel from './TourFaqModel'
import ItineraryModel from './ItineraryModel'

export class TourManageModel extends BaseEntity {
    public static Create(obj: TourManageModel, accountSettings: AccountSettingsModel) {
        const res = Object.assign(new TourManageModel(), obj, {
            offers: obj.offers.map((x) => OfferManageTourModel.Create(x, accountSettings)),
        })
        if (res.cityId === 0) res.cityId = undefined
        return res
    }
    id: number = 0
    nameInternal: string = ''
    nameDisplay: string = ''
    url: string = ''
    cityId?: number = 0
    toursCategories: number[] = []
    recommendedFor: number = 0
    languageId: number = 0
    isDeleted: boolean = false
    videoUrl: string = ''
    cancellationPolicy: CancellationPolicy[] = []
    // heroImage: Photo = new Photo()
    offers: OfferManageTourModel[] = []
    heroImageId?: number = undefined
    photos: Photo[] = []
    isDefaultCancellationPolicy: boolean = true
    isDefaultNotesToClientsPostBooking: boolean = true
    isDefaultTermsAndConditions: boolean = true
    isDefaultFaq: boolean = true
    termsAndConditions: string = ''
    faq: string = ''
    faqs: TourFaqModel[] = []
    shortDescription: string = ''
    salesDescription: string = ''
    tourItinerary: string = ''
    itineraries: ItineraryModel[] = []
    notesToAgents: string = ''
    seo: string = ''
    seoPageTitle: string = ''
    seoPageDescription: string = ''
    notesToClientsPostBooking: string = ''
    locationAddress: string = ''
    locationLatitude?: number
    locationLongitude?: number
    googlePlacesId?: string
    googlePlacesRating?: number
    googlePlacesTotalRatings?: number
    tripAdvisorId?: string
    tripAdvisorRating?: number
    tripAdvisorTotalRatings?: number
    facebookPageId?: string
    isTopTour: boolean = false
}

export class TourManageTableModel extends BaseEntity {
    public static Create(obj: TourManageTableModel) {
        return Object.assign(new TourManageTableModel(), obj)
    }
    nameDisplay: string = ''
    nameInternal: string = ''
    languageId: number = 0
    offers: OfferTourManageTableModel[] = []
    isTopTour: boolean = false
}
