import { GenericFilterModel } from 'traveldesk-ui'

export default class ArrivalsDetailsFilter extends GenericFilterModel {
    constructor() {
        super()
        this.sortField = 'groupId'
        this.sortType = 'ASC'
    }
    inHouse: boolean = true
}
