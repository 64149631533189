import { Action, Reducer } from 'redux'
import { AppThunkAction } from './'
import { SelectOptionModel } from 'traveldesk-ui'
import { apiCallWrapper } from 'traveldesk-ui'

export interface State {
    citiesOptions: SelectOptionModel[]
}

const RECEIVE_CITIES_OPTIONS = 'A_RECEIVE_CITIES_OPTIONS'
interface ReceiveCitiesOptionsAction {
    type: 'A_RECEIVE_CITIES_OPTIONS'
    payload: SelectOptionModel[]
}

type KnownAction = ReceiveCitiesOptionsAction

export const actionCreators = {
    requestCitiesOptions: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState()
        if (appState && appState.cities && appState.cities.citiesOptions.length === 0) {
            const result = await apiCallWrapper<SelectOptionModel[]>('/api/cities/options', 'GET', dispatch)
            if (result.IsOk) {
                dispatch({ type: RECEIVE_CITIES_OPTIONS, payload: result.Content?.sort((a, b) => a.label > b.label ? 1 : -1) || [] })
            }
        }
    },
}

const unloadedState: State = { citiesOptions: [] as SelectOptionModel[] }

export const reducer: Reducer<State> = (state: State | undefined, incomingAction: Action): State => {
    if (state === undefined) {
        return unloadedState
    }

    const action = incomingAction as KnownAction
    switch (action.type) {
        case RECEIVE_CITIES_OPTIONS:
            return {
                citiesOptions: action.payload,
            }
        default:
            return state
    }
}
