import { LanguagesStore, Language } from 'traveldesk-ui'
import { useEffect } from 'react'
import { ApplicationState } from '../store'
import { useDispatch, useSelector } from 'react-redux'
interface Props {
    isOffers: boolean
    selector: (state: any) => LanguagesStore.State
}
export const useLanguages = (props: Props) => {
    const dispatch = useDispatch()
    const languages = useSelector(props.selector)
    useEffect(() => {
        if (languages.languages.length === 0) {
            dispatch(LanguagesStore.actionCreators.requestLanguages(props.isOffers))
        }
    }, [])
    return languages
}
export const useLanguagesManageTable = () =>
    useLanguages({
        isOffers: false,
        selector: (state: ApplicationState) => state.languages,
    })
