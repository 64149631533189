import ExternalProviderOptionModel from '../pages/TimeSlots/ExternalProviderOption'
import TimeSlotGroup from './../models/TimeSlotGroup'
import { SelectOptionModel, apiCallWrapper } from 'traveldesk-ui'

export default class TimeSlotsApiService {
    static getExternalProviders = async (dispatch: any) => {
        const res = await apiCallWrapper<ExternalProviderOptionModel[]>(`/api/timeslots/externalproviders`, 'GET', dispatch)
        return res
    }
    static getExternalTimeSlots = async (apiProviderId: number, productId: string, dispatch: any) => {
        const res = await apiCallWrapper<SelectOptionModel[]>(`/api/timeslots/externaltimeslots?apiProviderId=${apiProviderId}&productId=${productId}`, 'GET', dispatch)
        return res
    }
    static getExternalOffers = async (apiProviderId: number, dispatch: any) => {
        const res = await apiCallWrapper<SelectOptionModel[]>(`/api/timeslots/externaloffers?apiproviderid=${apiProviderId}`, 'GET', dispatch)
        return res
    }
    static getTable = async (dispatch: any) => {
        const res = await apiCallWrapper<TimeSlotGroup[]>(`/api/timeslots/groups`, 'GET', dispatch)
        res.Content = res.Content?.map((x) => TimeSlotGroup.Create(x))
        return res
    }
    static getForEdit = async (dispatch: any, id: number) => {
        const res = await apiCallWrapper<TimeSlotGroup>(`/api/timeslots/groups/${id}`, 'GET', dispatch)
        return res
    }
    static save = async (tsg: TimeSlotGroup, dispatch: any) => {
        const res = await apiCallWrapper<TimeSlotGroup>(
            `/api/timeslots/group`,
            tsg.id == 0 ? 'POST' : 'PUT',
            dispatch,
            tsg,
        )
        res.Content = res.Content ? TimeSlotGroup.Create(res.Content) : res.Content
        return res
    }
}
