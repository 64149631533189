import * as React from 'react'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import { ContainerWithPadding, Grid, SectionTitle } from 'traveldesk-ui'
import { Routes } from '../../App'
import { ApplicationState } from '../../store'
import { actionCreators as bookingActions } from '../../store/AgencyBookings'
interface Props extends RouteComponentProps<{ id: string }> {}
const BookingConfirm = (props: Props) => {
    const dispatch = useDispatch()
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const booking = useSelector((state: ApplicationState) => state.agencyBookings.bookingDetails)
    const [bookingId, setBookingId] = React.useState(0)
    React.useEffect(() => {
        dispatch(bookingActions.clearBookingDetails)
        try {
            const id = parseInt(props.match.params.id)
            dispatch(bookingActions.requestBookingDetails(id))
            setBookingId(id)
        } catch {
            dispatch(push(Routes.agentBookings))
        }
    }, [props.match.params])
    return (
        <ContainerWithPadding>
            <Grid>
                <SectionTitle title={`${Localization.getString('Booking confirmed')} #${props.match.params.id}`} />
                <Link className="a-back-to-list" to="/agent/bookings">
                    {Localization.getString('Back to bookings')}
                </Link>
                <p>
                    <Link to={Routes.downloadInvoice(bookingId)}>{Localization.getString('Download invoice')}</Link>
                </p>
                <p>
                    <Link to={Routes.downloadVoucher(bookingId, 'eng')}>
                        {Localization.getString('Download voucher (RUS)')}
                    </Link>
                </p>
                <p>
                    <Link to={Routes.downloadVoucher(bookingId, 'rus')}>
                        {Localization.getString('Download voucher (ENG)')}
                    </Link>
                </p>
                <br />
                <br />
                <h3>Pick Up Time: {booking?.pickUpTime}</h3>
            </Grid>
        </ContainerWithPadding>
    )
}
export default BookingConfirm
