import moment from "moment";
import { fromNumber } from "traveldesk-ui";

export default class ArrivalMeeting {
    static Create(obj: ArrivalMeeting) {
        return Object.assign(new ArrivalMeeting(), obj, {
            date: moment(obj.date)
        })
    }
    date: moment.Moment = moment()
    get dateTimeDisplay() {
        return `${this.date.format("DD.MM.YYYY")} ${this.timeDisplay}`
    }
    get timeDisplay() {
        return this.isNoTime ? "N/A" : `${fromNumber(this.timeStart)} - ${fromNumber(this.timeEnd)}`
    }
    staffId: number = 0
    staffName: string = ''
    timeStart: number = 0
    timeEnd: number = 0
    isNoTime: boolean = false
}