import * as React from 'react'
import Header from './Header'
import 'react-day-picker/src/style.css'
import Loading from './Loading'
import { Layout as LayoutWrapper, NotificationsWrapper } from 'traveldesk-ui'
export default (props: { children?: React.ReactNode }) => (
    <LayoutWrapper>
        <NotificationsWrapper />
        <Loading />
        <Header />
        {props.children}
    </LayoutWrapper>
)
