import { PickUpTimeRegionValue, PickUpTimeHotelValue } from './PickUpTimeValues'

export default class PickUpTime {
    public static Create(obj: PickUpTime) {
        return Object.assign(new PickUpTime(), obj)
    }
    id: number = 0
    name: string = ''
    isFixedTime: boolean = false
    regionsPickUpTimes: PickUpTimeRegionValue[] = [] as PickUpTimeRegionValue[]
    hotelsPickUpTimes: PickUpTimeHotelValue[] = [] as PickUpTimeHotelValue[]
}
