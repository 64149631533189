export default class ArrivalGroupInfoHotelModel {
    public static Create(obj: ArrivalGroupInfoHotelModel) {
        return Object.assign(new ArrivalGroupInfoHotelModel(obj.groupInfoId, obj.hotelId, obj.roomNumber), obj)
    }

    constructor(groupInfoId: number, hotelId: number, roomNumber: string) {
        this.groupInfoId = groupInfoId
        this.hotelId = hotelId
        this.roomNumber = roomNumber
    }
    groupInfoId: number = 0
    hotelId: number = 0
    roomNumber: string = ''
}
