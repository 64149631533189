// import { fromNumber } from "../../UI/TimePicker/utils";
import ArrivalGroupInfoModel, { ArrivalGroupInfoCommunicationStatus, ArrivalGroupStatus } from './ArrivalGroupInfoModel'
import IdNameModel from '../../models/IdNameModel'
import { fromNumber } from 'traveldesk-ui'
import moment from 'moment/moment'
import ArrivalGroupInfoHotelModel from './ArrivalGroupInfoHotelModel'
import ArrivalMeeting from './ArrivalMeeting'
export default class ArrivalModel {
    public static Create(model: ArrivalModel) {
        const res = Object.assign(new ArrivalModel(), model, {
            arrivalDate: moment(model.arrivalDate),
            departureDate: moment(model.departureDate),
            birthDate: moment(model.birthDate),
            groupInfo: model.groupInfo ? ArrivalGroupInfoModel.Create(model.groupInfo) : undefined,
            meetings: model.meetings ? model.meetings.map(x => ArrivalMeeting.Create(x)) : []
        })
        return res
    }
    public static GroupIdUrl(groupId: string) {
        return btoa(groupId)
    }
    id: number = 0
    tdHotelId: number = 0
    hotelName: string = ''
    paxId: string = ''
    tdAgencyId: number = 0
    tdAgencyName: string = ''
    arrivalDate: moment.Moment = moment()
    arrivalTime: number = 0
    departureDate: moment.Moment = moment()
    departureTime: number = 0
    departureTimeString: string = ''
    pickUpTime?: number
    pickUpTimeEnd?: number
    isDeleted: boolean = false
    get customClass() {
        return this.isDeleted ? 'deleted deleted-arrival' : undefined
    }
    // RenderPhones(fontSize?:number) {
    //     return this.GroupInfo
    //     ? this.GroupInfo.PhoneNumbers
    //         ? this.GroupInfo.PhoneNumbers.split(',').map((phone,index)=><PInTableList fontSize={fontSize ? `${fontSize}px`: undefined}  key={index}><a href={`tel:${phone}`}>{phone}</a></PInTableList>)
    //         : null
    //     : null
    // }
    get PickUpTimeDescr() {
        return `${this.pickUpTime ? fromNumber(this.pickUpTime) : ' '} ${this.pickUpTimeEnd ? `- ${fromNumber(this.pickUpTimeEnd)}` : ' '
            }`
    }
    touristName: string = ''
    birthDate?: moment.Moment
    get RoomNumber() {
        return this.groupInfo?.hotels?.find((x) => x.hotelId == this.tdHotelId)?.roomNumber ?? ''
    }
    set RoomNumber(value: string) {
        let gi = this.groupInfo
        if (!gi) {
            gi = new ArrivalGroupInfoModel(this.groupId)
            this.groupInfo = gi
        }
        let h = gi.hotels.find((x) => x.hotelId == this.tdHotelId)
        if (!h) {
            h = new ArrivalGroupInfoHotelModel(gi.id, this.tdHotelId, value)
            gi.hotels.push(h)
        }
        h.roomNumber = value
    }
    get Notes() {
        return this.groupInfo?.notes ?? ''
    }
    set Notes(value: string) {
        let gi = this.groupInfo
        if (!gi) {
            gi = new ArrivalGroupInfoModel(this.groupId)
            this.groupInfo = gi
        }
        gi.notes = value
    }
    get PhoneNumbers() {
        return this.groupInfo?.phoneNumbers ?? this.touristPhone
    }
    set PhoneNumbers(value: string) {
        let gi = this.groupInfo
        if (!gi) {
            gi = new ArrivalGroupInfoModel(this.groupId)
            this.groupInfo = gi
        }
        gi.phoneNumbers = value
    }
    get Age() {
        const age = this.AgeNumber
        return age > 0 ? age : 'Not set'
    }
    get AgeNumber(): number {
        return this.birthDate ? moment().diff(this.birthDate, 'years') : 0
    }
    touristPhone: string = ''
    touristEmail: string = ''
    touristCountry: string = ''
    roomType: string = ''
    accType: string = ''
    mealType: string = ''
    hotelId: string = ''
    arrivalTerminal: string = ''
    arrivalFlight: string = ''
    arrivalTransport: string = ''
    departureFlight: string = ''
    departureTerminal: string = ''
    departureTransport: string = ''
    agencyId: string = ''
    // Agency: string
    groupId: string = ''
    hotelConfNumber: string = ''
    agentConfNumber: string = ''
    groupInfo?: ArrivalGroupInfoModel = undefined
    isManuallyEntered: boolean = false
    arrivalListId: number = 0
    arrivalListName: string = ''
    get CommunicationStatus() {
        return this.groupInfo ? this.groupInfo.communicationStatus : ArrivalGroupInfoCommunicationStatus.None
    }
    get Status() {
        return this.isDeleted
            ? -1
            : this.groupInfo
                ? this.groupInfo.numberOfBookings > 0
                    ? ArrivalGroupStatus.Purchased
                    : this.groupInfo.status
                : ArrivalGroupStatus.Pending
    }
    notes: string = ''
    meetings: ArrivalMeeting[] = []
    // public static get ArrivalsGroupStatusesDescriptions (){
    //     return [
    //         "New",
    //         "Has Bookings",
    //         "No Show"
    //     ]
    // }
}
