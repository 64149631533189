export default class TimeSlotNewBookingModel {
    public static Create(obj: TimeSlotNewBookingModel) {
        return Object.assign(new TimeSlotNewBookingModel(), obj)
    }
    isOnDay = (weekDay: number) => {
        // return true
        const w = 1 << (weekDay - 1)
        const isAvailable = this.daysOffered == 0 ? true : (w & this.daysOffered) > 0
        return isAvailable
    }
    id: number = 0
    name: string = ''
    description: string = ''
    daysOffered: number = 0
    isAnyTime: boolean = false
    isNoTime: boolean = false
    startTimeHour: number = 0
    startTimeMinutes: number = 0
    endTimeHour: number = 0
    endTimeMinutes: number = 0
    toString() {
        return `${this.description}` // (${this.name})`
    }
}
