import GenericFilterModel from '../serviceModels/GenericFilterModel'
import moment from 'moment'
export default class BookingsOperationsFilter extends GenericFilterModel {
    constructor() {
        super()
        this.from = moment().utc().startOf('d')
        this.to = this.from.clone().add(14, 'd')
    }
    from: moment.Moment
    to: moment.Moment
}
