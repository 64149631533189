import * as React from 'react'
import {
    ContainerWithPadding,
    Grid,
    Row,
    Col,
    SectionTitle,
    minutesToTimeString,
    SelectOptionModel,
    IdFormField,
    TextFormField,
    ToggleFieldController,
    ToggleFormField,
} from 'traveldesk-ui'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import parseId from './../../services/parseId'
import { RouteComponentProps } from 'react-router'
import { actionCreators as pickUpTimesActions } from './../../store/PickUpTimes'
import { useForm, Controller } from 'react-hook-form'
import { Link } from 'react-router-dom'
import PickUpTime from './../../models/PickUpTime'
import { nameof } from 'traveldesk-ui'

import { Buttons } from 'traveldesk-ui'
import { actionCreators as regionsActions } from '../../store/Regions'
import { PickUpTimeRegionValue, PickUpTimeHotelValue } from './../../models/PickUpTimeValues'
import PickUpTimeFormMassEdit from './PickUpTimeFormMassEdit'
import { LoadingSection } from '../../components/LoadingSection'
import {
    DataTableColumnModel,
    InputType,
    FilterType,
    DataTableModel,
    DataTableModelCustomAction,
    DataTable,
} from 'traveldesk-ui'
interface Props extends RouteComponentProps<{ id: string }> {}

let clearSelectedHotelsCallback = undefined as any
const PickUpTimeForm = (props: Props) => {
    const dispatch = useDispatch()
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const pickUpTime = useSelector((state: ApplicationState) => state.pickUpTimes.pickUpTime)
    const regions = useSelector((state: ApplicationState) => state.regions.supplierRegions)
    const hotels = useSelector((state: ApplicationState) => state.pickUpTimes.hotelsOptions)
    const cities = useSelector((state: ApplicationState) => state.pickUpTimes.citiesOptions)
    const [showOverridenHotels, setShowOverridenHotels] = React.useState(false)
    const [showDeactivatedRegions, setShowDeactivatedRegions] = React.useState(true)
    const [showHotelsPickUps, setShowHotelsPickUps] = React.useState(false)
    const [regionsPickUps, setRegionsPickUps] = React.useState([] as PickUpTimeRegionValue[])
    const [hotelsPickUps, setHotelsPickUps] = React.useState([] as PickUpTimeHotelValue[])
    const [selectedHotels, setSelectedHotels] = React.useState([] as number[])
    const [isMassSetHotels, setIsMassSetHotels] = React.useState(false)
    React.useEffect(() => {
        const id = parseId(props.match.params.id)
        if (typeof id == undefined) {
            props.history.push('/timeslots')
        }
        dispatch(pickUpTimesActions.managePickUpTime(id || 0))
    }, [props.match.params.id])
    React.useEffect(() => {
        reset(pickUpTime)
        if (pickUpTime?.hotelsPickUpTimes.length ?? 0 > 0) {
            setShowHotelsPickUps(true)
        }
    }, [pickUpTime])

    React.useEffect(() => {
        dispatch(regionsActions.requestSupplierRegions())
        dispatch(pickUpTimesActions.getCitiesOptions())
    }, [])
    React.useEffect(() => {
        if (showHotelsPickUps) {
            dispatch(pickUpTimesActions.getHotelsOptions())
        }
    }, [showHotelsPickUps])
    React.useEffect(() => {
        if (hotels.length == 0 || !pickUpTime) return
        setHotelsPickUps(
            hotels.map((hotel) => {
                const res = Object.assign(new PickUpTimeHotelValue(), {
                    name: hotel.name,
                    hotelId: hotel.id,
                    regionId: hotel.regionId,
                    regionName: hotel.regionId ? regions.find((x) => x.id == hotel.regionId)?.name ?? '' : '',
                    cityId: hotel.cityId,
                    cityName: cities.find((x) => x)?.label ?? '',
                })
                const v = pickUpTime?.hotelsPickUpTimes.find((x) => x.hotelId == hotel.id)
                if (v) {
                    Object.assign(res, v)
                }
                return res
            }),
        )
    }, [pickUpTime, hotels, regions, cities])
    React.useEffect(() => {
        setRegionsPickUps(
            regions.map((region) => {
                const res = Object.assign(new PickUpTimeRegionValue(), {
                    name: region.name,
                    regionId: region.id,
                    cityId: region.cityId,
                    cityName: cities.find((x) => x.value == region.cityId)?.label,
                })
                const v = pickUpTime?.regionsPickUpTimes.find((x) => x.regionId == region.id)
                if (v) {
                    Object.assign(res, v)
                }
                return res
            }),
        )
    }, [pickUpTime, regions, cities])
    const hotelsPickUpsDisplay = React.useMemo(
        () => (showOverridenHotels ? hotelsPickUps.filter((x) => x.timeStart || x.timeEnd) : hotelsPickUps),
        [hotelsPickUps, showOverridenHotels],
    )
    const regionsPickUpsDisplay = React.useMemo(
        () => (showDeactivatedRegions ? regionsPickUps : regionsPickUps.filter((x) => x.isActive)),
        [regionsPickUps, showDeactivatedRegions],
    )
    const { register, handleSubmit, formState:{errors}, control, getValues, setValue, watch, reset, setError } = useForm()
    const onSubmit = (data: any) => {
        data.regionsPickUpTimes = regionsPickUps
        data.hotelsPickUpTimes = hotelsPickUps.filter((x) => x.timeStart || x.timeEnd)
        dispatch(pickUpTimesActions.save(data))
    }
    const isFixedTime = watch('isFixedTime')
    const initHotelsDataTable = (citiesOptions: SelectOptionModel[], regionsOptions: SelectOptionModel[]) => {
        const columns = [
            new DataTableColumnModel({
                fieldName: 'name',
                displayName: Localization.getString('Hotel'),
                sortable: true,
                width: 200,
                filterType: FilterType.TEXT,
            }),
            new DataTableColumnModel({
                fieldName: 'cityName',
                displayName: Localization.getString('City'),
                sortable: true,
                width: 120,
                filterType: FilterType.MULTISELECT,
                filterOptions: citiesOptions,
                filterFieldName: 'cityId',
            }),
            new DataTableColumnModel({
                fieldName: 'regionName',
                displayName: Localization.getString('Region'),
                sortable: true,
                width: 120,
                filterType: FilterType.MULTISELECT,
                filterOptions: regionsOptions,
                filterFieldName: 'regionId',
            }),
            new DataTableColumnModel({
                fieldName: 'timeStart',
                displayName: isFixedTime
                    ? Localization.getString('From')
                    : Localization.getString('Starts, minutes before tour start time'),
                customRenderer: (obj: PickUpTimeHotelValue) => minutesToTimeString(obj.timeStart),
                isEditable: true,
                width: 50,
                editType: InputType.Time,
            }),
            new DataTableColumnModel({
                fieldName: 'timeEnd',
                displayName: isFixedTime
                    ? Localization.getString('To')
                    : Localization.getString('Ends, minutes before tour start time'),
                customRenderer: (obj: PickUpTimeHotelValue) => minutesToTimeString(obj.timeEnd),
                width: 50,
                isEditable: true,
                editType: InputType.Time,
            }),
        ]
        return new DataTableModel({
            columns,
            name: Localization.getString('Hotels pick up times'),
            customActions: [
                new DataTableModelCustomAction(
                    () => {},
                    () => (
                        <i
                            onClick={selectedHotels.length > 0 ? () => setIsMassSetHotels(true) : undefined}
                            className={`fa fa-pencil ${selectedHotels.length == 0 ? 'disabled' : ''}`}
                        />
                    ),
                    Localization.getString("Mass set pick up times for selected hotels'"),
                ),
                new DataTableModelCustomAction(
                    () => {},
                    () => (
                        <i
                            onClick={() => setShowOverridenHotels(!showOverridenHotels)}
                            className={`fa fa-calendar${showOverridenHotels ? ' active' : ''}`}
                        />
                    ),
                    Localization.getString('Show hotels with overriden pick up times only'),
                ),
            ],
        })
    }
    const initRegionsDataTable = (citiesOptions: SelectOptionModel[]) => {
        const columns = [
            new DataTableColumnModel({
                fieldName: 'name',
                displayName: Localization.getString('Region'),
                sortable: true,
                width: 120,
                filterType: FilterType.TEXT,
            }),
            new DataTableColumnModel({
                fieldName: 'cityName',
                displayName: Localization.getString('City'),
                sortable: true,
                width: 100,
                filterType: FilterType.MULTISELECT,
                filterOptions: citiesOptions,
                filterFieldName: 'cityId',
            }),
            new DataTableColumnModel({
                fieldName: 'timeStart',
                displayName: isFixedTime
                    ? Localization.getString('From')
                    : Localization.getString('Starts, minutes before tour start time'),
                customRenderer: (obj: PickUpTimeRegionValue) => minutesToTimeString(obj.timeStart),
                isEditable: true,
                width: 50,
                editType: InputType.Time,
            }),
            new DataTableColumnModel({
                fieldName: 'timeEnd',
                displayName: isFixedTime
                    ? Localization.getString('To')
                    : Localization.getString('Ends, minutes before tour start time'),
                customRenderer: (obj: PickUpTimeRegionValue) => minutesToTimeString(obj.timeEnd),
                isEditable: true,
                width: 50,
                editType: InputType.Time,
            }),
            new DataTableColumnModel({
                fieldName: 'isActive',
                displayName: Localization.getString('Is Active'),
                isEditable: true,
                width: 40,
                editType: InputType.Checkbox,
                customRenderer: (obj: PickUpTimeRegionValue) => (obj.isActive ? <i className="fa fa-check" /> : null),
            }),
        ]
        return new DataTableModel({
            columns,
            name: Localization.getString('Regions pick up times'),
        })
    }
    const regionsDataTableModel = React.useMemo(() => initRegionsDataTable(cities), [isFixedTime, cities])
    const hotelsDataTableModel = React.useMemo(
        () =>
            initHotelsDataTable(
                cities,
                regions.map((x) => ({ value: x.id, label: x.name })),
            ),
        [cities, regions, showOverridenHotels, selectedHotels],
    )
    const onRegionPickUpTimeEdit = (prev: PickUpTimeRegionValue, next: PickUpTimeRegionValue) => {
        setRegionsPickUps(
            regionsPickUps.map((x) =>
                x.regionId == next.regionId ? Object.assign(new PickUpTimeRegionValue(), next) : x,
            ),
        )
    }
    const onHotelPickUpTimeEdit = (prev: PickUpTimeHotelValue, next: PickUpTimeHotelValue) => {
        setHotelsPickUps(
            hotelsPickUps.map((x) => (x.hotelId == next.hotelId ? Object.assign(new PickUpTimeHotelValue(), next) : x)),
        )
    }
    const onSelectedChanged = (selected: number[]) => {
        setSelectedHotels(selected)
    }
    const clearSelectedHotels = () => {
        setSelectedHotels([])
        if (clearSelectedHotelsCallback) clearSelectedHotelsCallback()
    }
    const onCancelMassEdit = () => {
        setIsMassSetHotels(false)
    }
    const onSubmitMassEdit = (value: { timeStart: number; timeEnd: number }) => {
        setHotelsPickUps(
            hotelsPickUps.map((hp) =>
                selectedHotels.indexOf(hp.hotelId) >= 0
                    ? Object.assign(new PickUpTimeHotelValue(), hp, {
                          timeStart: value.timeStart,
                          timeEnd: value.timeEnd,
                      })
                    : hp,
            ),
        )
        clearSelectedHotels()
        setIsMassSetHotels(false)
    }
    return (
        <ContainerWithPadding>
            {isMassSetHotels && <PickUpTimeFormMassEdit onClose={onCancelMassEdit} onSubmit={onSubmitMassEdit} />}
            {pickUpTime ? (
                <Grid>
                    <SectionTitle
                        title={Localization.getString(
                            pickUpTime.id
                                ? `${Localization.getString('Edit pick up time')}`
                                : Localization.getString('Add pick up time'),
                        )}
                    />
                    <Link className="a-back-to-list" to="/pickuptimes">
                        {Localization.getString('Back to pick up times list')}
                    </Link>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col type="100">
                                <IdFormField control={control} />
                                <TextFormField
                                    label={Localization.getString('Name')}
                                    isRequired
                                    error={errors.name ? errors.name.message : ''}
                                    name={nameof<PickUpTime>('name')}
                                    register={register}
                                    options={{
                                        required: Localization.getString('Pick up time name is required'),
                                    }}
                                />
                                <ToggleFieldController
                                    name={nameof<PickUpTime>('isFixedTime')}
                                    label={Localization.getString('Fixed time')}
                                    control={control}
                                />
                                <ToggleFormField
                                    value={showDeactivatedRegions}
                                    label={Localization.getString('Show deactivated regions')}
                                    onChange={(e) => setShowDeactivatedRegions(e)}
                                />
                                <DataTable
                                    sortField="name"
                                    sortType="ASC"
                                    embededScreen
                                    data={regionsPickUpsDisplay}
                                    dataTable={regionsDataTableModel}
                                    onEdit={onRegionPickUpTimeEdit}
                                />
                                <br />
                                {pickUpTime.hotelsPickUpTimes.length == 0 && (
                                    <ToggleFormField
                                        value={showHotelsPickUps}
                                        label={Localization.getString('Override hotels pick up times')}
                                        onChange={(e) => setShowHotelsPickUps(e)}
                                    />
                                )}
                                {(pickUpTime.hotelsPickUpTimes.length > 0 || showHotelsPickUps) && [
                                    <DataTable
                                        sortField="name"
                                        sortType="ASC"
                                        embededScreen
                                        data={hotelsPickUpsDisplay}
                                        dataTable={hotelsDataTableModel}
                                        onEdit={onHotelPickUpTimeEdit}
                                        onSelectedChanged={onSelectedChanged}
                                        selectIdField="hotelId"
                                        setSelectClearFromParentCallback={(callback) => {
                                            clearSelectedHotelsCallback = callback
                                        }}
                                    />,
                                    <br />,
                                ]}
                                <Buttons.StickySaveButton>{Localization.getString('Save')}</Buttons.StickySaveButton>
                            </Col>
                        </Row>
                    </form>
                </Grid>
            ) : (
                <LoadingSection />
            )}
        </ContainerWithPadding>
    )
}
export default PickUpTimeForm
