import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from './../../store'
import { actionCreators } from './../../store/PickUpTimes'
import PickUpTime from './../../models/PickUpTime'
import { Link } from 'react-router-dom'
import { Tooltip } from 'traveldesk-ui'
import { DataTableColumnModel, FilterType, DataTableModel, DataTableModelCustomAction, DataTable } from 'traveldesk-ui'
const PickUpTimes = () => {
    const dispatch = useDispatch()
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const getData = () => {
        dispatch(actionCreators.requestPickUpTimesManageList())
    }
    React.useEffect(() => {
        getData()
    }, [])
    const onRefresh = () => {
        getData()
    }
    const initDataTable = () => {
        const columns = [
            new DataTableColumnModel({
                fieldName: 'name',
                displayName: Localization.getString('Name'),
                sortable: true,
                width: 300,
                filterType: FilterType.TEXT,
            }),
            new DataTableColumnModel({
                fieldName: 'isFixedTime',
                width: 40,
                displayName: Localization.getString('Is Fixed Time'),
                customRenderer: (obj: PickUpTime) => (obj.isFixedTime ? <i className="fa fa-check" /> : null),
            }),
            new DataTableColumnModel({
                fieldName: '',
                displayName: '',
                width: 40,
                customRenderer: (obj: PickUpTime) => (
                    <Link to={`/pickuptimes/${obj.id}`}>
                        <i className="fa fa-edit" />
                    </Link>
                ),
            }),
            new DataTableColumnModel({
                fieldName: '',
                displayName: '',
                width: 40,
                customRenderer: (obj: PickUpTime) => <i className="fa fa-trash clickable" />,
            }),
        ]
        return new DataTableModel({
            columns,
            name: Localization.getString('Pick Up Times'),
            customActions: [
                new DataTableModelCustomAction(
                    () => {},
                    () => (
                        <Tooltip text={Localization.getString('Add pick up time')}>
                            <Link to="/pickuptimes/add">
                                <i className="fa fa-plus" />
                            </Link>
                        </Tooltip>
                    ),
                ),
            ],
        })
    }
    const dataTableModel = React.useMemo(initDataTable, [])
    const data = useSelector((state: ApplicationState) => state.pickUpTimes.pickUpTimesManage)
    return (
        <DataTable
            onRefreshData={onRefresh}
            sortField="name"
            sortType="ASC"
            data={data}
            dataTable={dataTableModel}
            isFullScreenable
            isFullScreen={false}
        />
    )
}
export default PickUpTimes
